import Swal from 'sweetalert2';

/**
 * Take in an event and extract the inner text of the target to copy to clipboard and
 * display as a Toast
 * @param {event} e
 */
async function copyTargetText(e) {
  try {
    const text = e.target.innerText;
    await navigator.clipboard.writeText(text).then(() => {
      if (text !== '-')
        Swal.fire({
          title: `Copied: ${text}`,
          icon: 'success',
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timer: 1500
        });
    });
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

export async function copyTextToClipboard(givenText) {
  try {
    await navigator.clipboard.writeText(givenText).then(() => {
      if (givenText !== '-')
        Swal.fire({
          title: `Copied: ${givenText}`,
          icon: 'success',
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timer: 1500
        });
    });
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

export default copyTargetText;
