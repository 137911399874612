import React, { useEffect } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router';
import axios from 'axios';
import { ElasticMessage, ElasticMessageData } from '../../../types/MessageTypes';
import WhatsAppMessage from './WhatsAppMessage';
import { environment } from '../../../enviroment/enviroment';

export default function MessagesList() {
  const history = useHistory();
  const [loadingMessages, setLoadingMessages] = React.useState(false);
  const [messages, setMessages] = React.useState<ElasticMessage[]>([]);
  const [startDate, setStartDate] = React.useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = React.useState(new Date());
  const [lookupKey, setLookupKey] = React.useState<string>('phone_number');
  const [lookupValue, setLookupValue] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>('');

  const onDateChange = (dates: any[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const loadMessages = (pageNumber: number) => {
    setLoadingMessages(true);
    setError('');
    const beginningStartDate = new Date(startDate);
    beginningStartDate.setHours(0, 0, 0, 0);
    const startTimestamp = beginningStartDate.getTime() / 1000;

    const endofEndDate = new Date(endDate);
    endofEndDate.setHours(23, 59, 59, 0);
    const endTimestamp = endofEndDate.getTime() / 1000;

    const url = `${environment.baseUrl}/elastic_search/messages?lookup_key=${lookupKey}&lookup_value=${lookupValue}&start_date=${startTimestamp}&end_date=${endTimestamp}&page=${pageNumber}`;
    axios.get(url).then((response) => response.data).then((jsonResult: ElasticMessageData) => {
      setMessages(pageNumber === 1 ? jsonResult.data : [...messages, ...jsonResult.data]);
      setHasMore(jsonResult.data.length > 0);
      setLoadingMessages(false);
      setPage(pageNumber);
    }).catch((err) => {
      setLoadingMessages(false);
      setError(err.message);
    });   
  };

  useEffect(() => {
    setPage(1);
    if (messages.length > 0){
      setMessages([]);
    }
  }, [lookupKey]);

  return (
    <div>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Messages
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7 whatsapp-section" fluid>
          <Row>
            <Col className="mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center mb-3">
                    <Col lg="6">
                        <ButtonGroup>
                            <Button active>
                                WhatsApp
                            </Button>
                            <Button disabled>
                                USSD
                            </Button>
                            <Button disabled>
                                Education
                            </Button>
                        </ButtonGroup>
                    </Col>
  
                    <Col lg="6" className="text-right">
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={() => history.push('/admin/smslogs')}>
                          SMS Logs
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => history.push('/admin/questions/education')}>
                          Education questions
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col lg="12">
                        <div className="d-flex">
                            <ButtonGroup size='xs' className="mr-2">
                                <Button size='xs' color={lookupKey === 'phone_number' ? 'primary' : ''} onClick={() => setLookupKey('phone_number')}>
                                    Phone Number
                                </Button>
                                <Button size='xs' color={lookupKey === 'id' ? 'primary' : ''} onClick={() => setLookupKey('id')}>
                                    ID
                                </Button>
                            </ButtonGroup>
                            <Input type="number" placeholder="Search" value={lookupValue} onChange={(e) => setLookupValue(e.target.value)} />
                            <DatePicker
                              selected={startDate}
                              onChange={onDateChange}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              className='form-control'
                            />
                            <Button color="primary" className="ml-2" disabled={loadingMessages || lookupValue.length === 0} onClick={() => loadMessages(1)}>
                                Search
                            </Button>
                        </div>
                    </Col>
                  </Row>
                  
                </CardHeader>
                <CardBody className="pb-5" style={{ minHeight: 0 }}>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    {messages.length === 0 && (
                        <div className="text-center">
                            <h3>No messages found</h3>
                        </div>
                    )}
                    <div className="whatsapp-container">
                      {messages.map((message) => (
                          <WhatsAppMessage message={message} key={message.timestamp} />
                      ))}
                      {!loadingMessages && messages.length > 0 && hasMore  && (
                        <div className="text-center p-3">
                          <Button className="btn btn-primary" onClick={() => loadMessages(page + 1)}>
                            Load older messages
                          </Button>
                        </div>
                      )}
                      {loadingMessages && (
                        <Row className="my-3">
                            <Col className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                                </div>
                            </Col>
                        </Row>
                      )}
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Button className='btn-icon-only rounded-circle fixed' color='primary' onClick={() =>window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>
            <i className='fa fa-chevron-down' />
          </Button>
        </Container>
    </div>
  );
}
