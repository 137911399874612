/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Container } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';

import { environment } from '../../../enviroment/enviroment';
import AddNewAdvanceBatchModal from './AddNewAdvanceBatchModal';
import AdvanceBatchItemsModal from './AdvanceBatchItemsModal';
import SubscriptionBatchItemsModal from './SubscriptionBatchItemsModal';
import AdvanceBatchList from './AdvanceBatchListComponent';
import SubscriptionBatchList from './SubscriptionBatchListComponent';
import AddNewSubscriptionBatchModal from './AddNewSubscriptionBatchModal';

/**
 * @summary Render AdvanceBatches component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */

class AdvanceBatches extends React.Component {
  constructor(props) {
    super(props);
    this.getAdvanceBatches = this.getAdvanceBatches.bind(this);
    this.getSubscriptionBatches = this.getSubscriptionBatches.bind(this);
    this.state = {
      batch: 'advances',
      advanceBatches: null,
      subscriptionBatches: null,
    };
  }

  componentDidMount() {
    this.getAdvanceBatches();
  }

  getBatchesForType() {
    if (this.state.batch === 'advances') {
      this.getAdvanceBatches();
    } else {
      this.getSubscriptionBatches();
    }
  }

  getConfigurationURL() {
    if (this.state.batch === 'advances') {
      return '/admin/advance_batches_configurations';
    }

    return '/admin/subscription_batches_configurations';
  }

  getAdvanceBatches() {
    axios
      .post(`${environment.baseUrl}/graphql/`, {
        query:
          'query{allAdvanceBatchJobs{id,requestedBy{username},payroll{name},employer{companyName}, dateCreated, status, startDate, endDate}}',
      })
      .then((res) => {
        this.setState({
          advanceBatches: res.data.data.allAdvanceBatchJobs,
          toggleAddNewBatchModal: false,
          toggleBatchItemsModal: false,
        });
      });
  }

  getSubscriptionBatches() {
    axios
      .post(`${environment.baseUrl}/graphql/`, {
        query:
          'query{allSubscriptionBatchJobs{id,requestedBy{username},subscription{name},employer{companyName}, dateCreated, startDate, endDate}}',
      })
      .then((res) => {
        this.setState({
          subscriptionBatches: res.data.data.allSubscriptionBatchJobs,
          toggleAddNewBatchModal: false,
          toggleBatchItemsModal: false,
        });
      });
  }

  handleShowAddNewBatchModal = () => {
    this.setState({
      toggleAddNewBatchModal: !this.state.toggleAddNewBatchModal,
    });
  };

  handleShowAdvanceBatchItemsModal = (batchId) => {
    const matchingBatches = this.state.advanceBatches.filter(
      (batch) => parseInt(batch.id) === parseInt(batchId, 10)
    );
    this.setState({
      toggleBatchItemsModal: !this.state.toggleBatchItemsModal,
      activeBatch: matchingBatches[0],
    });
  };

  handleShowSubscriptionBatchItemsModal = (batchId) => {
    const matchingBatches = this.state.subscriptionBatches.filter(
      (batch) => parseInt(batch.id) === parseInt(batchId, 10)
    );
    this.setState({
      toggleBatchItemsModal: !this.state.toggleBatchItemsModal,
      activeBatch: matchingBatches[0],
    });
  };

  render() {
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Advance Batches
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid>
          <div className="card mt--6">
            <div className="card-body d-flex justify-content-between">
              <div style={{ width: '200px' }}>
                <Select
                  options={[
                    {
                      value: 'advances',
                      label: 'Advances',
                    },
                    {
                      value: 'subscriptions',
                      label: 'Subscriptions',
                    },
                  ]}
                  defaultValue={{
                    value: 'advances',
                    label: 'Advances',
                  }}
                  onChange={(obj) => {
                    this.setState({ batch: obj.value }, () =>
                      this.getBatchesForType()
                    );
                  }}
                />
              </div>
              <div>
                <Button
                  color="secondary"
                  onClick={() => this.handleShowAddNewBatchModal()}
                  className="btn btn-secondary">
                  New Batch
                </Button>
                <Link
                  to={this.getConfigurationURL()}
                  className="btn btn-secondary">
                  Configurations
                </Link>
              </div>
            </div>
          </div>
        </Container>

        {this.state.batch === 'advances' ? (
          <AdvanceBatchList
            advanceBatches={this.state.advanceBatches}
            handleShowBatchItemsModal={this.handleShowAdvanceBatchItemsModal}
          />
        ) : (
          <SubscriptionBatchList
            subscriptionBatches={this.state.subscriptionBatches}
            handleShowBatchItemsModal={
              this.handleShowSubscriptionBatchItemsModal
            }
          />
        )}

        {this.state.batch === 'advances' &&
          !!this.state.toggleAddNewBatchModal && (
            <AddNewAdvanceBatchModal
              getAdvanceBatches={this.getAdvanceBatches}
              toggleAddNewBatchModal={this.state.toggleAddNewBatchModal}
              handleShowAddNewBatchModal={this.handleShowAddNewBatchModal}
            />
          )}

        {this.state.batch === 'subscriptions' &&
          !!this.state.toggleAddNewBatchModal && (
            <AddNewSubscriptionBatchModal
              getSubscriptionBatches={this.getSubscriptionBatches}
              toggleAddNewBatchModal={this.state.toggleAddNewBatchModal}
              handleShowAddNewBatchModal={this.handleShowAddNewBatchModal}
            />
          )}

        {this.state.batch === 'advances' &&
          this.state.toggleBatchItemsModal && (
            <AdvanceBatchItemsModal
              activeBatch={this.state.activeBatch}
              toggleBatchItemsModal={this.state.toggleBatchItemsModal}
              handleShowBatchItemsModal={this.handleShowAdvanceBatchItemsModal}
            />
          )}

        {this.state.batch === 'subscriptions' &&
          this.state.toggleBatchItemsModal && (
            <SubscriptionBatchItemsModal
              activeBatch={this.state.activeBatch}
              toggleBatchItemsModal={this.state.toggleBatchItemsModal}
              handleShowBatchItemsModal={
                this.handleShowSubscriptionBatchItemsModal
              }
            />
          )}
      </>
    );
  }
}

AdvanceBatches.defaultProps = {
  AdvanceBatches: [],
};

AdvanceBatches.propTypes = {};

export default AdvanceBatches;
