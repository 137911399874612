import React from 'react';
import { Card, CardHeader, Container, Row, Col, Table } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import moment from 'moment';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';
import EmployeeImportModal from './EmployeeImportModal';

const customStyles = {
  container: (base) => ({
    ...base,
    flexGrow: 1,
    margin: '5px',
  }),
};

class EmployeeImport extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      employer: null,
      employers: [],
      files: [],
      statusFilter: 'processed',
      showFileImportModal: false,
    };
  }

  componentDidMount() {
    this.getEmployers();
    this.getEmployeeImportFiles();
  }

  getEmployeeImportFiles = () => {
    this.setState({ loading: true });
    return axios
      .get(`${environment.baseUrl}/employeeimportfiles/`, {
        params: {
          status: this.state.statusFilter,
          employer_id: this.state.employer,
        },
      })
      .then((res) => {
        this.setState({
          files: res.data,
          loading: false,
        });
      });
  };

  getEmployers = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/`, { headers }).then((res) => {
      this.setState({
        employers: res.data.filter((employer) => employer.status !== 'deleted'),
      });
    });
  };

  importData = (id) => {
    Swal.fire({
      title: 'Confirm import',
      text: `Are you sure you want to import this data?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Import that thang!`,
      cancelButtonText: `Cancel import`,
    }).then((swalResult) => {
      if (swalResult.isConfirmed) {
        Swal.fire({
          title: 'Importing that thang',
          showConfirmButton: false,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        axios
          .post(`${environment.baseUrl}/employeeimportfiles/${id}/import/`)
          .then(() => {
            Swal.fire({
              title: 'Importing started',
              text: `File is in queue for importing`,
              showConfirmButton: true,
              icon: 'success',
              allowOutsideClick: false,
            }).then(() => {
              this.getEmployeeImportFiles();
            });
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  processFile = (file) => {
    Swal.fire({
      title: 'Processing file',
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post(`${environment.baseUrl}/employeeimportfiles/${file.id}/process/`)
      .then(() => {
        Swal.fire({
          title: 'Processing complete',
          showConfirmButton: true,
          icon: 'success',
          allowOutsideClick: false,
        });
        this.getEmployeeImportFiles();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  cancelFile = (id) => {
    Swal.fire({
      title: 'Cancelling file',
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post(`${environment.baseUrl}/employeeimportfiles/${id}/cancel/`)
      .then(() => {
        Swal.fire({
          title: 'Cancelling complete',
          showConfirmButton: true,
          icon: 'success',
          allowOutsideClick: false,
        });
        this.getEmployeeImportFiles();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  downloadResults = (id) => {
    Swal.fire({
      title: 'Preparing download',
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post(`${environment.baseUrl}/employeeimportfiles/${id}/download/`)
      .then((res) => {
        Swal.fire({
          title: 'Results download',
          allowOutsideClick: false,
          showConfirmButton: true,
          html: `<a href='${res.data.file_url}'>Click here to download</a>`,
        });
      });
  };

  downloadOriginalFile = (id) => {
    Swal.fire({
      title: 'Preparing download',
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post(
        `${environment.baseUrl}/employeeimportfiles/${id}/downloadImportFile/`,
      )
      .then((res) => {
        Swal.fire({
          title: 'Original file download',
          allowOutsideClick: false,
          showConfirmButton: true,
          html: `<a href='${res.data.file_url}'>Click here to download</a>`,
        });
      });
  };

  render() {
    const { loading, employers, showFileImportModal, files } = this.state;
    return (
      <>
        {showFileImportModal && (
          <EmployeeImportModal
            onClose={() => this.setState({ showFileImportModal: false })}
            refreshList={() => this.getEmployeeImportFiles()}
          />
        )}
        <section className="dashboard">
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      Employee import
                    </h6>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className="mt--7">
            <Row>
              <div className="col-12 mt-3">
                <Card className="shadow">
                  <CardHeader>
                    <Row>
                      <Col className="d-flex">
                        <Select
                          styles={customStyles}
                          options={[
                            { value: null, label: 'All' },
                            ...employers.map((employer) => ({
                              value: employer.id,
                              label: employer.company_name,
                            })),
                          ]}
                          defaultValue={{ value: null, label: 'All' }}
                          onChange={(obj) =>
                            this.setState({ employer: obj.value }, () =>
                              this.getEmployeeImportFiles(),
                            )
                          }
                          placeholder="Filter by employer"
                        />
                        <Select
                          styles={customStyles}
                          options={[
                            {
                              value: 'processed,uploading,importing',
                              label: 'Processing, processed, importing',
                            },
                            {
                              value: 'imported',
                              label: 'Imported',
                            },
                            {
                              value: 'uploaded',
                              label: 'Uploaded',
                            },
                            { value: 'cancelled', label: 'Cancelled' },
                            { value: null, label: 'All' },
                          ]}
                          defaultValue={{
                            value: 'processed,uploading,importing',
                            label: 'Processing, processed, importing',
                          }}
                          onChange={(obj) =>
                            this.setState({ statusFilter: obj.value }, () =>
                              this.getEmployeeImportFiles(),
                            )
                          }
                          placeholder="Filter by status"
                        />
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <button
                          className="btn ml-2"
                          onClick={() =>
                            this.setState({ showFileImportModal: true })
                          }
                        >
                          Upload file
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {loading ? (
                    <Row className="my-3">
                      <Col className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                      style={{
                        backgroundColor: 'white',
                        borderBottom: '0.5pt solid #f7fafc',
                      }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            onClick={() => this.sortBy('first_name')}
                          >
                            File
                          </th>
                          <th scope="col">Employer</th>
                          <th scope="col">Status</th>
                          <th scope="col">Uploaded by</th>
                          <th scope="col">Date uploaded</th>
                          <th scope="col">Downloads</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {files.map((file, index) => (
                          <tr key={index}>
                            <td>{file.file}</td>
                            <td>{file.employer.company_name}</td>
                            <td>
                              {[
                                'error',
                                'error_processing',
                                'error_importing',
                              ].includes(file.status) && (
                                <span className="badge badge-danger">
                                  {file.status}
                                </span>
                              )}
                              {['cancelled'].includes(file.status) && (
                                <span className="badge badge-warning">
                                  {file.status}
                                </span>
                              )}
                              {['processing', 'processed', 'uploaded'].includes(
                                file.status,
                              ) && (
                                <span className="badge badge-info">
                                  {file.status}
                                </span>
                              )}
                              {['importing', 'imported'].includes(
                                file.status,
                              ) && (
                                <span className="badge badge-success">
                                  {file.status}
                                </span>
                              )}
                            </td>
                            <td>
                              {file.user_uploaded &&
                                file.user_uploaded.first_name}
                            </td>
                            <td>
                              {moment(file.date_created).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td align="left">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  this.downloadOriginalFile(file.id)
                                }
                              >
                                Original file
                              </button>
                              {['processed', 'importing', 'imported'].includes(
                                file.status,
                              ) && (
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => this.downloadResults(file.id)}
                                >
                                  Results
                                </button>
                              )}
                            </td>
                            <td align="right">
                              {[
                                'uploaded',
                                'processed',
                                'importing',
                                'processing',
                              ].includes(file.status) && (
                                <>
                                  <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => this.importData(file.id)}
                                  >
                                    Import
                                  </button>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => this.cancelFile(file.id)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default EmployeeImport;
