import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import {
  Button,
  Card,
  CardTitle,
  CardText,
  Container,
  Form,
  Row,
  Label,
  Col,
  Input,
  FormGroup,
} from 'reactstrap';

/**
 * @summary Render FortnightlyPayRollForm component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const FortnightlyPayRollForm = (props) => {
  const { handleInputChange, fortnightlyPayrollPayload, triedSubmitting } =
    props;

  return (
    <Container>
      <div>
        <Card body outline color="secondary">
          <CardTitle tag="h5">Fortnightly - day based</CardTitle>
          <CardText>
            <Form>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="payCycleName">Name</Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      name="payCycleName"
                      id="payCycleName"
                      placeholder="Pay Cycle Name e.g. CompanyX - Fortnightly"
                      onChange={(e) => handleInputChange(e)}
                      invalid={
                        triedSubmitting &&
                        !fortnightlyPayrollPayload?.payCycleName?.trim()
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="initialPayDate">Initial Pay Date</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      name="initialPayDate"
                      id="initialPayDate"
                      placeholder="initial pay date"
                      onChange={(e) => handleInputChange(e)}
                      invalid={
                        triedSubmitting &&
                        !fortnightlyPayrollPayload?.initialPayDate
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Button size="sm" color="primary">
                      Save{' '}
                    </Button>{' '}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardText>
        </Card>
        <br />
      </div>{' '}
      <br />
    </Container>
  );
};

FortnightlyPayRollForm.defaultProps = {};

FortnightlyPayRollForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
};

export default FortnightlyPayRollForm;
