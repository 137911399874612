import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { environment } from '../../../../enviroment/enviroment';
import apiResponseHandler from '../../../../utils/apiResponseHandler';
import 'react-datepicker/dist/react-datepicker.css';

class EmployeeDocumentEmailModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      employer: null,
      file: null,
      singleIssueDate: null,
      rangeIssueDate: [null, null],
      distributionTime: null,
      singularDocumentName: '',
      showModal: false,
      email: '',
      reason: '',
    };
  }

  componentDidMount(prevProps, prevState) {
    if (this.props.documentType === 'irp5s') {
      this.setState({
        singularDocumentName: 'IRP5',
      });
    } else if (this.props.documentType === 'payslips') {
      this.setState({
        singularDocumentName: 'Payslip',
      });
    } else if (this.props.documentType === 'timesheets') {
      this.setState({
        singularDocumentName: 'Timesheet',
      });
    } else if (this.props.documentType === 'rosters') {
      this.setState({
        singularDocumentName: 'Roster',
      });
    }

    this.setState({
      showModal: this.props.showModal,
    });
  }

  confirmSend = async () => {
    if (!this.validateEmail(this.state.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid email address',
      });
      return;
    }
    const action = 'email'
    const result = await Swal.fire({
      title: `${action} ${this.state.singularDocumentName.toLowerCase()}?`,
      text: `Are you sure you want to \n${action} the ${this.state.singularDocumentName.toLowerCase()} for \n${this.props.employeeData.first_name} ${this.props.employeeData.last_name}?`,
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, send it!',
    });
    if (result.isConfirmed) {
      this.handleSendDocument();
    }
    return false;
  };

  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  handleSendDocument = async () => {
    Swal.fire({
      title: `Sending ${this.state.singularDocumentName.toLowerCase()}`,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const bodyFormData = new FormData();
    bodyFormData.append('email', this.state.email);
    bodyFormData.append('reason', this.state.reason);

    let url = `${environment.baseUrl}/employee_payslips/${this.props.documentId}/email/`;
    if (this.props.documentType === 'irp5s') {
      url = `${environment.baseUrl}/employee_irp5s/email/${this.props.documentId}/email/`;
    } 
    else if (this.props.documentType === 'timesheets') {
      url = `${environment.baseUrl}/employee_timesheet/${this.props.documentId}/email/`;
    }
    else if (this.props.documentType === 'rosters') {
      url = `${environment.baseUrl}/employee_roster/${this.props.documentId}/email/`;
    }

    axios.post(url, bodyFormData)
    .then((res) => {
      Swal.fire({
        icon: 'success',
        title: `Success`,
        text: `${this.state.singularDocumentName} sent successfully`,
      });
      this.props.updateModalState(false);
    })
    .catch((error) => {
      apiResponseHandler.handleApiError('Error', error);
    });
  }

  render() {
    return (
        <Modal isOpen={this.state.showModal} size="lg">
          <ModalHeader><h1>Share {this.state.singularDocumentName}</h1></ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="reasonText">Reason</Label>
              <Input
                type="text"
                name="reason"
                id="reasonText"
                placeholder='Reason for sending the email'
                value={this.state.reason}
                onChange={(e) => this.setState({ reason: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="emailText">Recepient Email</Label>
              <Input
                type="email"
                name="email"
                id="emailText"
                placeholder='Email address to send the document to'
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type='submit'
              onClick={this.confirmSend}
              disabled={this.state.loading || this.state.email === '' || this.state.reason === ''}>
              Send Email
            </Button>
            <Button color="secondary" onClick={() => this.props.updateModalState(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
    );
  }
}

export default EmployeeDocumentEmailModal;
