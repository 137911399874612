/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import Swal from 'sweetalert2';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { useEnabledFeatureFlag } from '../../../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary Render AdvanceBatches component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const RiskyAdvancesTable = (props) => {
  const [state, setState] = useState({
    riskyAdvances: null,
    loading: true,
  });

  const isProcessUnflaggedRiskyAdvancePaymentsEnabled = useEnabledFeatureFlag(
    FeatureFlags.PROCESS_UNFLAGGED_RISKY_ADVANCE_PAYMENTS_AUTOMATICALLY
  );

  /**
   * @summary Get a list of risky, pending advances
   *
   */
  const getRiskyAdvances = () => {
    setState({
      ...state,
      loading: true,
    });
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios
      .get(`${environment.baseUrl}/advancerequests/`, {
        headers,
        params: {
          status: 'pending',
          risky: true,
          page_size: 40,
        },
      })
      .then((res) => {
        setTimeout(() => {
          setState({
            ...state,
            loading: false,
            riskyAdvances: res.data,
          });
        }, 2000);
      });
  };

  useEffect(() => {
    getRiskyAdvances();
  }, []);

  /**
   * @summary Loader displayed while fetching advances
   *
   * @returns {Component}
   */
  const loadingComponent = () => {
    return (
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * @summary Reject a risky advance
   *
   * @param {string} id the advance id to be rejected
   */
  const rejectRiskyAdvance = (id) => {
    setState({
      ...state,
      loading: true,
    });
    props.changeAdvanceStatus(id, 'rejected').then((_res) => {
      getRiskyAdvances();
    });
  };

  /**
   * @summary unflag a risky advance
   *
   * @param {string} id the advance id to be marked as non-risky
   */
  const markAsNotRisky = (id) => {
    setState({
      ...state,
      loading: true,
    });
    const payload = { is_risky: false };
    axios
      .patch(`${environment.baseUrl}/advancerequests/${id}/`, payload)
      .then(() => {
        Swal.fire({
          title: `Advance has been unflagged`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        getRiskyAdvances();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setState({
          ...state,
          loading: false,
        });
      });
  };

  /**
   * @summary unflag a risky advance and set payment to be processed automatically.
   *
   * @param {string} id the advance id to be marked as non-risky
   */
  const markAsNotRiskyAndProcessPaymentAutomatically = (id) => {
    setState({
      ...state,
      loading: true,
    });
    const url = `${environment.baseUrl}/advancerequests/${id}/set_not_risky/`;
    axios
      .patch(url, {})
      .then(() => {
        Swal.fire({
          title: `Advance has been unflagged and payment will now be processed automatically`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        getRiskyAdvances();
      })
      .catch((error) => {
        if (error.response && error.response.status === 405) {
          Swal.fire({
            title: error.response.data,
            icon: 'warning',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          apiResponseHandler.handleApiError('Error', error);
          setState({
            ...state,
            loading: false,
          });
        }
        getRiskyAdvances();
      });
  };

  return (
    <>
      {state.loading ? (
        loadingComponent()
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Employee</th>
              <th>Employer</th>
              <th>Amount</th>
              <th>Requested On</th>

              <th>Reason Flagged</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.riskyAdvances.map((advanceRequest) => {
              return (
                <tr key={advanceRequest.id}>
                  <td>{advanceRequest.id}</td>
                  <td>
                    <Link to={`/admin/employees/${advanceRequest.employee.id}`} disabled={state?.loading || false}>
                      {`${advanceRequest.employee.first_name} ${advanceRequest.employee.last_name}`}
                    </Link>
                  </td>
                  <td>
                    {advanceRequest.employee.employer &&
                      `${advanceRequest.employee.employer.company_name}`}
                  </td>

                  <td>R {advanceRequest.amount_requested}</td>
                  <td>
                    {moment(advanceRequest.date_created).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                  </td>

                  <td>
                    <ul>
                      {!!advanceRequest.risky_reasons &&
                        advanceRequest.risky_reasons
                          .split(',')
                          .map((riskyReason) => {
                            return <li key={riskyReason}>{riskyReason}</li>;
                          })}
                    </ul>
                  </td>

                  <td className="justify-content-start">
                    <Link to={`/admin/advances/${advanceRequest.id}`} disabled={state?.loading || false}>
                      <Button size="sm" color="primary">
                        View
                      </Button>
                    </Link>
                    <br />
                    <br />
                    <Button
                      size="sm"
                      color="success"
                      disabled={state?.loading || false}
                      onClick={() =>
                        isProcessUnflaggedRiskyAdvancePaymentsEnabled
                          ? markAsNotRiskyAndProcessPaymentAutomatically(
                              advanceRequest.id
                            )
                          : markAsNotRisky(advanceRequest.id)
                      }
                    >
                      Mark as not risky
                    </Button>{' '}
                    <br />
                    <br />
                    <Button
                      size="sm"
                      color="danger"
                      disabled={state?.loading || false}
                      onClick={() => rejectRiskyAdvance(advanceRequest.id)}
                    >
                      Reject
                    </Button>{' '}
                  </td>
                </tr>
              );
            })}{' '}
          </tbody>
        </Table>
      )}
    </>
  );
};

RiskyAdvancesTable.defaultProps = {};

RiskyAdvancesTable.propTypes = {
  changeAdvanceStatus: PropTypes.func.isRequired,
};

export default RiskyAdvancesTable;
