/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
import { Card, CardHeader, Row, Col, CardFooter } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { readString } from 'react-papaparse';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

const AdvancePaymentFileUploadModal = (props) => {
  const [state, setState] = useState({
    csvData: null,
    file: null,
  });
  const [loading, setLoading] = useState({});

  const { showAdvancePaymentFileUploadModalModal } = props;

  const handleFiles = (files) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      setState({
        loading: false,
        csvData: reader.result,
        markAsPaidIds: readString(reader.result, { header: true })
          .data.filter((item) => item['Statement Reference'])
          .map((item) => item['Statement Reference'].split('-')[1]),
        file: files[0],
      });
      setLoading(false);
    };
    reader.readAsText(files[0]);
  };

  const handleAdvancePaymentFileUploadSubmit = () => {
    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;

    if (state.file === null) {
      Swal.fire({
        title: 'Oops...',
        text: 'Please upload a file to upload',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Uploading payment file',
        willOpen: () => {
          Swal.showLoading();
        },
      });
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      };

      const bodyFormData = new FormData();
      bodyFormData.append('advance_payment_file', state.file);
      bodyFormData.append('uploaded_by', currentUserId);

      axios
        .post(
          `${environment.baseUrl}/advancepaymentfileprocessingjob/`,
          bodyFormData,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            title: 'Upload complete',
            html: ``,
            icon: 'success',
          });
          showAdvancePaymentFileUploadModalModal(false);
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        zIndex: 10,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(100,100,100,0.4)',
      }}>
      <Card
        style={{
          maxWidth: '100vw',
          width: '1000px',
          maxHeight: '100vh',
          margin: 'auto',
          backgroundColor: 'white',
        }}>
        <CardHeader>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleAdvancePaymentFileUploadSubmit()}>
                Mark all as paid
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  showAdvancePaymentFileUploadModalModal(false);
                }}>
                Close
              </button>
            </Col>
          </Row>
        </CardHeader>
        {state.csvData ? (
          loading ? (
            <Row className="my-3">
              <Col className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Col>
            </Row>
          ) : (
            <div style={{ overflow: 'scroll' }}>
              <CsvToHtmlTable
                data={state.csvData || ''}
                csvDelimiter=","
                tableClassName="table table"
                responsive
              />
            </div>
          )
        ) : (
          <Row className="my-3">
            <Col className="d-flex justify-content-center">
              <h5>No file loaded</h5>
            </Col>
          </Row>
        )}
        <CardFooter
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ReactFileReader
              multipleFiles={false}
              fileTypes={['.csv']}
              handleFiles={handleFiles}>
              <button type="button" className="btn btn-primary">
                Choose file
              </button>
            </ReactFileReader>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

AdvancePaymentFileUploadModal.defaultProps = {};

AdvancePaymentFileUploadModal.propTypes = {
  showAdvancePaymentFileUploadModalModal: PropTypes.func.isRequired,
};

export default AdvancePaymentFileUploadModal;
