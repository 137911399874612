/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Alert,
  Button,
  Form,
  Row,
  Label,
  Col,
  Input,
  FormGroup,
} from 'reactstrap';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import Header from '../../../theme/components/Headers/Header';
import { environment } from '../../../enviroment/enviroment';

class EmployerAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      formError: false,
      errors: [],
      triedSubmitting: false,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    const { companyName } = this.state;
    const isPayloadValid = this.validateFormInput();
    if (isPayloadValid) {
      const payload = {
        company_name: companyName,
      };

      axios
        .post(`${environment.baseUrl}/employers/`, payload)
        .then((res) => {
          Swal.fire({
            title: 'Employer has been successfully added',
            text: res.alert_message ? res.alert_message : '',
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 500,
            timerProgressBar: true,
          }).then((swalResponse) => {
            window.location.href = `/admin/employers/${res.data[0].pk}`;
          });
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
          localStorage.clear();
        });
    }
  };

  validateFormInput = () => {
    const errors = [];
    const { companyName } = this.state;

    if (companyName?.trim() === '' || companyName === undefined) {
      errors.push('Company name cannot be blank.');
    }

    this.setState({
      ...this.state,
      errors,
      formError: errors.length > 0,
      triedSubmitting: true,
    });

    return errors.length < 1;
  };

  handleinputchange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <section className="dashboard">
        <Header /> <br />
        {this.state.formError && this.state.errors && (
          <Alert color="danger">
            {this.state.errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </Alert>
        )}
        <div className="container-fluid">
          <br />
          <h3>New Employer</h3>
          <Form
            onSubmit={(event) => this.handleFormSubmit(event, 'post')}
            method="post"
          >
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyName" className="required-input">
                    Company Name
                  </Label>
                  <Input
                    type="text"
                    name="companyName"
                    bsSize="sm"
                    id="companyName"
                    onChange={this.handleinputchange}
                    placeholder="Company Name"
                    invalid={
                      this.state.triedSubmitting &&
                      !this.state.companyName?.trim()
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Button color="primary">Create New Employer</Button>
          </Form>
        </div>
      </section>
    );
  }
}

export default EmployerAdd;
