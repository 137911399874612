import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Alert,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import InputToggle from '../../../components/InputToggle';
import { environment } from '../../../enviroment/enviroment';

const xeroAccountCodes = [
  { value: 202, label: '202 - Payslips Revenue' },
  { value: 203, label: '203 - Communications Revenue' },
  { value: 204, label: '204 - IRP5 Revenue' },
];

const unitTypes = [
  { value: 'registered', label: 'Registered' },
  { value: 'fixed', label: 'Fixed' },
  { value: 'known_universe', label: 'Known Universe' },
];
export const CreateBillableUnit = (props) => {
  const [employerFeatures, setEmployerFeatures] = useState([]);

  const [billableUnitFeatures, setBillableUnitFeatures] = useState([]);

  const [employerBillableUnitConfig, setEmployerBillableUnitConfig] = useState({
    features: [],
    name: '',
    accountCode: '',
    unitType: '',
    unitPrice: 0,
    baseCost: 0,
    enabled: false,
  });

  const getEmployerFeatures = () => {
    const url = `${environment.baseUrl}/employer_features?enabled=true`;
    axios
      .get(url, {
        params: {
          employer_id: props.employerId,
        },
      })
      .then((res) => {
        if (res.data) {
          setEmployerFeatures(res.data);
        }
      });
  };

  const updateBillableUnitConfigProperty = (configField, configValue) => {
    setEmployerBillableUnitConfig((prevState) => ({
      ...prevState,
      [configField]: configValue,
    }));
  };

  const isBillableConfigValid = () => {
    if (employerBillableUnitConfig.name.trim().length === 0) return false;
    if (employerBillableUnitConfig.features.length === 0) return false;
    if (employerBillableUnitConfig.accountCode.length === 0) return false;
    if (employerBillableUnitConfig.unitType.length === 0) return false;
    if (employerBillableUnitConfig.unitPrice <= 0) return false;
    if (employerBillableUnitConfig.baseCost <= 0) return false;
    return true;
  };

  const createNewEmployerBillableUnit = () => {
    const url = `${environment.baseUrl}/employer_billable_units/?employer_id=${props.employerId}`;
    axios
      .post(url, {
        employer_id: props.employerId,
        name: employerBillableUnitConfig.name,
        price_per_unit: employerBillableUnitConfig.unitPrice,
        unit_type: employerBillableUnitConfig.unitType,
        account_code: employerBillableUnitConfig.accountCode,
        enabled: employerBillableUnitConfig.enabled,
        base_cost: employerBillableUnitConfig.baseCost,
        features: employerBillableUnitConfig.features,
      })
      .then((res) => {
        props.setIsCreateNewBillabeUnit(false);
        getEmployerFeatures();
        Swal.fire({
          title: 'Billable Unit Successfully Created',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
      });
  };

  useEffect(() => {
    getEmployerFeatures();
  }, []);

  return (
    <Container>
      <Modal isOpen={props.isOpen} size="lg" style={{ maxWidth: '90%' }}>
        <ModalHeader>Create Billable Unit</ModalHeader>

        <ModalBody>
          <div className="p-1">
            <ListGroup>
              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Features</strong>
                  </Col>
                  <Col className="col-8">
                    <Select
                      isMulti
                      isSearchable
                      onChange={(features) => {
                        setEmployerBillableUnitConfig((prevState) => ({
                          ...prevState,
                          features: features
                            ? features.map((a) => a.value)
                            : [],
                        }));
                      }}
                      options={employerFeatures.map((empFeature) => ({
                        value: empFeature.id,
                        label: empFeature.name,
                      }))}
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Name</strong>
                  </Col>
                  <Col className="col-8">
                    <Input
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                      onChange={(e) =>
                        updateBillableUnitConfigProperty('name', e.target.value)
                      }
                      type="text"
                      bsSize="md"
                      required
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Account Code</strong>
                  </Col>
                  <Col className="col-8">
                    <Select
                      isSearchable
                      onChange={(accountCode) => {
                        setEmployerBillableUnitConfig((prevState) => ({
                          ...prevState,
                          accountCode: accountCode.value,
                        }));
                      }}
                      options={xeroAccountCodes}
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Unit Type</strong>
                  </Col>
                  <Col className="col-8">
                    <Select
                      isSearchable
                      onChange={(uType) => {
                        updateBillableUnitConfigProperty(
                          'unitType',
                          uType.value
                        );
                      }}
                      options={unitTypes}
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Unit Price</strong>
                  </Col>
                  <Col className="col-2">
                    <Input
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                      onChange={(e) =>
                        updateBillableUnitConfigProperty(
                          'unitPrice',
                          Number(e.target.value)
                        )
                      }
                      type="number"
                      bsSize="md"
                      required
                    />
                  </Col>

                  <Col className="col-1">
                    <strong className="float-right">Base Cost</strong>
                  </Col>
                  <Col className="col-2">
                    <Input
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                      onChange={(e) =>
                        updateBillableUnitConfigProperty(
                          'baseCost',
                          Number(e.target.value)
                        )
                      }
                      type="number"
                      bsSize="md"
                      required
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">Enabled</strong>
                  </Col>
                  <Col className="col-8">
                    <InputToggle
                      checked={employerBillableUnitConfig.enabled}
                      onChange={() =>
                        updateBillableUnitConfigProperty(
                          'enabled',
                          !employerBillableUnitConfig.enabled
                        )
                      }
                      offstyle="btn-danger"
                      onstyle="btn-success"
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={!isBillableConfigValid()}
            color="primary"
            onClick={() => createNewEmployerBillableUnit()}>
            Create Billable Unit
          </Button>
          <Button
            color="secondary"
            onClick={() => props.setIsCreateNewBillabeUnit(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
