import React, { useState, useEffect } from 'react';
import {
  Modal,
  Col,
  ModalHeader,
} from 'reactstrap';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import './AdvanceHistoryModal.css';
import AdvanceHistoryModalTable from './AdvanceHistoryModalTable';
import EmployeeModalTable from './EmployeeModalTable';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { IAdvanceHistoryReturnType, IAdvanceRequestType } from '../../../types/AdvanceRequestTypes';

interface AdvanceHistoryModalProps {
  advanceRequest: IAdvanceRequestType;
  showAdvanceHistoryModal: boolean;
  setShowAdvanceHistoryModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvanceHistoryModal: React.FC<AdvanceHistoryModalProps> = (props) => {
  const [advanceHistoryLoading, setAdvanceHistoryLoading] = useState(false);
  const [advanceHistory, setAdvanceHistory] = useState<IAdvanceHistoryReturnType>();
  const [advanceHistoryError, setAdvanceHistoryError] = useState<any>();

  useEffect(() => {
    getAdvanceRequestHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdvanceRequestHistory = () => {
    setAdvanceHistoryLoading(true);
    axios
      .get(
        `${environment.baseUrl}/advancerequests/${props.advanceRequest.id}/payment_history/`
      )
      .then((res) => {
        setAdvanceHistory(res.data);
      })
      .catch((error) => {
        setAdvanceHistoryError(error.response)
        apiResponseHandler.handleApiError('Error', error);
      });
    setAdvanceHistoryLoading(false);
  };

  return (
    <Modal className="modal-main" isOpen={props.showAdvanceHistoryModal}>
      <div className="history-modal-header-container">
        <ModalHeader className="history-modal-header">
          Employee Transaction History
        </ModalHeader>
        <button
          className="btn bg-transparent btn-outline-transparent"
          onClick={() => props.setShowAdvanceHistoryModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M18 6L6 18"
              stroke="#062133"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#062133"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      {advanceHistoryLoading ? (
        <Col className="d-flex justify-content-center mt-3">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      ) : advanceHistoryError ? (
        <div style={{display: 'flex', flexDirection: 'column' }}>
        <text>Error status: {advanceHistoryError.status}</text>
        <text>Error message: {advanceHistoryError.data.message}</text>
        </div>
      ) : (
        advanceHistory && (
          <>
            <EmployeeModalTable
              employee={props.advanceRequest.employee}
              advance={props.advanceRequest}
            />
            <div>
              <ModalHeader className="history-table-header">
                Transaction History
              </ModalHeader>
              <AdvanceHistoryModalTable advanceHistory={advanceHistory} />
            </div>
          </>
        )
      )}
    </Modal>
  );
};

export default AdvanceHistoryModal;
