/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Alert,
  Container,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { formatISODateTime } from '../../../utils/formatters';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import WeeklyPayRollForm from '../Payroll/WeeklyPayRollForm';
import FortnightlyPayRollForm from '../Payroll/FortnightlyPayRollForm';
import MonthlyDateBasedPayRollForm from '../Payroll/MonthlyDateBasedPayrollForm';
import MonthlyDayBasedPayRollForm from '../Payroll/MonthlyDayBasedPayRollForm';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary Render AddEmployerPayrollModal component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const AddEmployerPayrollModal = (props) => {
  const { toggle, showAddPayrollModal, employerId } = props;

  const [errors, setErrors] = useState([]);
  const [triedSubmitting, setTriedSubmitting] = useState(false);

  const [payrollType, setPayrollType] = useState(0);
  const [weeklyPayrollPayload, setWeeklyPayrollPayload] = useState({
    frequency: 'weekly',
    frequencyType: 'day based',
  });

  const [fortnightlyPayrollPayload, setFortnightlyPayrollPayload] = useState({
    frequency: 'fortnightly',
    frequencyType: 'day based',
  });

  const [monthlyDayBasedPayrollPayload, setMonthlyDayBasedPayrollPayload] =
    useState({ frequency: 'monthly', frequencyType: 'day based' });

  const [monthlyDateBasedPayrollPayload, setMonthlyDateBasedPayrollPayload] =
    useState({ frequency: 'monthly', frequencyType: 'date based' });

  const setPayrollFrequency = (event) => {
    setPayrollType(event.target.value);
    setErrors([]);
  };

  const handleInputChangeWeekly = (event) => {
    setWeeklyPayrollPayload({
      ...weeklyPayrollPayload,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChangeFortnightly = (event) => {
    setFortnightlyPayrollPayload({
      ...fortnightlyPayrollPayload,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChangeMonthlyDayBased = (event) => {
    setMonthlyDayBasedPayrollPayload({
      ...monthlyDayBasedPayrollPayload,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChangeMonthlyDateBased = (event) => {
    setMonthlyDateBasedPayrollPayload({
      ...monthlyDateBasedPayrollPayload,
      [event.target.name]: event.target.value,
    });
  };

  const selectPayrollTypesComponent = () => (
    <div onChange={setPayrollFrequency}>
      <Col md={12}>
        <FormGroup check inline>
          <Label>
            <Input type="radio" name="payCycleType" value="weekly" /> Weekly
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label>
            <Input type="radio" name="payCycleType" value="fortnightly" />{' '}
            Fortnightly
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label>
            <Input type="radio" name="payCycleType" value="monthlyDayBased" />{' '}
            Monthly - Day Based
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label>
            <Input type="radio" name="payCycleType" value="monthlyDateBased" />{' '}
            Monthly - Date Based
          </Label>
        </FormGroup>
      </Col>
    </div>
  );

  const handleWeeklyPayrollFormSubmit = (event) => {
    setTriedSubmitting(true);
    event.preventDefault();
    const isValid = isWeeklyPayrollValid();
    if (isValid) {
      const payload = {
        name: weeklyPayrollPayload.payCycleName,
        frequency: weeklyPayrollPayload.frequency,
        frequency_type: weeklyPayrollPayload.frequencyType,
        pay_day: weeklyPayrollPayload.payDay,
        employer: employerId,
        advance_recon_date: weeklyPayrollPayload.advanceReconDate,
      };
      postPayCycle(payload);
      toggle();
    }
  };

  const handleFortnightlyPayrollFormSubmit = (event) => {
    setTriedSubmitting(true);
    event.preventDefault();
    const isValid = isFortnightlyPayrollValid();
    if (isValid) {
      const payload = {
        name: fortnightlyPayrollPayload.payCycleName,
        frequency: fortnightlyPayrollPayload.frequency,
        frequency_type: fortnightlyPayrollPayload.frequencyType,
        initial_pay_date: formatISODateTime(
          fortnightlyPayrollPayload.initialPayDate,
        ),
        employer: employerId,
      };
      postPayCycle(payload);
      toggle();
    }
  };

  const handleMonthlyDateBasedPayrollFormSubmit = (event) => {
    setTriedSubmitting(true);
    event.preventDefault();
    const isValid = isMonthlyDateBasedPayrollValid();
    if (isValid) {
      const payload = {
        name: monthlyDateBasedPayrollPayload.payCycleName,
        frequency: monthlyDateBasedPayrollPayload.frequency,
        frequency_type: monthlyDateBasedPayrollPayload.frequencyType,
        pay_date: monthlyDateBasedPayrollPayload.payDate,
        employer: employerId,
      };
      postPayCycle(payload);
      toggle();
    }
  };

  const handleMonthlyDayBasedPayrollFormSubmit = (event) => {
    setTriedSubmitting(true);
    event.preventDefault();
    const isValid = isMonthlyDayBasedPayrollValid();
    if (isValid) {
      const payload = {
        name: monthlyDayBasedPayrollPayload.payCycleName,
        frequency: monthlyDayBasedPayrollPayload.frequency,
        frequency_type: monthlyDayBasedPayrollPayload.frequencyType,
        pay_day: monthlyDayBasedPayrollPayload.payDay,
        pay_day_position: monthlyDayBasedPayrollPayload.payDayPosition,
        employer: employerId,
      };
      postPayCycle(payload);
      toggle();
    }
  };

  const isWeeklyPayrollValid = () => {
    setErrors([]);
    const weeklyPayRollErrors = [];
    if (
      !weeklyPayrollPayload.payCycleName ||
      weeklyPayrollPayload.payCycleName.trim() === ''
    ) {
      weeklyPayRollErrors.push('Please add a name for your pay cycle.');
    }

    if (
      !weeklyPayrollPayload.payDay ||
      weeklyPayrollPayload.payDay < 0 ||
      weeklyPayrollPayload.payDay > 6
    ) {
      weeklyPayRollErrors.push(
        'Please select the day of the week employees get paid.',
      );
    }

    if (
      !weeklyPayrollPayload.advanceReconDate ||
      weeklyPayrollPayload.advanceReconDate < 0 ||
      weeklyPayrollPayload.advanceReconDate > 31
    ) {
      weeklyPayRollErrors.push('Please select the recon date between 1-31.');
    }
    setErrors(weeklyPayRollErrors);
    return weeklyPayRollErrors.length === 0;
  };

  const isFortnightlyPayrollValid = () => {
    const fortnightlyPayrollErrors = [];

    if (
      !fortnightlyPayrollPayload.payCycleName ||
      fortnightlyPayrollPayload.payCycleName.trim() === ''
    ) {
      fortnightlyPayrollErrors.push('Please add a name for your pay cycle.');
    }

    if (!fortnightlyPayrollPayload.initialPayDate) {
      fortnightlyPayrollErrors.push(
        'Please select the initial pay date used to calculate pay days.',
      );
    }
    setErrors(fortnightlyPayrollErrors);
    return fortnightlyPayrollErrors.length === 0;
  };

  const isMonthlyDateBasedPayrollValid = () => {
    const monthlyDateBasedPayrollErrors = [];
    if (
      !monthlyDateBasedPayrollPayload.payCycleName ||
      monthlyDateBasedPayrollPayload.payCycleName.trim() === ''
    ) {
      monthlyDateBasedPayrollErrors.push(
        'Please add a name for your pay cycle.',
      );
    }

    if (
      !monthlyDateBasedPayrollPayload.payDate ||
      monthlyDateBasedPayrollPayload.payDate < 0 ||
      monthlyDateBasedPayrollPayload.payDate > 31
    ) {
      monthlyDateBasedPayrollErrors.push(
        'Please enter a pay date between 1-31.',
      );
    }

    if (
      !monthlyDateBasedPayrollPayload.advanceReconDate ||
      monthlyDateBasedPayrollPayload.advanceReconDate < 0 ||
      monthlyDateBasedPayrollPayload.advanceReconDate > 31
    ) {
      monthlyDateBasedPayrollErrors.push(
        'Please select the recon date between 1-31.',
      );
    }

    setErrors(monthlyDateBasedPayrollErrors);
    return monthlyDateBasedPayrollErrors.length === 0;
  };

  const isMonthlyDayBasedPayrollValid = () => {
    const monthlyDayBasedPayrollErrors = [];

    if (
      !monthlyDayBasedPayrollPayload.payCycleName ||
      monthlyDayBasedPayrollPayload.payCycleName.trim() === ''
    ) {
      monthlyDayBasedPayrollErrors.push(
        'Please add a name for your pay cycle.',
      );
    }

    if (
      !monthlyDayBasedPayrollPayload.payDayPosition ||
      monthlyDayBasedPayrollPayload.payDayPosition === 0 ||
      monthlyDayBasedPayrollPayload.payDayPosition === 1
    ) {
      monthlyDayBasedPayrollErrors.push('Please select the day position.');
    }

    if (
      !monthlyDayBasedPayrollPayload.payDay ||
      monthlyDayBasedPayrollPayload.payDay < 0 ||
      monthlyDayBasedPayrollPayload.payDay > 6
    ) {
      monthlyDayBasedPayrollErrors.push(
        'Please select the day of the week employees get paid.',
      );
    }

    if (
      !monthlyDayBasedPayrollPayload.advanceReconDate ||
      monthlyDayBasedPayrollPayload.advanceReconDate < 0 ||
      monthlyDayBasedPayrollPayload.advanceReconDate > 31
    ) {
      monthlyDayBasedPayrollErrors.push(
        'Please select the recon date between 1-31.',
      );
    }

    setErrors(monthlyDayBasedPayrollErrors);
    return monthlyDayBasedPayrollErrors.length === 0;
  };

  const postPayCycle = (payload) => {
    const url = `${environment.baseUrl}/pay_cycles/`;
    axios
      .post(url, payload)
      .then((res) => {
        Swal.fire({
          title: 'Payroll has been successfully added',
          text: res.alert_message ? res.alert_message : '',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  return (
    <Container>
      <Modal isOpen={showAddPayrollModal} toggle={toggle} size="lg">
        <ModalHeader>Add new payroll</ModalHeader>

        {selectPayrollTypesComponent()}

        <ModalBody>
          <span>
            {errors.length > 0 && (
              <Alert color="danger">
                {errors.map((error) => (
                  <li>{error}</li>
                ))}
              </Alert>
            )}
          </span>
          {payrollType === 'weekly' && (
            <Form onSubmit={handleWeeklyPayrollFormSubmit}>
              <WeeklyPayRollForm
                handleInputChange={handleInputChangeWeekly}
                weeklyPayrollPayload={weeklyPayrollPayload}
                triedSubmitting={triedSubmitting}
              />
            </Form>
          )}

          {payrollType === 'fortnightly' && (
            <Form onSubmit={handleFortnightlyPayrollFormSubmit}>
              <FortnightlyPayRollForm
                handleInputChange={handleInputChangeFortnightly}
                fortnightlyPayrollPayload={fortnightlyPayrollPayload}
                triedSubmitting={triedSubmitting}
              />
            </Form>
          )}

          {payrollType === 'monthlyDayBased' && (
            <Form onSubmit={handleMonthlyDayBasedPayrollFormSubmit}>
              <MonthlyDayBasedPayRollForm
                handleInputChange={handleInputChangeMonthlyDayBased}
                monthlyDayBasedPayrollPayload={monthlyDayBasedPayrollPayload}
                triedSubmitting={triedSubmitting}
              />
            </Form>
          )}

          {payrollType === 'monthlyDateBased' && (
            <Form onSubmit={handleMonthlyDateBasedPayrollFormSubmit}>
              <MonthlyDateBasedPayRollForm
                handleInputChange={handleInputChangeMonthlyDateBased}
                monthlyDateBasedPayrollPayload={monthlyDateBasedPayrollPayload}
                triedSubmitting={triedSubmitting}
              />
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

AddEmployerPayrollModal.defaultProps = {};

AddEmployerPayrollModal.propTypes = {};

export default AddEmployerPayrollModal;
