import React from 'react';
import { Table } from 'reactstrap';
import { formatUTCToLocalDateTime } from '../../../utils/formatters';
import { IAdvanceHistoryReturnType } from '../../../types/AdvanceRequestTypes';

interface AdvanceHistoryModalTableProps {
  advanceHistory: IAdvanceHistoryReturnType; 
}

const getAdvanceRequestIcon = (status: string) => {
  // Error states
  if (['payment_failed'].includes(status)) {
    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="16.5"
          cy="16"
          r="12"
          fill="white"
          stroke="#DE411F"
          stroke-width="2"
        />
        <path
          d="M21.5 12L12.5 21M12.5 12L21.5 21"
          stroke="#DF411F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
  // Warning states
  if (['payment_paused', 'payment_reversed'].includes(status)) {
    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="16.5"
          cy="16"
          r="12"
          fill="white"
          stroke="#DDAD01"
          stroke-width="2"
        />
        <path
          d="M16 21.5V21.51M16 17.5V10"
          stroke="#DDAD01"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
  // Success states
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="16.5"
        cy="16"
        r="12"
        fill="white"
        stroke="#36AF3B"
        stroke-width="2"
      />
      <path
        d="M10.6667 16.5L14.8333 20.6667L23.1667 12.3333"
        stroke="#36AF3B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AdvanceHistoryModalTable: React.FC<AdvanceHistoryModalTableProps> = (
  props
) => (
    <div className="modal-table-container modal-history-table-container">
      <Table borderless>
        <thead>
          <tr>
            <th scope="col" aria-label="Status Icon" />
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Status Reason</th>
            <th scope="col">Status Description</th>
          </tr>
        </thead>
        <tbody>
          {props.advanceHistory &&
            props.advanceHistory.hits.map((advance) => (
              <tr key={advance.timestamp}>
                <td>{getAdvanceRequestIcon(advance.status)}</td>
                <td style={{ whiteSpace: 'normal' }}>
                  {formatUTCToLocalDateTime(advance.timestamp)}
                </td>
                <td>{advance.status}</td>
                <td>{advance.status_reason}</td>

                <td style={{ whiteSpace: 'normal' }}>
                  {advance.status_description}{' '}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );


export default AdvanceHistoryModalTable;
