import EmployerAdd from '../pages/Admin/Employer/EmployerAdd';
import EmployersList from '../pages/Admin/Employer/EmployersList';
import EmployerDetail from '../pages/Admin/Employer/EmployerDetail';
import EmployeeList from '../pages/Admin/Employee/EmployeeList';
import EmployeeAdd from '../pages/Admin/Employee/EmployeeAdd';
import EmployeeImport from '../pages/Admin/Employee/EmployeeImport';
import AdvanceList from '../pages/Admin/Advances/AdvanceList';
import AdvanceBatches from '../pages/Admin/AdvanceBatches';
import AdvanceBatchConfigurationList from '../pages/Admin/AdvanceBatches/AdvanceBatchConfigurationList';
import SubscriptionBatchConfigurationList from '../pages/Admin/AdvanceBatches/SubscriptionBatchConfigurationList';
import MessagesList from '../pages/Admin/Messages/MessagesList';
import EmployeeDetail from '../pages/Admin/Employee/EmployeeDetail';

// EmployerAdmin
import SmsList from '../pages/Admin/SMS/SmsList';
import UserList from '../pages/Admin/User/UserList';
import UserDetail from '../pages/Admin/User/UserDetail';
import UserAdd from '../pages/Admin/User/UserAdd';
import OtpLogIn from '../pages/Admin/Login/OtpLogin';
import RegistrationList from '../pages/Admin/Registrations/RegistrationList';
import ColdRegistrationAttempts from '../pages/Admin/Registrations/ColdRegistrationAttempts';
import AdvanceDetail from '../pages/Admin/Advances/AdvanceDetail';

// Education Questions
import EducationQuestions from '../pages/Admin/EducationQuestions';
import EditEducationQuestionView from '../pages/Admin/EditEducationQuestionView';
import AddEducationQuestionView from '../pages/Admin/AddEducationQuestion';
import UserPermissions from '../pages/Admin/User/UserPermissions';
import UserAuditLog from '../pages/Admin/User/UserAuditLog';
import SupportTicketList from '../pages/Admin/SupportTickets/SupportTicketList';
import AdvancePaymentFiles from '../pages/Admin/AdvancePaymentFiles';
import EmployerReconsPage from '../pages/Admin/EmployerRecons/EmployerReconsPage';

import Tools from '../pages/Admin/Tools';
import MergeProfiles from '../pages/Admin/Tools/MergeProfiles';
import EmployeeBlockDates from '../pages/Admin/Advances/EmployeeBlockDates';

const routes = [
  // Employers Routes
  {
    path: '/employers',
    name: 'Employers',
    icon: 'fas fa-user',
    component: EmployersList,
    layout: '/admin',
  },
  {
    path: '/employers/add',
    name: 'Employer Add',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployerAdd,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/employers/:empId',
    name: 'Employer Detail',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployerDetail,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/employers/:empId/recons',
    name: 'Employer Recons',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployerReconsPage,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/employers/:empId/employees',
    name: 'Employer Edit',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployeeList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/employees/import',
    name: 'Employer Import',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployeeImport,
    layout: '/admin',
    invisible: true,
  },
  // Employee Routes
  {
    path: '/employees',
    name: 'Employees',
    icon: 'fas fa-users',
    component: EmployeeList,
    layout: '/admin',
  },
  {
    path: '/employees/add',
    name: 'Employee Add',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployeeAdd,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/employees/:empId',
    name: 'Employee Detail',
    icon: 'ni ni-tv-2 text-primary',
    component: EmployeeDetail,
    layout: '/admin',
    invisible: true,
  },
  // Advance Routes
  {
    path: '/advances',
    name: 'Advances',
    icon: 'fas fa-money-bill',
    component: AdvanceList,
    layout: '/admin',
    count: true,
    countName: 'advances',
    secondCount: true,
    secondCountName: 'risky_advances',
  },
  // Advance Routes
  {
    path: '/block_dates',
    name: 'Block Dates',
    icon: 'fas fa-money-bill',
    component: EmployeeBlockDates,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/advances/:advId',
    name: 'Advance Edit',
    icon: 'ni ni-key-25 text-info',
    component: AdvanceDetail,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/advance_batches',
    name: 'Advance Batches',
    icon: 'fa fa-tasks',
    component: AdvanceBatches,
    layout: '/admin',
    invisible: false,
  },
  {
    path: '/advance_payment_files',
    name: 'Advance Payment Files',
    component: AdvancePaymentFiles,
    layout: '/admin',
    icon: 'fas fa-file-invoice-dollar',
    invisible: false,
  },
  {
    path: '/advance_batches_configurations',
    name: 'Advance Batches Config',
    component: AdvanceBatchConfigurationList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/subscription_batches_configurations',
    name: 'Subscription Batches Config',
    component: SubscriptionBatchConfigurationList,
    layout: '/admin',
    invisible: true,
  },
  // Messages
  {
    path: '/messages',
    name: 'Messages',
    icon: 'fas fa-comments',
    component: MessagesList,
    layout: '/admin',
  },
  {
    path: '/smslogs',
    name: 'SMS logs',
    icon: 'fas fa-sms',
    component: SmsList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/registrations',
    name: 'Registrations',
    icon: 'fas fa-user-plus',
    component: RegistrationList,
    layout: '/admin',
    count: true,
    countName: 'registrations',
  },
  {
    path: '/coldregistrationattempt',
    name: 'Cold Registration Attempts',
    icon: 'fas fa-user-plus',
    component: ColdRegistrationAttempts,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/tickets',
    name: 'Support Tickets',
    icon: 'fas fa-user-plus',
    component: SupportTicketList,
    layout: '/admin',
    count: true,
    countName: 'supportTickets',
  },
  {
    path: '/tools/merge-profiles',
    name: 'Merge Profiles',
    icon: 'fas fa-align-center',
    component: MergeProfiles,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/users/add',
    name: 'Users',
    icon: 'fas fa-user-shield',
    component: UserAdd,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/users/permissions',
    name: 'Users',
    icon: 'fas fa-user-shield',
    component: UserPermissions,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/users/logs',
    name: 'User logs',
    icon: 'fas fa-user-shield',
    component: UserAuditLog,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'fas fa-user-shield',
    component: UserList,
    layout: '/admin',
  },
  {
    path: '/tools',
    name: 'Tools',
    icon: 'fa fa-support',
    component: Tools,
    layout: '/admin',
  },
  {
    path: '/users/:userId',
    name: 'Users',
    icon: 'fas fa-user-shield',
    component: UserDetail,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: OtpLogIn,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/questions/education',
    name: 'Education Questions',
    icon: 'fa fa-graduation-cap',
    component: EducationQuestions,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/question/add',
    name: 'Add Education Question',
    icon: 'fas fa-users',
    component: AddEducationQuestionView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/question/education/:questionId',
    name: 'Edit Education Question',
    icon: 'fas fa-users',
    component: EditEducationQuestionView,
    layout: '/admin',
    invisible: true,
  },
];
export default routes;
