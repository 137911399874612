import React from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
  FormGroup,
  Alert,
} from 'reactstrap';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';
import PropTypes from "prop-types";

import UserEmployerAndRoleSelect from '../../../components/Users/UserEmployerAndRoleSelect';
import UserTagsSelect from '../../../components/Users/UserTagsSelect';
import UserFeatureSelected from '../../../components/Users/UserFeatureSelected';
import InboxQueryBranchesSelected from '../../../components/Users/InboxQueryBranchesSelected';
import ManageMultiEmployerModal from '../Employer/ManageMultiEmployerModal';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';
import CustomIntlTelInput, { isMobileNumberValid } from '../../../components/Users/CustomIntlTelInput';

let cancelToken;

export const sanitizeMobileNumber = (mobileNumber) => {
  let sanitizedInput = mobileNumber ? mobileNumber.replace(/[^\d]/g, '') : ''; // Remove special characters and spaces
  sanitizedInput = sanitizedInput.replace(/^0+/, ''); // Remove leading zeros
  return sanitizedInput;
};

export const mobileNumberLength = (mobileNumber) => {
  if (mobileNumber.substring(0, 1) === '0') {
    return 10;
  }
  if (
    mobileNumber.substring(0, 2) === '27' ||
    mobileNumber.substring(0, 1) === ''
  ) {
    return 11;
  }
  return 9;
};

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advancerequests: [],
      loading: false,
      save_data: {},
      employer: {},
      employer_features: [],
      user_features: [],
      user_tags: [],
      employers: [],
      availableRoles: [],
      smsLogs: [],
      groups: [],
      formError: false,
      errors: {
        mobileNumberErrors: [],
      },
      mobileNumberExists: 1, // Phone Number Component will validate this apon initial load
      mobile_number: {
        value: '',
        country_code: '27',
        valid: false,
      },
      isLoading: false,
      employer_branches: [],
      selected_branches: [],
    };

    this.handleEmailNotificationChange = this.handleEmailNotificationChange.bind(this);
  }

  componentDidMount() {
    this.getUserData();
    this.getEmployers();
    this.getAvailableRoles();
    this.getUserBranchMappings();
  }

  getUsers = debounce((params) => {
    this.setState({ isLoading: true });
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    axios
      .get(`${environment.baseUrl}/users/`, {
        params: { ...params },
        cancelToken: cancelToken.token,
      })

      .then((res) => {
        // check that the users are not the same
        this.setState({
          mobileNumberExists: res.data.filter(
            (user) =>
              user.mobile_number === params.search && user.id !== this.state.id
          ).length,

          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }, 1000);

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  getAvailableRoles = () => {
    let rolesFor = 'admin';
    if (this.state.employer) {
      rolesFor = 'all';
    }
    axios
      .get(`${environment.baseUrl}/user_roles?roles_for=${rolesFor}`)
      .then((res) => {
        this.setState({ availableRoles: res.data });
      });
  };

  getUserData = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/users/${this.props.match.params.userId}/`)
      .then((res) => {
        this.setState(
          {
            ...res.data,
            loading: false,
            mobile_number: {
              value: sanitizeMobileNumber(res.data.mobile_number),
              country_code: '27',
              valid: true,
            },
          },
          () => {
            if (res.data.employer && res.data.employer.id) {
              this.getEmployerData(res.data.employer.id);
            }
          }
        );
      });
  };

  addUserBranchMapping = (user_id, branch_id_list) => {
    this.setState({ loading: true });
    axios
      .post(`${environment.baseUrl}/add_user_branch_mapping/`, {
        user_id,
        branch_id_list,
      })
      .then((res) => {
        this.setState({ loading: false });
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
          Swal.fire({
            title: "An error occurred when trying to allocate a branch to a User",
            icon: 'error',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
          });
      });
  };


  getUserBranchMappings = () => {
    axios
      .get(`${environment.baseUrl}/get_user_branch_mappings/${this.props.match.params.userId}/`)
      .then((res) => {
        const transformedBranches = res.data.branches.map(branch => ({
          id: branch.branch_id,
          name: branch.branch_name
        }));
        this.setState({ selected_branches: transformedBranches });
      });
  };

  getEmployerData = (employerId) => {
    axios
      .get(`${environment.baseUrl}/employers/${employerId}/`)
      .then((res) => {
        if (res.data.branches && res.data.branches.length > 0) {
          this.setState({
            employer_branches: res.data.branches
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };
  
  afterSetStateFinished(eventName) {
    if (eventName === 'employer') {
      this.getAvailableRoles();
    }
  }

  onEmployerChanged = (event) => {
    this.handleinputchange(event);
  };

  onRolesChanged = (roleIds) => {
    this.setState({
      groups: roleIds,
    });
  };

  updatedUserFeatures = (features) => {
    this.setState({
      user_features: features,
    });
  };

  updatedBranchReceiveQueries = (selected_branches) => {
    this.setState({ selected_branches });
  };

  onTagsChanged = (tags) => {
    this.setState({
      user_tags: tags,
    });
  };

  handleinputchange = (event) => {
    event.preventDefault();
    const eventName = event.target.name;
    let eventValue = event.target.value;
    // this.form.validateFields(event.target);
    if (eventName === 'employer' && eventValue === 'NO_EMPLOYER') {
      eventValue = undefined;
    }
    if (
      event.target.name === 'bank_account_number' &&
      !/^\d+$/.test(event.target.value)
    ) {
      return null;
    }

    this.setState(
      {
        [eventName]: eventValue,
      },
      () => {
        this.afterSetStateFinished(eventName);
      }
    );
  };

  handleEmailNotificationChange(event) {
    this.setState({ receive_email_notifications: event.target.checked });
  }

  validateInputs = () => {
    const errors = {
      mobileNumberErrors: [],
    };
    if (this.state.mobile_number.value && !this.state.mobile_number.valid) {
      errors.mobileNumberErrors.push('User mobile number is incorrect.');
    } else if (
      this.state.mobile_number.valid &&
      this.state.mobileNumberExists !== 0
    ) {
      errors.mobileNumberErrors.push(
        'User already exists for the provided mobile number.'
      );
    }

    this.setState({
      errors,
      formError: Object.values(errors).flat().length > 0,
    });
    return Object.values(errors).flat().length === 0;
  };

  saveData = () => {
    if (this.validateInputs()) {
      let employerId;
      if (typeof this.state.employer === 'object') {
        employerId = this.state.employer.id;
      } else {
        employerId = this.state.employer;
      }

      const payload = {
        ...this.state,
        mobile_number: sanitizeMobileNumber(this.state.mobile_number.value),
        employer: employerId,
        features: this.state.user_features.map((uF) => uF.feature.id),
        tags: this.state.user_tags.map((ut) => ut.tag.id),
      };

      axios
        .patch(
          `${environment.baseUrl}/users/${this.props.match.params.userId}/`,
          payload
        )
        .then((res) => {
          apiResponseHandler.handleApiSuccess('User Saved');
          this.getUserData();

          if (this.state.selected_branches.length > 0) {
            const branch_id_list = this.state.selected_branches.map(branch => branch.id);
            this.addUserBranchMapping(this.props.match.params.userId, branch_id_list);
          }
        })
        .catch((error) =>
          apiResponseHandler.handleApiError('Error while saving user', error)
        );
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  changeActive = (state) => {
    axios
      .patch(
        `${environment.baseUrl}/users/${this.props.match.params.userId}/`,
        { is_active: 'state' }
      )
      .then((res) => {
        apiResponseHandler.handleApiSuccess('User Saved', res);
        this.getUserData();
      })
      .catch((error) =>
        apiResponseHandler.handleApiError('Error while saving user', error)
      );
  };

  deleteUser = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d63030',
      confirmButtonText: 'Yes, delete them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${environment.baseUrl}/users/${this.props.match.params.userId}/`
          )
          .then((res) => {
            Swal.fire({
              title: `User deleted`,
              icon: 'success',
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.props.history.push('/admin/users');
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  render() {
    const {
      loading,
      first_name,
      last_name,
      username,
      email,
      employers,
      mobile_number,
      is_active,
      errors,
      formError,
      receive_email_notifications,
    } = this.state;
    return (
      <section className="dashboard">
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {`${first_name} ${last_name} (${username})`}
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className="mt--7">
          <Row className="pt-5 emp-edit-data">
            <Col>
              {loading ? (
                <Row className="my-3">
                  <Col className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={{ size: '6', offset: '3' }}>
                    <Row>
                      <Col>
                        <Card className="shadow">
                          <CardHeader>
                            <Row className="align-items-center">
                              <Col lg="8">
                                <h3 className="mb-0">Edit user</h3>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}>
                                <ManageMultiEmployerModal
                                  userFeatures={this.state.user_features}
                                  employerSelectedId={this.state.employer?.id}
                                  employers={this.state.employers}
                                  {...this.props}
                                />
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.changeActive(!is_active);
                                  }}>
                                  Make {is_active ? 'Inactive' : 'Active'}
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={this.deleteUser}>
                                  Delete
                                </button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {formError && (
                              <Alert color="danger">
                                {Object.values(errors)
                                  .flat()
                                  .map((error) => (
                                    <li key={error}>{error}</li>
                                  ))}
                              </Alert>
                            )}
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="first_name">First name</Label>
                                  <Input
                                    type="text"
                                    name="first_name"
                                    value={first_name}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="last_name">Last name</Label>
                                  <Input
                                    type="text"
                                    name="last_name"
                                    value={last_name}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="username">Username</Label>
                                  <Input
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="email">Email</Label>
                                  <Input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="mobile_number">
                                    Mobile number
                                  </Label>
                                  <CustomIntlTelInput
                                    customValidation={isMobileNumberValid}
                                    containerClassName="intl-tel-input"
                                    style={{ width: '100%' }}
                                    inputClassName="form-control"
                                    value={`${
                                      mobile_number?.value.length ? '+' : ''
                                    }${sanitizeMobileNumber(
                                      mobile_number.value
                                    )}`}
                                    formatOnInit
                                    onPhoneNumberChange={(
                                      valid,
                                      phone,
                                      selectedCountryData,
                                      fullNumber
                                    ) => {
                                      // check if user exists
                                      this.getUsers({
                                        search:
                                          sanitizeMobileNumber(fullNumber),
                                      });

                                      this.setState({
                                        mobile_number: {
                                          country_code:
                                            selectedCountryData.dialCode ||
                                            '27',
                                          value: fullNumber,
                                          valid,
                                        },
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <UserEmployerAndRoleSelect
                              onEmployerChanged={this.onEmployerChanged}
                              onRolesChanged={this.onRolesChanged}
                              employers={employers}
                              availableRoles={this.state.availableRoles}
                              employerSelectedId={this.state.employer?.id}
                              selectedRoleIds={this.state.groups}
                            />
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="features">Features</Label>
                                  <UserFeatureSelected
                                    userFeatures={this.state.user_features.filter(
                                      (e) => e.enabled
                                    )}
                                    employerFeatures={this.state.employer_features.filter(
                                      (e) => e.enabled
                                    )}
                                    updatedUserFeatures={
                                      this.updatedUserFeatures
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            {this.state.employer_branches.length > 0 && (
                            <>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="query_branches">Receive Queries from these branches</Label>
                                    <InboxQueryBranchesSelected
                                      queryBranches={this.state.employer_branches}
                                      selectedBranches={this.state.selected_branches}
                                      updatedBranchReceiveQueries={this.updatedBranchReceiveQueries}
                                    />
                                    {this.state.selected_branches.length === 0 && (
                                      <span style={{ color: '#FF922B' }}>
                                        At least one branch must be selected to receive queries.
                                      </span>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '10px' }}> {/* Reduce space between "Receive Queries from" and the email toggle */}
                                <Col>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={receive_email_notifications}
                                        onChange={this.handleEmailNotificationChange}
                                      />{' '}
                                      Receive emails for queries
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                            )}
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="employerTag">Tags</Label>
                                  <UserTagsSelect
                                    employerTagsByCategory={
                                      this.state.employer_tags
                                    }
                                    userTags={this.state.user_tags}
                                    onTagsChanged={this.onTagsChanged}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <button
                                  className="btn btn-primary float-right"
                                  onClick={this.saveData}
                                  disabled={this.state.isLoading}>
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

UserDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default UserDetail;
