import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Badge,
  Card,
  Container,
  Row,
  Col,
  CardText,
  CardBody,
  CardSubtitle,
  UncontrolledCollapse,
  Button,
  PopoverBody,
  UncontrolledPopover,
  ListGroup,
  ListGroupItem,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../utils/formatters';

const EducationQuestionCard = (props) => {
  const {
    platform,
    category,
    template,
    isActive,
    answers,
    questionType,
    questionId,
    dateAdded,
    dateModified,
    modifiedBy,
    addedBy,
  } = props;
  return (
    <div>
      <Container>
        <Card>
          <CardBody>
            <CardSubtitle tag="h6" className="mb-2 text-muted">
              {isActive ? (
                <Badge color="dark">Active</Badge>
              ) : (
                <Badge color="light">Inactive</Badge>
              )}
              &nbsp;
              <Badge color="success">{platform}</Badge> &nbsp;
              <Badge color="info">{category}</Badge> &nbsp;
            </CardSubtitle>
            <CardText>{template}</CardText>

            <Row>
              <Col>
                <Button
                  color="primary"
                  size="sm"
                  id={`toggler${questionId}`}
                  style={{ marginBottom: '1rem' }}
                >
                  Toggle Answers
                </Button>
                <UncontrolledCollapse toggler={`#toggler${questionId}`}>
                  {answers &&
                    answers.map((answer) => (
                      <>
                        <ListGroup>
                          <ListGroupItem>
                            <Container>
                              <Row>{answer.template}</Row>
                              <Row>
                                <small>
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faCalendarAlt}
                                  />
                                  &nbsp;{formatDateTime(answer.date_created)}
                                  &nbsp;
                                  <FontAwesomeIcon size="sm" icon={faUser} />
                                  &nbsp;ID : &nbsp;{answer.added_by}
                                </small>
                              </Row>
                            </Container>
                          </ListGroupItem>
                        </ListGroup>
                        <br />
                      </>
                    ))}
                </UncontrolledCollapse>

                <Link
                  to={{
                    pathname: `/admin/question/education/${questionId}`,
                    state: {
                      questionId,
                      template,
                      platform,
                      questionType,
                      category,
                      answers,
                      isActive,
                    },
                  }}
                  className="btn btn-info btn-sm"
                  style={{ marginBottom: '1rem' }}
                >
                  Edit
                </Link>

                <Button
                  id={`UncontrolledPopover${questionId}`}
                  style={{ marginBottom: '1rem' }}
                  size="sm"
                  type="button"
                >
                  Metadata
                </Button>
                <UncontrolledPopover
                  placement="bottom"
                  target={`UncontrolledPopover${questionId}`}
                >
                  <PopoverBody>
                    <Table className="table table-bordered table-striped  table-sm">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'right' }}>date added</td>
                          <td>{formatDateTime(dateAdded)}</td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: 'right' }}>added by</td>
                          <td>{addedBy}</td>
                        </tr>

                        {dateModified && (
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              date modified
                            </td>
                            <td>{dateModified}</td>
                          </tr>
                        )}

                        {modifiedBy && (
                          <tr>
                            <td style={{ textAlign: 'right' }}>modified by</td>
                            <td>{modifiedBy}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
              <Col />
            </Row>
          </CardBody>
        </Card>
      </Container>
      <br />
    </div>
  );
};

EducationQuestionCard.defaultProps = {
  platform: '',
  category: '',
  template: '',
  isActive: false,
  answers: '',
  questionType: '',
  questionId: '',
  dateAdded: '',
  dateModified: '',
  modifiedBy: '',
  addedBy: null,
};

EducationQuestionCard.propTypes = {
  platform: PropTypes.string,
  category: PropTypes.string,
  template: PropTypes.string,
  isActive: PropTypes.bool,
  answers: PropTypes.string,
  questionType: PropTypes.string,
  questionId: PropTypes.string,
  dateAdded: PropTypes.string,
  dateModified: PropTypes.string,
  modifiedBy: PropTypes.string,
  addedBy: PropTypes.number,
};

export default EducationQuestionCard;
