import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import Pagination from '../../../components/Pagination';
import { Context } from '../../../state/GlobalStore';
import RegistrationModal from './Modals/RegistrationModal';
import { environment } from '../../../enviroment/enviroment';
// core components

const customStyles = {
  container: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  control: (provided) => ({
    ...provided,
    padding: 4,
    borderRadius: 6,
  }),
};

class RegistrationList extends React.Component {
  static contextType = Context;

  constructor() {
    super();
    this.state = {
      registrations: [],
      employers: [],
      loading: false,
      showAddEmployee: null,
      createEmployee: {},
      selectedRegistration: null,
      query: '',
      status: 'new',
      page: 1,
    };
  }

  componentDidMount() {
    this.getRegistrations();
    this.getEmployers();
  }

  getRegistrations(params) {
    this.setState({ loading: true });
    return axios
      .get(`${environment.baseUrl}/registrations/`, {
        params: {
          status: this.state.status,
          ...params,
        },
      })
      .then((res) => {
        this.setState({
          registrations: res.data,
          pages: res.data.pages,
          loading: false,
        });
      });
  }

  onChange = (e) => {
    const { value } = e.target;
    this.getMessages({ search: value });
  };

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({
        employers: res.data.filter((employer) => employer.status !== 'deleted'),
      });
    });
  };

  setRegistrationStatus = (id, status, status_reason = null) => {
    const { fetchStats } = this.context;
    fetchStats();
    return axios.patch(`${environment.baseUrl}/registrations/${id}/`, {
      status,
      status_reason,
    });
  };

  createEmployee = (registration_id) => {
    axios
      .post(`${environment.baseUrl}/employees/`, {
        ...this.state.createEmployee,
      })
      .then((res) => {
        Swal.fire({
          title: 'Employee created successfully',
          html: `<a href='${`/admin/employees/${res.data.id}`}'>Go to employee</a>`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.setRegistrationStatus(registration_id, 'created', '');
        this.getRegistrations();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  setPage = (page) => {
    this.setState({ page }, () => this.getMessages());
  };

  selectRegistration = (data) => {
    this.setState({ selectedRegistration: data });
  };

  onModalClose = (reload = true) => {
    if (reload) {
      this.getRegistrations();
    }
    this.setState({ selectedRegistration: null });
  };

  render() {
    const {
      registrations,
      status,
      loading,
      page,
      pages,
      employers,
      showAddEmployee,
      selectedRegistration,
    } = this.state;
    return (
      <>
        {selectedRegistration && (
          <RegistrationModal
            registration={selectedRegistration}
            onClose={this.onModalClose}
          />
        )}
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Registrations
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col lg="2">
                      <div>
                        <select
                          className="form-control mx-2"
                          onChange={(e) =>
                            this.getRegistrations({
                              status:
                                e.target.value !== 'all'
                                  ? e.target.value
                                  : null,
                            })
                          }
                        >
                          <option value="all" selected={status === 'all'}>
                            All statuses
                          </option>
                          <option value="new" selected={status === 'new'}>
                            New
                          </option>
                          <option
                            value="pending"
                            selected={status === 'pending'}
                          >
                            Pending
                          </option>
                          <option
                            value="created"
                            selected={status === 'created'}
                          >
                            Created
                          </option>
                          <option
                            value="updated"
                            selected={status === 'updated'}
                          >
                            Updated
                          </option>
                          <option
                            value="rejected"
                            selected={status === 'rejected'}
                          >
                            Rejected
                          </option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <button
                        type="button"
                        className="btn btn-sm btn-warning float-right mr-2"
                        onClick={() =>
                          this.props.history.push(
                            '/admin/coldregistrationattempt',
                          )
                        }
                      >
                        Cold Registration Attempts
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                {loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush "
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '0.5pt solid #f7fafc',
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Mobile number</th>
                        <th scope="col">Source</th>
                        <th scope="col">Status</th>
                        <th scope="col">Reason/Employee</th>
                        <th scope="col">Date created</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {registrations.map((registration) => (
                        <>
                          <tr>
                            <td>{registration.name}</td>
                            <td>{registration.company}</td>
                            <td>{registration.mobile_number}</td>
                            <td>{registration.source}</td>
                            <td>
                              {registration.status === 'new' && (
                                <span className="badge badge-success">New</span>
                              )}
                              {registration.status === 'pending' && (
                                <span className="badge badge-warning">
                                  Pending
                                </span>
                              )}
                              {registration.status === 'created' && (
                                <span className="badge badge-info">
                                  Employee created
                                </span>
                              )}
                              {registration.status === 'updated' && (
                                <span className="badge badge-info">
                                  employee updated
                                </span>
                              )}
                              {registration.status === 'rejected' && (
                                <span className="badge badge-warning">
                                  rejected
                                </span>
                              )}
                            </td>
                            <td>
                              {registration.status_reason
                                ? registration.status_reason
                                : registration.employee && (
                                    <Link
                                      to={`/admin/employees/${registration.employee.id}`}
                                    >
                                      {registration.employee.first_name}{' '}
                                      {registration.employee.last_name}
                                    </Link>
                                  )}
                            </td>
                            <td>
                              {moment(registration.date_created).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td align="right">
                              {['new', 'pending'].includes(
                                registration.status,
                              ) && (
                                <Button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    this.selectRegistration(registration)
                                  }
                                >
                                  Select
                                </Button>
                              )}
                            </td>
                          </tr>
                          {showAddEmployee === registration.id && (
                            <td colSpan="7">
                              <Row>
                                <Col>
                                  <h4>New employee</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First name"
                                    value={this.state.createEmployee.first_name}
                                    onChange={(e) =>
                                      this.setState({
                                        createEmployee: {
                                          ...this.state.createEmployee,
                                          first_name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last name"
                                    value={this.state.createEmployee.last_name}
                                    onChange={(e) =>
                                      this.setState({
                                        createEmployee: {
                                          ...this.state.createEmployee,
                                          last_name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Mobile number"
                                    value={
                                      this.state.createEmployee.mobile_number
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        createEmployee: {
                                          ...this.state.createEmployee,
                                          mobile_number: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Select
                                    onChange={(obj) => {
                                      this.setState({
                                        createEmployee: {
                                          ...this.state.createEmployee,
                                          employer: obj.value,
                                        },
                                      });
                                    }}
                                    styles={customStyles}
                                    options={employers.map((employer) => ({
                                      value: employer.id,
                                      label: employer.company_name,
                                    }))}
                                  />
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      this.createEmployee(registration.id)
                                    }
                                  >
                                    Create
                                  </button>
                                  <button
                                    onClick={() =>
                                      this.setState({ showAddEmployee: null })
                                    }
                                    className="btn btn-danger"
                                  >
                                    Cancel
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </Col>
          </Row>
          <Pagination page={page} pages={pages} setPage={this.setPage} />
        </Container>
      </>
    );
  }
}

export default RegistrationList;
