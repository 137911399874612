/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { environment } from '../../../enviroment/enviroment';
import StatusBadge from './StatusBadge';
import Pagination from '../../../components/Pagination';
import { Context } from '../../../state/GlobalStore';
import RiskyAdvancesTable from './RiskyAdvancesTable';

import apiResponseHandler from '../../../utils/apiResponseHandler';
import CustomToolTip from '../../../components/Tooltip/CustomToolTip';
import copyTargetText from '../../../utils/clipboard';
import removeLeadingTrailingChar from '../../../utils/removeLeadingTrailingChar';
import CapitalizeFirstLetter from '../../../utils/CapitalizeFirstLetter';

let cancelToken;

class AdvanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advance_requests: [],
      query: '',
      status: 'pending',
      risky: false,
      loading: false,
      page: 1,
    };
  }

  static contextType = Context;

  componentDidMount() {
    this.getAdvanceRequests();
  }

  getAdvanceRequests(params) {
    this.setState({ loading: true });
    const { status, page, query } = this.state;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    let paramsValue = {
      status: status === 'all' ? null : status,
      page,
      pagination: true,
      search: query || null,
      page_size: 40,
      ...params,
    };

    // dont show risky advances for pending advances
    if (status === 'pending') {
      paramsValue = {
        risky: false,
        ...paramsValue,
      };
    }

    axios
      .get(`${environment.baseUrl}/advancerequests/`, {
        headers,
        params: paramsValue,
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        this.setState({
          advance_requests: res.data.results,
          pages: res.data.pages,
          loading: false,
        });
      });
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState(
      {
        query: value,
      },
      () => this.getAdvanceRequests(),
    );
  };

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    const arrayCopy = [...this.state.advance_requests];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ advance_requests: arrayCopy });
  }

  changeAdvanceStatus = (id, status) => {
    const request = axios
      .patch(`${environment.baseUrl}/advancerequests/${id}/`, {
        status,
      })
      .then((_res) => {
        Swal.fire({
          title: `Advance marked ${status}`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getAdvanceRequests();
        const { fetchStats } = this.context;
        fetchStats();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
    return request;
  };

  markAllPendingPaid = () => {
    const promises = this.state.advance_requests
      .filter((advance) => advance.status === 'pending')
      .map((advance) =>
        axios.patch(`${environment.baseUrl}/advancerequests/${advance.id}/`, {
          status: 'paid',
        }),
      );
    Promise.all(promises)
      .then((_res) => {
        Swal.fire({
          title: 'All pending advances marked paid',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      })
      .finally(() => {
        this.getAdvanceRequests();
        const { fetchStats } = this.context;
        fetchStats();
      });
  };

  setPage = (page) => {
    this.setState({ page }, () => this.getAdvanceRequests());
  };

  deleteAdvance(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this advance?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${environment.baseUrl}/advancerequests/${id}/`)
          .then((_res) => {
            Swal.fire({
              title: 'Advance was deleted',
              icon: 'success',
            });
            this.getAdvanceRequests();
            const { fetchStats } = this.context;
            fetchStats();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  }

  /**
   * Show all risky advances that are still pending
   *
   */
  showRiskyAdvances() {
    this.setState({ showRiskyAdvances: true });
  }

  /**
   * Show all pending, non-risky advances
   *
   */
  clearRiskyFilters() {
    this.setState({ showRiskyAdvances: false });
    this.getAdvanceRequests();
  }

  render() {
    const {
      showRiskyAdvances,
      advance_requests,
      loading,
      status,
      page,
      pages,
    } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Advances
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col lg="3">
                      <input
                        type="text"
                        className="form-control mx-2"
                        placeholder="Search Advances"
                        onChange={this.onChange}
                        disabled={showRiskyAdvances}
                      />
                    </Col>
                    <Col lg="2">
                      <div>
                        <select
                          className="form-control mx-2"
                          onChange={(e) =>
                            this.setState(
                              { status: e.target.value },
                              this.getAdvanceRequests,
                            )
                          }
                          disabled={showRiskyAdvances}
                        >
                          <option value="all" selected={status === 'all'}>
                            All statuses
                          </option>
                          <option
                            value="pending"
                            selected={status === 'pending'}
                          >
                            Pending
                          </option>
                          <option value="paid" selected={status === 'paid'}>
                            Paid
                          </option>
                          <option
                            value="rejected"
                            selected={status === 'rejected'}
                          >
                            Rejected
                          </option>
                        </select>
                      </div>
                    </Col>

                    <Col>
                      <button
                        className="btn btn-sm float-right mr-2"
                        onClick={() =>
                          window.open(
                            `${environment.baseUrl}/advancerequests/peach`,
                          )
                        }
                      >
                        Download peach
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-warning float-right mr-2"
                        onClick={() => this.showRiskyAdvances()}
                      >
                        Advance Risk Queue
                      </button>

                      <button
                        type="button"
                        className="btn btn-success btn-sm float-right mr-2"
                        onClick={() => this.clearRiskyFilters()}
                        disabled={!showRiskyAdvances}
                      >
                        Show Pending Advances
                      </button>
                    </Col>
                  </Row>
                </CardHeader>

                {showRiskyAdvances && (
                  <RiskyAdvancesTable
                    changeAdvanceStatus={this.changeAdvanceStatus}
                  />
                )}

                {!showRiskyAdvances &&
                  (loading ? (
                    <Row className="my-3">
                      <Col className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <CustomToolTip id="tooltip-id">
                        <p>
                          For transactions made using{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            INSTANT PAYMENT
                          </span>
                        </p>
                      </CustomToolTip>
                      <CustomToolTip id="tooltip-status">
                        <p>
                          For transactions in the{' '}
                          <span style={{ fontWeight: 'bold' }}>FAILED</span> or{' '}
                          <span style={{ fontWeight: 'bold' }}>REJECTED</span>{' '}
                          state
                        </p>
                      </CustomToolTip>

                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" onClick={() => this.sortBy('uuid')}>
                              UUID
                            </th>
                            <th
                              id="tooltip-id"
                              scope="col"
                              onClick={() => this.sortBy('source_id')}
                              style={{ width: '100px' }}
                            >
                              Stitch ID{' '}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('first_namelast_name')}
                            >
                              Employee
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('company_name')}
                            >
                              Employer
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('amount_requested')}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('instant_payment')}
                            >
                              Instant Payment
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('date_created')}
                            >
                              Date Requested
                            </th>
                            <th
                              scope="col"
                              onClick={() => this.sortBy('status')}
                            >
                              Status
                            </th>
                            <th scope="col">Risky Reason</th>
                            <th
                              id="tooltip-status"
                              scope="col"
                              onClick={() => this.sortBy('status_reason')}
                            >
                              Status Reason{' '}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                            </th>
                            <th scope="col">
                              <button
                                onClick={this.markAllPendingPaid}
                                className="btn btn-sm btn-success float-right"
                              >
                                Mark all pending paid
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {advance_requests.map((advance_request, index) => (
                            <tr key={index}>
                              <td>{advance_request.uuid}</td>
                              <td
                                onClick={copyTargetText}
                                style={{
                                  textAlign: advance_request.source_id
                                    ? 'left'
                                    : 'center',
                                  overflowX: 'scroll',
                                }}
                              >
                                <div style={{ width: '200px' }}>
                                  {advance_request.source_id || '-'}
                                </div>
                              </td>
                              <td>
                                <Link
                                  to={`/admin/employees/${advance_request.employee.id}`}
                                >
                                  {`${advance_request.employee.first_name} ${advance_request.employee.last_name}`}
                                </Link>
                              </td>
                              <td>
                                {advance_request.employee.employer &&
                                  `${advance_request.employee.employer.company_name}`}
                              </td>
                              <td>R {advance_request.amount_requested}</td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {advance_request.instant_payment ? 'Yes' : 'No'}
                              </td>
                              <td>
                                {moment(advance_request.date_created).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>
                              <td>
                                <StatusBadge status={advance_request.status} />
                              </td>
                              <td
                                style={{
                                  textAlign: advance_request.is_risky
                                    ? 'left'
                                    : 'center',
                                }}
                              >
                                {advance_request.is_risky ? (
                                  <ul>
                                    {removeLeadingTrailingChar(
                                      advance_request.risky_reasons,
                                      ',',
                                    )
                                      .split(',')
                                      .map((reason, index) => (
                                        <li key={index}>
                                          {CapitalizeFirstLetter(reason)}
                                        </li>
                                      ))}
                                  </ul>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td
                                style={{
                                  textAlign: advance_request.status_reason
                                    ? 'left'
                                    : 'center',
                                }}
                              >
                                {advance_request.status_reason || '-'}
                              </td>
                              <td className="d-flex justify-content-end">
                                <Link
                                  to={`/admin/advances/${advance_request.id}`}
                                  className="btn btn-primary"
                                >
                                  View
                                </Link>
                                {advance_request.status === 'pending' && (
                                  <>
                                    <button
                                      onClick={() =>
                                        this.changeAdvanceStatus(
                                          advance_request.id,
                                          'paid',
                                        )
                                      }
                                      className="btn btn-success text-white ml-2"
                                    >
                                      Paid
                                    </button>

                                    <button
                                      onClick={() =>
                                        this.changeAdvanceStatus(
                                          advance_request.id,
                                          'rejected',
                                        )
                                      }
                                      className="btn btn-danger text-white ml-2"
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  ))}
              </Card>
            </Col>
          </Row>
          <Pagination page={page} pages={pages} setPage={this.setPage} />
        </Container>
      </>
    );
  }
}

export default AdvanceList;
