import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap'; // Assuming you're using reactstrap
import { CURRENCY_SYMBOL } from '../../../constants/constants';

// Modify the getValue function to handle various input types
const getValue = (value: { value: number } | number | string | undefined): number => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') return parseFloat(value) || 0;
  if (typeof value === 'object' && value !== null && 'value' in value) return value.value;
  return 0;
};

enum PaymentMethods {
  Standard = 'Standard',
  Instant = 'Instant'
}

// not_enabled usage (in next admin PR): [not_enabled.replace("{payment_method}", PaymentMethods.Standard)]
const not_enabled = "{payment_method} payments not enabled" // payment method has been disabled
const no_limit_enabled = "No limit enabled" // payment method has been switched on, but limit not set in config
const not_available = "Not available"


// Define the interface
export interface IEmployeeLimits {
  volume_and_value: {
    amount_limit: { value: number, default: 0, enabled: boolean };
    request_limit: { value: number, default: 0, enabled: boolean };
  };
  instant: {
    amount_limit: { value: number, default: 0, enabled: boolean };
    request_limit: { value: number, default: 0, enabled: boolean }; 
  };
  standard: {
    amount_limit: { value: number, default: 0, enabled: boolean };
    request_limit: { value: number, default: 0, enabled: boolean };
  };
  employee_limits?: {
    available_advance_balance: number,
    available_advance_to_withdraw: number,
    is_advance_permitted: boolean,
    volume_and_value: {
      amount_used: number;
      requests_used: number;
    },
    instant: {
      amount_used: number;
      requests_used: number;
    },
    standard: {
      amount_used: number;
      requests_used: number;
    }
  }
}

export interface IEmployeeAdvanceData {
  available_advance_balance?: number;
  available_advance_to_withdraw?: number;
  volume_and_value?: {
    amount_used: number;
    request_used: number;
  };
  instant?: {
    amount_used: number;
    request_used: number;
  };
  standard?: {
    amount_used: number;
    request_used: number;
  };
}

// Export the components
const EmployeeLimits: React.FC<{ 
  employeeLimits: IEmployeeLimits; 
  employeeAdvanceData?: IEmployeeAdvanceData 
}> = ({ employeeLimits, employeeAdvanceData = {} }) => {
  const defaultEmployeeAdvanceData: IEmployeeAdvanceData = {
    available_advance_balance: 0,
    available_advance_to_withdraw: 0,
    volume_and_value: {
      amount_used: 0,
      request_used: 0
    },
    instant: {
      amount_used: 0,
      request_used: 0
    },
    standard: {
      amount_used: 0,
      request_used: 0
    }
  };

  const safeEmployeeAdvanceData = { ...defaultEmployeeAdvanceData, ...employeeAdvanceData };

  const totalAdvancesTaken = (safeEmployeeAdvanceData.instant?.amount_used ?? 0) + (safeEmployeeAdvanceData.standard?.amount_used ?? 0);
  const maximumInstantAndStandardRequests = (employeeLimits.instant?.request_limit?.value || 0) + (employeeLimits.standard?.request_limit?.value || 0);

  /* Sections can be added here as needed to expand on the UI
  *  The structure is as follows:
  *  {
  *    title: string; // The title of the section which will display in bold
  *    items: {
  *      value: string | number | null; // The value to display, can be a string, number, or null. Each element will display next to each other on the same line with no additional formatting.
  *      label: string; // The label of the item which will display just below the value in a smaller font.
  *      placeholder: string | string[]; // The placeholder to display if the value is null, can be a string or an array of strings.
  *    }[];
  *  }[];
  */
  const sections = [
    {
      title: 'Balances',
      items: [
        {
          value: employeeLimits?.employee_limits?.available_advance_to_withdraw ? 
            [CURRENCY_SYMBOL, ' ', employeeLimits?.employee_limits?.available_advance_to_withdraw] 
            : [not_available],
          label: 'Available to withdraw (including limits)',
          placeholder: [not_available]
        },
        {
          value: employeeLimits?.employee_limits?.available_advance_balance ?
            [CURRENCY_SYMBOL, ' ', employeeLimits?.employee_limits?.available_advance_balance]
            : [not_available],
          label: 'Available balance (based on days worked)',
          placeholder: [not_available]
        },
      ]
    },
    {
      title: 'Amount limits',
      items: [
        {
          value:  employeeLimits.volume_and_value?.amount_limit?.enabled ? 
            [CURRENCY_SYMBOL, ' ', employeeLimits.volume_and_value?.amount_limit.value] 
            : [no_limit_enabled],
          label: 'Total amount per pay cycle limit',
          placeholder: [no_limit_enabled]
        },
        { 
          value: employeeLimits.standard?.amount_limit?.enabled ? 
            [CURRENCY_SYMBOL, ' ', employeeLimits.standard?.amount_limit.value] 
            :  [no_limit_enabled], 
          label: 'Total amount per standard transaction limit', 
          placeholder: [no_limit_enabled]
        },
        { 
          value: employeeLimits.instant?.amount_limit?.enabled ? 
            [CURRENCY_SYMBOL, ' ', employeeLimits.instant?.amount_limit.value] 
            :  [no_limit_enabled], 
          label: 'Total amount per instant transaction limit', 
          placeholder: [no_limit_enabled]
        },
      ]
    },
    {
      title: 'Number of transaction limits',
      items: [
        {
          value: employeeLimits.volume_and_value?.request_limit?.enabled ? 
            [employeeLimits.employee_limits?.volume_and_value?.requests_used ?? 0, ' / ', getValue(employeeLimits.volume_and_value?.request_limit)]
            : [no_limit_enabled],
          label: 'Taken of total number of transactions per pay cycle limit',
          placeholder: [no_limit_enabled]
        },
        { 
          value: employeeLimits.standard?.request_limit?.enabled ? 
            [employeeLimits.employee_limits?.standard?.requests_used ?? 0, ' / ', getValue(employeeLimits.standard?.request_limit)]
            : [no_limit_enabled], 
          label: 'Taken of total of standard transactions per pay cycle limit', 
          placeholder: [no_limit_enabled]
        },
        { 
          value: employeeLimits.instant?.request_limit?.enabled ? 
            [employeeLimits.employee_limits?.instant?.requests_used ?? 0, ' / ', getValue(employeeLimits.instant?.request_limit)]
            : [no_limit_enabled], 
          label: 'Taken of total of instant transactions per pay cycle limit',
          placeholder: [no_limit_enabled] 
        },
      ]
    },
  ];

  return (
    <>
      {sections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <h2 className="mt-4 mb-3">{section.title}</h2>
          {section.items.map((item, itemIndex) => (
            <Card key={itemIndex} className="mb-3">
              <CardBody>
                <h3>
                  {item.value !== null ? (
                    Array.isArray(item.value)
                      ? item.value.map((displayItem, index) => (
                          <span key={index}>
                            {typeof displayItem === 'object'
                              ? JSON.stringify(displayItem)
                              : displayItem}
                          </span>
                        ))
                      : getValue(item.value)
                  ) : (
                    item.placeholder
                  )}
                </h3>
                <small>{item.label}</small>
              </CardBody>
            </Card>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default EmployeeLimits;