import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import RecurrenceRuleGenerator from './RecurrenceRuleGenerator';
import CalendarDetails from './CalendarDetails';
import ReconInvoiceConfigurations from './ReconInvoiceConfigurations';
import CreateEventActions from './CreateEventActions';
import ReconEventStore from './EmployerReconsContext.js';

function CreateReconEventModal(props) {
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  return (
    <ReconEventStore>
      <div>
        <Modal isOpen={props.isOpen} size="lg" style={{ maxWidth: '90%' }}>
          <ModalHeader>
            <span style={{ fontSize: 30 }}>
              Recon Details for {props.employerName}
            </span>
          </ModalHeader>
          <ModalBody>
            <div>
              <Nav justified tabs>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => setCurrentActiveTab('1')}
                    className={classnames({
                      active: currentActiveTab === '1',
                    })}
                  >
                    Recon Details
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                    }}
                    className={classnames({
                      active: currentActiveTab === '2',
                    })}
                    onClick={() => setCurrentActiveTab('2')}
                  >
                    Calendar Details
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                    }}
                    className={classnames({
                      active: currentActiveTab === '3',
                    })}
                    onClick={() => setCurrentActiveTab('3')}
                  >
                    Event Details
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1" className="p-2">
                  <ReconInvoiceConfigurations
                    payCycles={props.payCycles}
                    branches={props.branches}
                    calendarEvents={props.calendarEvents}
                  />
                </TabPane>

                <TabPane tabId="2" className="p-4">
                  <CalendarDetails />
                </TabPane>

                <TabPane tabId="3">
                  <RecurrenceRuleGenerator />
                </TabPane>
              </TabContent>
            </div>
          </ModalBody>
          <CreateEventActions
            onClose={props.onClose}
            setIsCreateEvent={props.setIsCreateEvent}
          />
        </Modal>
      </div>
    </ReconEventStore>
  );
}

export default CreateReconEventModal;
