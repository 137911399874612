import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const localizer = momentLocalizer(moment);

const ReconInstanceDetails = (props) => {
  const { recon, onSuccess } = props;
  const originalDate = new Date(recon ? recon.start.date : '');
  const [value, setValue] = useState(new Date(recon ? recon.start.date : ''));

  const updateInstanceDate = useCallback(
    async (newDate) => {
      if (recon === null) return;
      axios
        .put(`/api/calendar_events/update_instance`, {
          recurringEventId: recon.recurringEventId,
          id: recon.id,
          newDate,
          oldDate: moment(originalDate).format('YYYY-MM-DD'),
        })
        .then((response) => {
          Swal.fire({
            title: 'Recon date successfully added',
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          onSuccess();
        })
        .catch((error) => {
          Swal.fire({
            title: 'Recon date failed to add',
            icon: 'error',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        });
    },
    [onSuccess, recon, originalDate]
  );

  const onSelectSlot = useCallback(
    (slotInfo) => {
      setValue(new Date(slotInfo.start));
      if (
        moment(slotInfo.start).format('YYYY-MM-DD') ===
        moment(originalDate).format('YYYY-MM-DD')
      ) {
        return;
      }
      updateInstanceDate(moment(slotInfo.start).format('YYYY-MM-DD'));
    },
    [originalDate, updateInstanceDate]
  );

  return (
    <div>
      {recon && (
        <Calendar
          views={['month']}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          min={moment().toDate()}
          style={{
            height: 'calc(100% - 20px)',
            margin: '10px',
            minHeight: '500px',
          }}
          events={[recon].map((event) => ({
            id: event.id,
            title: event.summary,
            allDay: true,
            start: value,
            end: value,
          }))}
          onSelectSlot={onSelectSlot}
          selectable
        />
      )}
    </div>
  );
};

export default ReconInstanceDetails;
