import React from 'react';
import { Table } from 'reactstrap';
import { copyTextToClipboard } from '../../../utils/clipboard';
import { IAdvanceRequestType } from '../../../types/AdvanceRequestTypes';
import { IEmployeeType } from '../../../types/EmployeeTypes';

interface EmployeeModalTableProps {
  employee: IEmployeeType;
  advance: IAdvanceRequestType;
}

const EmployeeModalTable: React.FC<EmployeeModalTableProps> = (props) => 
(
    <div className="modal-table-container">
      <Table borderless>
        <thead>
          <tr>
            <th scope="col">Employee Name</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">UUID</th>
            <th scope="col">Stitch ID</th>
          </tr>
        </thead>
        <tbody>
          {props.employee && props.advance && (
            <tr key={props.employee.id}>
              <td>
                {props.employee.first_name} {props.employee.last_name}
              </td>
              <td>{props.advance.instant_payment ? 'Instant' : 'Standard'}</td>
              <td>R {props.advance.amount_requested}</td>
              <td onClick={() => copyTextToClipboard(props.advance.uuid)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
              <td onClick={() => copyTextToClipboard(props.advance.source_id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
export default EmployeeModalTable;
