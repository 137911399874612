import React from 'react';
import axios from 'axios';
import { Input, Card, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import Select from 'react-select';

import Swal from 'sweetalert2';

import apiResponseHandler from '../../../utils/apiResponseHandler';
import SubscriptionBatchConfigurationOverrideModal from './SubscriptionBatchConfigurationOverrideModal';

import { environment } from '../../../enviroment/enviroment';

const options = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
];

let cancelToken;
const createData = {
  subscription: null,
  enabled: true,
  run_frequency: 'monthly',
  run_interval: '1',
  run_start_date: null,
};

class SubscriptionBatchConfigurationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batches: [],
      employers: [],
      subscriptions: [],
      employer: null,
      loading: false,
      showOverrideModal: false,
      addNewConfiguration: false,
      activeBatch: null,
    };
  }

  componentDidMount() {
    this.getSubscriptionBatchConfigurations();
    this.getEmployers();
    this.getSubscriptions();
  }

  handleShowConfigurationOverridesModal = (configId) => {
    const matchingBatches = this.state.batches.filter(
      (batch) => parseInt(batch.id) === parseInt(configId, 10)
    );
    this.setState({
      showOverrideModal: !this.state.showOverrideModal,
      activeBatch: matchingBatches[0],
    });
  };

  getSubscriptionBatchConfigurations() {
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/subscription_batch_configurations/`, {
        headers,
        params: {
          employer: this.state.employer,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        this.setState({
          batches: res.data,
          loading: false,
        });
      });
  }

  setAddNewConfiguration(enabled) {
    this.setState({ addNewConfiguration: enabled });
  }

  getEmployers() {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({
        employers: res.data.filter((emp) => !emp.deleted),
      });
    });
  }

  getSubscriptions() {
    axios.get(`${environment.baseUrl}/subscriptions/`).then((res) => {
      this.setState({
        subscriptions: res.data,
      });
    });
  }

  toggleConfigurationOverridesModal = () => {
    this.setState({
      showOverrideModal: !this.state.showOverrideModal,
    });
  };

  toggleConfigurationEnabled = (batchConfig) => {
    axios
      .patch(
        `${environment.baseUrl}/subscription_batch_configurations/${batchConfig.id}/`,
        {
          enabled: !batchConfig.enabled,
        }
      )
      .then(() => {
        Swal.fire({
          title: 'Subscription Batch Configuration was updated',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getSubscriptionBatchConfigurations();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  createNewConfiguration() {
    const url = `${environment.baseUrl}/subscription_batch_configurations/`;

    axios
      .post(url, createData)
      .then(() => {
        Swal.fire({
          title: 'Subscription Batch Configuration successfully submitted.',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        this.getSubscriptionBatchConfigurations();
        this.setState({
          addNewConfiguration: false,
        });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  }

  render() {
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Subscription Batch Configurations
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row>
                    <Col className="col-4">
                      <Select
                        options={
                          this.state.employers && [
                            { value: null, label: 'Show all employers' },
                            ...this.state.employers.map((employer) => ({
                              value: employer.id,
                              label: employer.company_name,
                            })),
                          ]
                        }
                        defaultValue={{
                          value: null,
                          label: 'Show all employers',
                        }}
                        onChange={(obj) =>
                          this.setState({ employer: obj.value }, () =>
                            this.getSubscriptionBatchConfigurations()
                          )
                        }
                      />
                    </Col>
                    <Col className="col-3 ml-auto">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.setAddNewConfiguration(true)}
                      >
                        Create Configuration
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                {this.state.loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush table-striped"
                    size="sm"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Subscription name</th>
                        <th scope="col">Employer</th>
                        <th scope="col">Enabled</th>
                        <th scope="col">Run Start Date</th>
                        <th scope="col">Run Interval</th>
                        <th scope="col">Run Frequency</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.addNewConfiguration && (
                        <tr>
                          <td>
                            <Select
                              options={
                                this.state.subscriptions && [
                                  ...this.state.subscriptions.map(
                                    (subscription) => ({
                                      value: subscription.id,
                                      label: subscription.name,
                                    })
                                  ),
                                ]
                              }
                              defaultValue={{
                                value: null,
                                label: 'Select Subscription',
                              }}
                              onChange={(obj) => {
                                createData.subscription = obj.value;
                              }}
                            />
                          </td>
                          <td>{createData.subscription}</td>
                          <td>Yes</td>
                          <td>
                            <Input
                              type="date"
                              name="runStartDate"
                              id="runStartDate"
                              placeholder="Run Start Date"
                              onChange={(e) => {
                                createData.run_start_date = e.target.value;
                              }}
                              required
                            />
                          </td>
                          <td>
                            <Input
                              type="number"
                              name="runInterval"
                              id="runInterval"
                              defaultValue="1"
                              onChange={(e) => {
                                createData.run_interval = e.target.value;
                              }}
                              required
                            />
                          </td>
                          <td>
                            <Select
                              options={options}
                              defaultValue={{
                                value: 'monthly',
                                label: 'Monthly',
                              }}
                              onChange={(obj) => {
                                createData.run_frequency = obj.value;
                              }}
                            />
                          </td>
                          <td align="left">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => this.createNewConfiguration()}
                            >
                              Create
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() =>
                                this.setAddNewConfiguration(false)
                              }
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      )}
                      {this.state.batches.map((batchConfiguration) => (
                        <tr key={batchConfiguration.id}>
                          <td>{batchConfiguration.subscription.name}</td>
                          <td>
                            {
                              batchConfiguration.subscription.employer
                                .company_name
                            }
                          </td>
                          <td>{batchConfiguration.enabled ? 'Yes' : 'No'}</td>
                          <td>{batchConfiguration.run_start_date}</td>
                          <td>{batchConfiguration.run_interval}</td>
                          <td>{batchConfiguration.run_frequency}</td>

                          <th scope="col">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm "
                              onClick={() =>
                                this.toggleConfigurationEnabled(
                                  batchConfiguration
                                )
                              }
                            >
                              {batchConfiguration.enabled
                                ? 'Disable'
                                : 'Enable'}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm "
                              onClick={() =>
                                this.handleShowConfigurationOverridesModal(
                                  batchConfiguration.id
                                )
                              }
                            >
                              Override Recon
                            </button>
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        {this.state.showOverrideModal && (
          <SubscriptionBatchConfigurationOverrideModal
            activeBatchConfig={this.state.activeBatch}
            toggleConfigurationOverridesModal={
              this.toggleConfigurationOverridesModal
            }
            showOverrideModal={this.state.showOverrideModal}
          />
        )}
      </>
    );
  }
}

export default SubscriptionBatchConfigurationList;
