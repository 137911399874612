import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: #535f7f;
    max-width: 250px;
    text-align: left;
  }

  .arrow::before {
    border-bottom-color: #535f7f !important;
  }
`;

const CustomToolTip = ({ id, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <StyledTooltip
      placement="bottom"
      isOpen={tooltipOpen}
      target={id}
      toggle={() => setTooltipOpen(!tooltipOpen)}>
      {children}
    </StyledTooltip>
  );
};

// Add prop types validation
CustomToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomToolTip;
