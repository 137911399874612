import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ReconInstanceDetails from './ReconInstanceDetails';

const localizer = momentLocalizer(moment);

const EmployerReconCalendar = (props) => {
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(false);

  const [calendarView, setCalendarView] = useState({
    startTime: moment().startOf('month').format('YYYY-MM-DD'),
    endTime: moment()
      .startOf('month')
      .add(1, 'month')
      .add(7, 'days')
      .format('YYYY-MM-DD'),
  });

  const queryClient = useCallback(async () => {
    axios
      .get(
        `/api/calendar_events/instances?employerId=${props.employerId}&startTime=${calendarView.startTime}&endTime=${calendarView.endTime}`,
      )
      .then((response) => {
        setInstances(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [calendarView.endTime, calendarView.startTime, props.employerId]);

  const revalidate = useCallback(() => {
    setSelectedInstance(null);
    queryClient();
  }, [setSelectedInstance, queryClient]);

  useEffect(() => {
    revalidate();
  }, [calendarView, revalidate]);

  const updateDateRange = (dateRanges) => {
    switch (dateRanges.length) {
      case 7:
        setCalendarView({
          startTime: moment(dateRanges[0]).format('YYYY-MM-DD'),
          endTime: moment(dateRanges[6]).format('YYYY-MM-DD'),
        });
        break;
      default:
        setCalendarView({
          startTime: moment(dateRanges.start).format('YYYY-MM-DD'),
          endTime: moment(dateRanges.end).format('YYYY-MM-DD'),
        });
        break;
    }
  };

  if (loading) {
    return (
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Modal
        isOpen={selectedInstance ? true : false}
        toggle={() => setSelectedInstance(null)}
        size="lg"
      >
        <ModalHeader>
          {selectedInstance ? 'Recon ' + selectedInstance.start.date : ''}
        </ModalHeader>
        <ModalBody>
          <ReconInstanceDetails
            recon={selectedInstance}
            onSuccess={revalidate}
          />
        </ModalBody>
      </Modal>

      <Calendar
        views={['month', 'week', 'day']}
        min={moment('6:00am', 'h:mma').toDate()}
        max={moment('7:00pm', 'h:mma').toDate()}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ margin: '10px', minHeight: '85vh' }}
        events={
          instances
            ? instances.map((event) => ({
                id: event.id,
                title: event.summary,
                allDay: true,
                start: new Date(event.start.date),
                end: new Date(event.start.date),
              }))
            : []
        }
        // onSelectEvent={(event) => {
        //   setSelectedInstance(
        //     instances.find((instance) => instance.id === event.id) || null,
        //   );
        // }}
        onRangeChange={(dateRanges) => updateDateRange(dateRanges)}
      />
    </>
  );
};

export default EmployerReconCalendar;
