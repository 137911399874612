export function eventIsAMouseClickOrKeyboardSpaceOrEnter(event) {
    // Handles both mouse clicks and keyboard
    // activate with Enter or Space
  
    // Keypresses other than Enter and Space should not trigger a command
    return ! (
      event.type === 'keydown' &&
      event.key !== "Enter" &&
      event.key !== " "
    )
  }
