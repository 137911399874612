import React from 'react';
import axios from 'axios';
import {
  Input,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import Select from 'react-select';

import Swal from 'sweetalert2';

import ReactTooltip from 'react-tooltip';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

import AdvanceBatchConfigurationOverrideModal from './AdvanceBatchConfigurationOverrideModal';

const options = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
];

let cancelToken;
const createData = {
  pay_cycle: null,
  enabled: true,
  run_frequency: 'monthly',
  run_interval: '1',
  run_start_date: null,
};

class AdvanceBatchConfigurationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batches: [],
      employers: [],
      payCycles: [],
      employer: null,
      loading: false,
      showOverrideModal: false,
      addNewConfiguration: false,
      activeBatch: null,
    };
  }

  componentDidMount() {
    this.getAdvanceBatchConfigurations();
    this.getEmployers();
    this.getPayCycles();
  }

  handleShowConfigurationOverridesModal = (configId) => {
    const matchingBatches = this.state.batches.filter(
      (batch) => parseInt(batch.id) === parseInt(configId, 10)
    );
    this.setState({
      showOverrideModal: !this.state.showOverrideModal,
      activeBatch: matchingBatches[0],
    });
  };

  getAdvanceBatchConfigurations() {
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/advance_batch_configurations/`, {
        headers,
        params: {
          employer: this.state.employer,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        this.setState({
          batches: res.data,
          loading: false,
        });
      });
  }

  setAddNewConfiguration(enabled) {
    this.setState({ addNewConfiguration: enabled });
  }

  getEmployers() {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({
        employers: res.data.filter((emp) => !emp.deleted),
      });
    });
  }

  getPayCycles() {
    axios.get(`${environment.baseUrl}/pay_cycles/`).then((res) => {
      this.setState({
        payCycles: res.data,
      });
    });
  }

  getPayCycleDropDownLabel(payCycle) {
    return `${payCycle.name} (${payCycle.employer.company_name})`;
  }

  toggleConfigurationEnabled = (batchConfig) => {
    axios
      .patch(
        `${environment.baseUrl}/advance_batch_configurations/${batchConfig.id}/`,
        {
          enabled: !batchConfig.enabled,
        }
      )
      .then(() => {
        Swal.fire({
          title: 'Advance Batch Configuration was updated',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getAdvanceBatchConfigurations();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError(
          'Advance Batch Configuration was not updated',
          error
        );
      });
  };

  toggleConfigurationOverridesModal = () => {
    this.setState({
      showOverrideModal: !this.state.showOverrideModal,
    });
  };

  createNewConfiguration() {
    const url = `${environment.baseUrl}/advance_batch_configurations/`;

    axios
      .post(url, createData)
      .then(() => {
        Swal.fire({
          title: 'Advance Batch Configuration successfully submitted.',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        this.getAdvanceBatchConfigurations();
        this.setState({
          addNewConfiguration: false,
        });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  }

  render() {
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Advance Batch Configurations
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row>
                    <Col className="col-4">
                      <Select
                        options={
                          this.state.employers && [
                            { value: null, label: 'Show all employers' },
                            ...this.state.employers.map((employer) => ({
                              value: employer.id,
                              label: employer.company_name,
                            })),
                          ]
                        }
                        defaultValue={{
                          value: null,
                          label: 'Show all employers',
                        }}
                        onChange={(obj) =>
                          this.setState({ employer: obj.value }, () =>
                            this.getAdvanceBatchConfigurations()
                          )
                        }
                      />
                    </Col>
                    <Col className="col-3 ml-auto">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.setAddNewConfiguration(true)}>
                        Create Configuration
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                {this.state.loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush table-striped"
                    size="sm"
                    responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Payroll name</th>
                        <th scope="col">Employer</th>
                        <th scope="col">Enabled</th>
                        <th scope="col">Run Start Date</th>
                        <th scope="col">Run Interval</th>
                        <th scope="col">Run Frequency</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.addNewConfiguration && (
                        <tr>
                          <td>
                            <Select
                              options={
                                this.state.payCycles && [
                                  ...this.state.payCycles.map((payCycle) => ({
                                    value: payCycle,
                                    label:
                                      this.getPayCycleDropDownLabel(payCycle),
                                  })),
                                ]
                              }
                              defaultValue={{
                                value: null,
                                label: 'Select Pay Cycle',
                              }}
                              onChange={(obj) => {
                                createData.pay_cycle = obj.value.id;
                              }}
                            />
                          </td>
                          <td>{createData.pay_cycle}</td>
                          <td>Yes</td>
                          <td>
                            <Input
                              type="date"
                              name="runStartDate"
                              id="runStartDate"
                              placeholder="Run Start Date"
                              onChange={(e) => {
                                createData.run_start_date = e.target.value;
                              }}
                              required
                            />
                          </td>
                          <td>
                            <Input
                              type="number"
                              name="runInterval"
                              id="runInterval"
                              defaultValue="1"
                              onChange={(e) => {
                                createData.run_interval = e.target.value;
                              }}
                              required
                            />
                          </td>
                          <td>
                            <Select
                              options={options}
                              defaultValue={{
                                value: 'monthly',
                                label: 'Monthly',
                              }}
                              onChange={(obj) => {
                                createData.run_frequency = obj.value;
                              }}
                            />
                          </td>
                          <td align="left">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => this.createNewConfiguration()}>
                              Create
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() =>
                                this.setAddNewConfiguration(false)
                              }>
                              Cancel
                            </button>
                          </td>
                        </tr>
                      )}
                      {this.state.batches.map((batchConfiguration) => (
                        <tr key={batchConfiguration.id}>
                          <td>{batchConfiguration.pay_cycle.name}</td>
                          <td>
                            {batchConfiguration.pay_cycle.employer.company_name}
                          </td>
                          <td>{batchConfiguration.enabled ? 'Yes' : 'No'}</td>
                          <td>{batchConfiguration.run_start_date}</td>
                          <td>{batchConfiguration.run_interval}</td>
                          <td>
                            {`${batchConfiguration.run_frequency} `}
                            <i
                              data-tip
                              data-for={`${batchConfiguration.id}-riskReasonsTip`}
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            />
                            <ReactTooltip
                              id={`${batchConfiguration.id}-riskReasonsTip`}
                              place="right"
                              effect="solid">
                              Upcoming run dates
                              {batchConfiguration.upcoming_run_dates.map(
                                (runDate) => (
                                  <li>{runDate}</li>
                                )
                              )}
                            </ReactTooltip>
                          </td>

                          <th scope="col">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm "
                              onClick={() =>
                                this.toggleConfigurationEnabled(
                                  batchConfiguration
                                )
                              }>
                              {batchConfiguration.enabled
                                ? 'Disable'
                                : 'Enable'}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm "
                              onClick={() =>
                                this.handleShowConfigurationOverridesModal(
                                  batchConfiguration.id
                                )
                              }>
                              Override Recon
                            </button>
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        {this.state.showOverrideModal && (
          <AdvanceBatchConfigurationOverrideModal
            activeBatchConfig={this.state.activeBatch}
            toggleConfigurationOverridesModal={
              this.toggleConfigurationOverridesModal
            }
            showOverrideModal={this.state.showOverrideModal}
          />
        )}
      </>
    );
  }
}

export default AdvanceBatchConfigurationList;
