import React from 'react';
import axios from 'axios';

import { Alert, Spinner, Row, Col, Container } from 'reactstrap';

import { Link } from 'react-router-dom';
import { environment } from '../../../enviroment/enviroment';
import EducationQuestionCard from '../../../components/EducationQuestionCard';
import Pagination from '../../../components/Pagination';

const STATE_ERROR = 'STATE_ERROR';
const STATE_LOADING = 'STATE_LOADING';
const STATE_OK = 'STATE_OK';
const STATE_NEW = 'STATE_NEW';

/**
 * @summary Render when data retrieval returns an error
 *
 * @param {string} errorMessage The error message
 * @returns {errorComponent}
 */
const renderError = (errorMessage) => {
  let message = 'An error occured';
  if (errorMessage) {
    message = errorMessage;
  }
  const errorComponent = (
    <Alert color="danger">
      <p>{message}</p>
    </Alert>
  );
  return errorComponent;
};

/**
 * @summary Render while data retrieval is happening
 *
 * @returns {loadingComponent}
 */
const renderLoading = (loadingMessage) => {
  const loadingComponent = (
    <Spinner size="sm" color="primary">
      {loadingMessage}
    </Spinner>
  );
  return loadingComponent;
};

/**
 * @summary Render while data retrieval returns an empty response
 *
 * @returns {emptyComponent}
 */
const renderEmpty = () => {
  const loadingComponent = <></>;
  return loadingComponent;
};

/**
 * @summary EducationQuestions component
 *
 * @param {Props} props
 * @component
 */
class EducationQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerState: STATE_OK,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  componentDidMount() {
    const { pageNumber } = this.state;
    this.getEducationQuestions(pageNumber);
  };

  getEducationQuestions = (pageNumber) => {
    this.setState({ containerState: STATE_LOADING });
    this.setState({ currentPage: pageNumber });

    const { pageSize } = this.state;

    axios
      .get(
        `${environment.baseUrl}/questions?page_size=${pageSize}&page_number=${pageNumber}&platform=all`,
      )
      .then((res) => {
        this.setState({
          educationQuestions: res.data.questions,
          containerState: STATE_OK,
          currentPage: res.data.current_page,
          pageSize: res.data.page_size,
          totalNumberOfPages: res.data.total_number_of_pages,
        });
      });
  };

  /**
   * @summary Render when data retrieval is successful
   *
   * @param {object} props The components' props
   * @returns {successComponent}
   */
  renderSuccess = () => {
    const { currentPage, totalNumberOfPages, educationQuestions } = this.state;

    const successComponent = (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Education Questions
                  </h6>
                </Col>

                <Col>
                  <Link
                    to="/admin/question/add"
                    className="btn btn-secondary float-right"
                  >
                    Add New
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {!!educationQuestions &&
          educationQuestions.map((educationQuestion) => (
              <EducationQuestionCard
                key={educationQuestion.id}
                questionId={educationQuestion.id}
                template={educationQuestion.template}
                dateAdded={educationQuestion.date_created}
                addedBy={educationQuestion.added_by}
                dateModified={educationQuestion.modified_by}
                modifiedBy={educationQuestion.date_modified}
                answers={educationQuestion.answers}
                platform={educationQuestion.platform}
                isActive={educationQuestion.is_active}
                category={educationQuestion.category}
                questionType={educationQuestion.question_type}
              />
            ))}
        <br />

        <center>
          <Pagination
            page={currentPage}
            pages={totalNumberOfPages}
            setPage={this.getEducationQuestions}
          />
        </center>
      </>
    );
    return successComponent;
  };

  render() {
    let renderFunction;
    const { containerState, errorMessage } = this.state;
    switch (containerState) {
      case STATE_OK: {
        renderFunction = () => this.renderSuccess(this.props);
        break;
      }
      case STATE_ERROR: {
        renderFunction = () => renderError(errorMessage);
        break;
      }
      case STATE_LOADING: {
        renderFunction = () => renderLoading();
        break;
      }
      case STATE_NEW: {
        renderFunction = () => renderEmpty();
        break;
      }
      default:
        renderFunction = () => renderEmpty();
        break;
    }

    return renderFunction();
  }
}

EducationQuestions.defaultProps = {};

EducationQuestions.propTypes = {};

export default EducationQuestions;
