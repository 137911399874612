import React from 'react';
import Select from 'react-select';

class InboxQueryBranchesSelected extends React.Component {
  render() {
    return (
      <Select
        isMulti
        name="query_branches"
        isSearchable
        onChange={(query_branches) => {
          let selected = [];
          if (query_branches != null) {
            selected = this.props.queryBranches.filter((e) =>
              query_branches.map((f) => f.value).includes(e.id)
            );
          }
          this.props.updatedBranchReceiveQueries(selected);
        }}
        value={this.props.selectedBranches.map((branch) => ({
          label: branch.name,
          value: branch.id,
        }))}
        options={this.props.queryBranches.map((queryBranch) => ({
          label: queryBranch.name,
          value: queryBranch.id,
        }))}
      />
    );
  }
}

export default InboxQueryBranchesSelected;
