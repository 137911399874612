import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Input, Table, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

const validateInputs = (data, setValidation, validation) => {
  const errors = [];

  if (data?.name?.trim() === '' || data?.name === undefined) {
    errors.push('Name is a required field.');
  }

  if (!data?.employee_amount || data?.employee_amount === undefined) {
    errors.push('Employee Amount is a required field.');
  }
  if (!data?.employer_amount || data?.employer_amount === undefined) {
    errors.push('Employer Amount is a required field.');
  }

  setValidation({
    ...validation,
    errors,
    formError: errors.length > 0,
    triedSubmitting: true,
  });

  return errors.length < 1;
};

const Subscription = ({
  subscription,
  getSubscriptions,
  employerId,
  setValidation,
  validation,
}) => {
  const [saveData, setSaveData] = useState({});
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setSaveData(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    if (validateInputs(saveData, setValidation, validation)) {
      axios
        .patch(`${environment.baseUrl}/subscriptions/${subscription.id}/`, {
          ...saveData,
        })
        .then(() => {
          Swal.fire({
            title: 'Successfully saved subscription',
            icon: 'success',
          });
          getSubscriptions();
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
      setEdit(false);
    }
  };

  const makeDefault = () => {
    axios
      .post(
        `${environment.baseUrl}/subscriptions/${subscription.id}/setDefault/`,
      )
      .then(() => {
        Swal.fire({
          title: 'Successfully saved subscription',
          icon: 'success',
        });
        getSubscriptions();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
    setEdit(false);
  };

  const deleteSubscription = (id) => {
    axios
      .delete(`${environment.baseUrl}/subscriptions/${id}/`)
      .then(() => {
        Swal.fire({
          title: 'Successfully deleted subscription',
          icon: 'success',
        });
        getSubscriptions();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  const onChange = (e) => {
    const newData = { ...saveData };
    newData[e.target.name] = e.target.value;
    setSaveData(newData);
  };

  return (
    <tr key={subscription.id}>
      <td>{subscription.id}</td>
      <td>
        {edit ? (
          <Input
            size="sm"
            style={{ width: '150px' }}
            type="text"
            value={saveData.name}
            name="name"
            onChange={(e) => onChange(e)}
            invalid={validation.triedSubmitting && !saveData?.name}
          />
        ) : (
          saveData.name
        )}
      </td>
      <td>
        {subscription.default ? (
          <span className="badge badge-success">Yes</span>
        ) : (
          <span className="badge badge-danger">No</span>
        )}
      </td>
      <td>
        {edit ? (
          <Input
            size="sm"
            type="number"
            step=".01"
            value={saveData.employee_amount}
            name="employee_amount"
            onChange={(e) => onChange(e)}
            invalid={validation.triedSubmitting && !saveData?.employee_amount}
          />
        ) : (
          subscription.employee_amount
        )}
      </td>
      <td>
        {edit ? (
          <Input
            size="sm"
            type="number"
            step=".01"
            value={saveData.employer_amount}
            name="employer_amount"
            onChange={(e) => onChange(e)}
            invalid={validation.triedSubmitting && !saveData?.employer_amount}
          />
        ) : (
          subscription.employer_amount
        )}
      </td>
      <td align="right">
        {edit ? (
          <button className="btn btn-sm btn-primary" onClick={() => save()}>
            Save
          </button>
        ) : (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )}

        {!subscription.default && (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => makeDefault()}
          >
            Make default
          </button>
        )}
        <button
          className="btn btn-sm btn-danger"
          onClick={() => deleteSubscription(subscription.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const SubscriptionsTable = ({ employerId }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [addNewSubscription, setAddNewSubscription] = useState(false);
  const [createData, setCreateData] = useState();

  const [validation, setValidation] = useState({
    triedSubmitting: false,
    formError: false,
    errors: [],
  });

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptions = () => {
    axios
      .get(`${environment.baseUrl}/subscriptions/`, {
        params: { employer_id: employerId },
      })
      .then((res) => {
        setSubscriptions(res.data);
      });
  };

  const createNewSubscription = () => {
    if (validateInputs(createData, setValidation, validation)) {
      axios
        .post(`${environment.baseUrl}/subscriptions/`, {
          ...createData,
          employer_id: employerId,
        })
        .then(() => {
          Swal.fire({
            title: 'Successfully created subscription',
            icon: 'success',
          });
          getSubscriptions();
          setAddNewSubscription(false);
          setCreateData({});
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  return (
    <Card className="shadow">
      <CardHeader>
        <strong>Subscriptions</strong>
        <Button
          color="primary"
          onClick={() => {
            setAddNewSubscription(true);
            setCreateData({ employer: employerId });
          }}
          className="btn btn-primary btn-sm float-right"
        >
          Add subscription
        </Button>
      </CardHeader>
      {validation.formError && validation.errors && (
        <Alert color="danger">
          {validation.errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </Alert>
      )}

      <Table responsive striped size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th className={addNewSubscription ? 'required-input' : ''}>Name</th>
            <th>Default</th>
            <th className={addNewSubscription ? 'required-input' : ''}>
              Employee amount
            </th>
            <th className={addNewSubscription ? 'required-input' : ''}>
              Employer amount
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <Subscription
              subscription={subscription}
              getSubscriptions={getSubscriptions}
              setValidation={setValidation}
              validation={validation}
            />
          ))}
          {addNewSubscription && (
            <tr>
              <td />
              <td>
                <Input
                  size="sm"
                  style={{ width: '150px' }}
                  type="text"
                  onChange={(e) =>
                    setCreateData({ ...createData, name: e.target.value })
                  }
                  invalid={
                    validation.triedSubmitting && !createData?.name?.trim()
                  }
                />
              </td>
              <td />
              <td>
                <Input
                  size="sm"
                  type="number"
                  step=".01"
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      employee_amount: e.target.value,
                    })
                  }
                  invalid={
                    validation.triedSubmitting && !createData?.employee_amount
                  }
                />
              </td>
              <td>
                <Input
                  size="sm"
                  type="number"
                  step=".01"
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      employer_amount: e.target.value,
                    })
                  }
                  invalid={
                    validation.triedSubmitting && !createData?.employer_amount
                  }
                />
              </td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => createNewSubscription()}
                >
                  Create
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default SubscriptionsTable;
