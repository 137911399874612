import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Table } from 'reactstrap';
import moment from 'moment';
import CustomToolTip from '../../../../components/Tooltip/CustomToolTip';
import copyTargetText, {
  copyTextToClipboard,
} from '../../../../utils/clipboard';
import removeLeadingTrailingChar from '../../../../utils/removeLeadingTrailingChar';
import CapitalizeFirstLetter from '../../../../utils/CapitalizeFirstLetter';
import AdvanceStatusBadge from '../../Advances/AdvanceStatusBadge';
import { environment } from '../../../../enviroment/enviroment';
import AdvanceHistoryModal from '../../Advances/AdvanceHistoryModal';

const AdvanceRequests = ({ employeeId }) => {
  const [advanceRequests, setAdvanceRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAdvanceRequest, setSelectedAdvanceRequest] = useState();
  const [showAdvanceHistoryModal, setShowAdvanceHistoryModal] = useState(false);

  useEffect(() => {
    getAdvanceRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdvanceRequests = () => {
    setLoading(true);
    axios
      .get(`${environment.baseUrl}/advancerequests/`, {
        params: { employee: employeeId },
      })
      .then((res) => {
        setAdvanceRequests(res.data);
      });
    setLoading(false);
  };

  return loading ? (
    <Col className="d-flex justify-content-center mt-3">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Col>
  ) : (
    <>
      <CustomToolTip id="tooltip-id">
        <p>
          For transactions made using{' '}
          <span style={{ fontWeight: 'bold' }}>INSTANT PAYMENT</span>
        </p>
      </CustomToolTip>
      <CustomToolTip id="tooltip-status">
        <p>
          For transactions in the{' '}
          <span style={{ fontWeight: 'bold' }}>FAILED</span> or{' '}
          <span style={{ fontWeight: 'bold' }}>REJECTED</span> state
        </p>
      </CustomToolTip>

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date Added</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">UUID</th>
            <th scope="col" id="tooltip-id">
              Stitch ID <i class="fa fa-question-circle" aria-hidden="true" />
            </th>
            <th scope="col">Status</th>
            <th scope="col" id="tooltip-status">
              Status Reason{' '}
              <i class="fa fa-question-circle" aria-hidden="true" />
            </th>
            <th scope="col">Risky Reason</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {advanceRequests.map((advance) => (
            <tr key={advance.uuid}>
              <td>
                {moment(advance.date_created).format('MMMM Do YYYY, h:mm:ss a')}
              </td>
              <td>{advance.instant_payment ? 'Instant' : 'Standard'}</td>
              <td>R {advance.amount_requested}</td>

              <td onClick={() => copyTextToClipboard(advance.uuid)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>

              <td
                onClick={copyTargetText}
                style={{
                  textAlign: advance.source_id ? 'left' : 'center',
                  overflowX: 'scroll',
                }}>
                <div style={{ width: '200px' }}>{advance.source_id || '-'}</div>
              </td>
              <td>
                <AdvanceStatusBadge status={advance.status} />
              </td>
              <td
                style={{
                  textAlign: advance.status_reason ? 'left' : 'center',
                }}>
                {advance.status_reason || '-'}
              </td>
              <td
                style={{
                  textAlign: advance.is_risky ? 'left' : 'center',
                }}>
                {advance.is_risky ? (
                  <ul>
                    {removeLeadingTrailingChar(advance.risky_reasons, ',')
                      .split(',')
                      .map((reason, index) => (
                        <li key={index}>{CapitalizeFirstLetter(reason)}</li>
                      ))}
                  </ul>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <button
                  className="btn bg-transparent btn-outline-primary"
                  style={{display: 'flex', width: '6.25rem', padding: '0.375rem 0.75rem', alignItems: 'center', height:'1.3125rem', borderRadius: '0.4375rem', border: "1px solid", flexShrink: 0, color: "#062133CC"}}
                  disabled={!advance.source_id}
                  onClick={() => {
                    setSelectedAdvanceRequest(advance);
                    setShowAdvanceHistoryModal(true);
                  }}>
                  View More
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showAdvanceHistoryModal && (
        <AdvanceHistoryModal
          advanceRequest={selectedAdvanceRequest}
          showAdvanceHistoryModal={showAdvanceHistoryModal}
          setShowAdvanceHistoryModal={setShowAdvanceHistoryModal}
        />
      )}
    </>
  );
};

export default AdvanceRequests;
