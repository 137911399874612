import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Label,
  ModalHeader,
  ModalFooter,
  Alert,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary ManageMultiEmployerModal component
 *
 * @param {Props} props
 * @component
 */
const ManageMultiEmployerModal = (props) => {
  const { employers, employerSelectedId } = props;

  const [state, setState] = useState({
    employers: [],
    multiEmployers: [],
    loading: true,
    modalIsOpen: false,
    isMultUser: false,
  });

  const toggleModal = () => {
    setState({
      ...state,
      modalIsOpen: !state.modalIsOpen,
    });
  };

  const updateMultiEmployers = (selectedOptions) => {
    const multiEmployers = employers
      .filter((employer) => employer.id === employerSelectedId)
      .map((employer) => ({
        value: employer.id,
        label: employer.company_name,
      }));
    if (selectedOptions) {
      selectedOptions
        .filter((option) => option.value !== employerSelectedId)
        .forEach((option) => {
          multiEmployers.push(option);
        });
    }
    setState({
      ...state,
      multiEmployers,
    });
  };

  const saveMultiEmployers = () => {
    setState({
      ...state,
      loading: true,
    });

    axios({
      method: state.isMultUser ? 'PUT' : 'POST',
      url: `${environment.baseUrl}/users/multi_employer_user/${
        state.isMultUser ? 'update/' : ''
      }`,
      data: {
        user: props.match.params.userId,
        employers: state.multiEmployers.map((employer) => employer.value),
      },
    })
      .then((res) => {
        Swal.fire({
          title: 'Multi Employer Access updated successfully!',
          html: ``,
          icon: 'success',
        });
        setState({
          ...state,
          loading: false,
        });
        toggleModal();
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          loading: false,
        });
      });
  };

  useEffect(() => {
    const getMultiEmployers = () => {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      axios
        .get(
          `${environment.baseUrl}/users/${props.match.params.userId}/multi_employer_user/employers/`,
          { headers },
        )
        .then((res) => res.data)
        .then((data) => {
          if ('employers' in data) {
            setState((prevState) => ({
              ...prevState,
              multiEmployers: data.employers.map((employer) => ({
                value: employer.id,
                label: employer.company_name,
              })),
              isMultUser: true,
              employers: employers.map((employer) => ({
                value: employer.id,
                label: employer.company_name,
              })),
              loading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              employers: employers.map((employer) => ({
                value: employer.id,
                label: employer.company_name,
              })),
              multiEmployers: employers
                .filter((employer) => employer.id === employerSelectedId)
                .map((employer) => ({
                  value: employer.id,
                  label: employer.company_name,
                })),
              loading: false,
            }));
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            employers: employers.map((employer) => ({
              value: employer.id,
              label: employer.company_name,
            })),
            loading: false,
          }));
        });
    };

    getMultiEmployers();
  }, [employers, employerSelectedId, props.match.params.userId]);

  return (
    <>
      <button onClick={toggleModal} className="btn btn-primary btn-sm">
        Manage Branches
      </button>
      <Modal isOpen={state.modalIsOpen}>
        <ModalHeader>Update Branches</ModalHeader>
        <ModalBody>
          {state.multiEmployers ? (
            <FormGroup>
              <Label for="branchName" sm={6} className="required-input">
                Employers / Branches
              </Label>
              <Col sm={12}>
                {state.employers.length > 0 && (
                  <Select
                    options={state.employers}
                    isMulti
                    onChange={(selectedOptions) => {
                      updateMultiEmployers(selectedOptions);
                    }}
                    isLoading={state.loading}
                    value={state.multiEmployers}
                  />
                )}
              </Col>
            </FormGroup>
          ) : (
            <Alert color="danger">
              <h4 className="alert-heading">No Branches</h4>
              <p>
                The selected employer has no default branch. Please add a
                default branch to the employer before adding branches.
              </p>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveMultiEmployers}>
            {state.loading ? <i className="fa fa-spinner fa-spin" /> : 'Save'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ManageMultiEmployerModal;
