/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary SubscriptionBatchItemsModal component
 *
 * @param {Props} props
 * @component
 */
const SubscriptionBatchItemsModal = (props) => {
  const [state, setState] = useState({
    batchAdvances: null,
  });
  const { toggleBatchItemsModal, handleShowBatchItemsModal, activeBatch } =
    props;

  const getSubscriptions = () => {
    axios
      .get(`${environment.baseUrl}/subscription_batch/${activeBatch.id}/report`)
      .then((res) => {
        setState({
          ...state,
          batchSubscriptions: res.data,
          toggleAddNewBatchModal: false,
          toggleBatchItemsModal: false,
        });
      });
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <Modal isOpen={toggleBatchItemsModal} size="lg">
      <ModalHeader>Subscription Batch Items</ModalHeader>
      <ModalBody>
        {Array.isArray(state.batchSubscriptions) &&
        state.batchSubscriptions.length ? (
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Employee Amount</th>
                <th>Employer Amount</th>
              </tr>
            </thead>
            <tbody>
              {state.batchSubscriptions.map((subscription) => (
                <tr key={subscription.id}>
                  <td>{subscription.id}</td>
                  <td>{subscription.employee_amount}</td>
                  <td>{subscription.employer_amount}</td>
                </tr>
              ))}{' '}
            </tbody>
          </Table>
        ) : (
          <p>This batch has no linked subscriptions</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleShowBatchItemsModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SubscriptionBatchItemsModal.defaultProps = {
  activeBatch: null,
};

SubscriptionBatchItemsModal.propTypes = {
  toggleBatchItemsModal: PropTypes.func.isRequired,
  handleShowBatchItemsModal: PropTypes.func.isRequired,
  activeBatch: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default SubscriptionBatchItemsModal;
