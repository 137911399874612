/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { environment } from '../../../../enviroment/enviroment';

const SupportTickets = ({ employeeId }) => {
  const [supportTickets, setSupportTickets] = useState([]);

  useEffect(() => {
    getSupportTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupportTickets = () => {
    axios
      .get(`${environment.baseUrl}/supporttickets/`, {
        params: { employee: employeeId },
      })
      .then((res) => {
        setSupportTickets(res.data);
      });
  };

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Message</th>
          <th scope="col">Status</th>
          <th scope="col">Date created</th>
        </tr>
      </thead>
      <tbody>
        {supportTickets.map((ticket) => (
          <tr>
            <td>{ticket.text_content.slice(0, 30)}...</td>
            <td>{ticket.status}</td>
            <td>
              {moment(ticket.date_created).format('MMMM Do YYYY, h:mm:ss a')}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SupportTickets;
