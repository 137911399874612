import React from 'react';
import flagsmith from 'flagsmith';
import PropTypes from 'prop-types';
import { FlagsmithProvider, useFlags } from 'flagsmith/react';

const FeatureFlagProvider = ({ children }) => (
  <FlagsmithProvider
    options={{
      environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY || '',
    }}
    flagsmith={flagsmith}>
    {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    }
  </FlagsmithProvider>
);

FeatureFlagProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeatureFlagProvider;

export const useEnabledFeatureFlag = (featureName) => {
  const auth = JSON.parse(localStorage.getItem('USER'));

  if (auth?.email) {
    flagsmith.identify(auth.email);
  }
  const flags = useFlags([featureName]);
  if (!flags) {
    return false;
  }
  if (!flags[featureName]) {
    return false;
  }
  return flags[featureName].enabled;
};
