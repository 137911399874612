/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary Render Add Branch Modal component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const AddNewBranchModal = (props) => {
  //   const [newBranchPayload, setNewBranchPayload] = useState({});
  const [state, setState] = useState({
    errors: [],
    formError: false,
    newBranchPayload: {
      branchName: '',
      branchCode: '',
    },
    triedSubmitting: false,
  });
  const {
    toggleAddBranchModal,
    handleShowAddBranchModal,
    getEmployerData,
    employerId,
  } = props;

  const validatePayload = (payload) => {
    const errors = [];

    if (payload.name?.trim() === '' || payload.name === undefined) {
      errors.push('Branch Name cannot be blank.');
    }

    if (payload.code?.trim() === '' || payload?.code === undefined) {
      errors.push('Branch Code cannot be blank.');
    }

    setState({
      ...state,
      errors,
      formError: errors.length > 0,
      triedSubmitting: true,
    });

    return errors.length < 1;
  };

  const handleSubmitAddNewModal = (e) => {
    const { newBranchPayload } = state;

    const payload = {
      name: newBranchPayload?.branchName,
      code: newBranchPayload?.branchCode,
      employer: employerId,
    };

    const url = `${environment.baseUrl}/branches/`;

    if (validatePayload(payload)) {
      axios
        .post(url, payload)
        .then((res) => {
          Swal.fire({
            title: 'New Branch added sucessfully',
            text: res.alert_message ? res.alert_message : '',
            icon: 'success',
            position: 'top',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          handleShowAddBranchModal();
          getEmployerData();
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  const handleInputChange = (event) => {
    setState({
      ...state,
      newBranchPayload: {
        ...state.newBranchPayload,
        [event.target.name]: event.target.value,
      },
    });
  };

  const { errors, formError, triedSubmitting, newBranchPayload } = state;
  return (
    <Modal isOpen={toggleAddBranchModal}>
      <ModalHeader>New Branch</ModalHeader>
      <ModalBody>
        {formError && errors && (
          <Alert color="danger">
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </Alert>
        )}

        <Form>
          <FormGroup>
            <Label for="branchName" sm={6} className="required-input">
              Name
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                name="branchName"
                id="branchName"
                placeholder="Branch Name"
                onChange={(event) => handleInputChange(event)}
                invalid={triedSubmitting && !newBranchPayload.branchName.trim()}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="branchCode" sm={6} className="required-input">
              Branch Code
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                name="branchCode"
                id="branchCode"
                placeholder="Branch Code"
                onChange={(event) => handleInputChange(event)}
                invalid={triedSubmitting && !newBranchPayload.branchCode.trim()}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmitAddNewModal}>
          Save
        </Button>{' '}
        <Button color="secondary" onClick={handleShowAddBranchModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddNewBranchModal.defaultProps = {};

AddNewBranchModal.propTypes = {
  toggleAddBranchModal: PropTypes.func.isRequired,
  handleShowAddBranchModal: PropTypes.func.isRequired,
  employerId: PropTypes.string.isRequired,
  getEmployerData: PropTypes.func.isRequired,
};

export default AddNewBranchModal;
