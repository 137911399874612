import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class UserFeatureSelected extends React.Component {
  static propTypes = {
    employerFeatures: PropTypes.array.isRequired,
    userFeatures: PropTypes.array.isRequired,
    updatedUserFeatures: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Select
        isMulti
        name="features"
        isSearchable
        onChange={(features) => {
          let selected = [];
          if (features != null) {
            selected = [...this.props.employerFeatures].filter((e) =>
              features.map((f) => f.value).includes(e.feature.id)
            );
          }
          this.props.updatedUserFeatures(selected);
        }}
        value={this.props.userFeatures.map((userFeature) => ({
          label: userFeature.feature.name,
          value: userFeature.feature.id,
        }))}
        options={this.props.employerFeatures.map((employerFeature) => ({
          label: employerFeature.feature.name,
          value: employerFeature.feature.id,
        }))}
      />
    );
  }
}

export default UserFeatureSelected;
