import React from 'react';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
import { Card, CardHeader, Row, Col, CardFooter } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

class EmployeeImportModal extends React.Component {
  constructor() {
    super();
    this.state = {
      csvData: null,
      loading: false,
      employer: null,
      file: null,
      allowImport: false,
      employers: [],
      importMode: null,
      lookupHeader: null,
    };
  }

  componentDidMount() {
    this.getEmployers();
  }

  handleFiles = (files) => {
    this.setState({ loading: true });
    const reader = new FileReader();
    reader.onload = (e) => {
      // Use reader.result
      this.setState(
        {
          loading: false,
          csvData: reader.result,
          file: files[0],
        },
        () => this.checkAllowImport(),
      );
    };
    reader.readAsText(files[0]);
  };

  getEmployers = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/`, { headers }).then((res) => {
      this.setState({
        employers: res.data.filter((employer) => employer.status !== 'deleted'),
        employer: res.data[0].id,
      });
    });
  };

  handleUpload = () => {
    Swal.fire({
      title: 'Uploading file',
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    };
    const { employer, lookupHeader, file, importMode } = this.state;
    const bodyFormData = new FormData();

    bodyFormData.append('employer', employer);

    bodyFormData.append('overwrite_configuration', false);
    bodyFormData.append('use_existing_configuration', true);
    bodyFormData.append(
      'employer_data_import_configuration_name',
      '_smartwage_default_configuration_',
    );

    bodyFormData.append('lookup_header', lookupHeader);
    bodyFormData.append(
      'delete_missing',
      importMode === 'create_update_delete',
    );
    bodyFormData.append('file', file);
    axios
      .post(`${environment.baseUrl}/employeeimportfiles/`, bodyFormData, {
        headers,
      })
      .then((res) => {
        Swal.fire({
          title: 'File uploaded',
          showConfirmButton: false,
          icon: 'success',
          timer: 3000,
        });
        this.props.onClose();
        this.props.refreshList();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  onSelectChange = (key, value) => {
    const changes = {};
    changes[key] = value;
    this.setState(changes, () => this.checkAllowImport());
  };

  checkAllowImport = () => {
    const { lookupHeader, employer, importMode, file } = this.state;
    if (!!lookupHeader && !!employer && !!importMode && !!file) {
      this.setState({
        allowImport: true,
      });
    } else {
      this.setState({
        allowImport: false,
      });
    }
  };

  render() {
    const { loading, employers, allowImport } = this.state;
    const { onClose } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
          zIndex: 10,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(100,100,100,0.4)',
        }}
      >
        <Card
          style={{
            maxWidth: '100vw',
            width: '1000px',
            maxHeight: '100vh',
            margin: 'auto',
            backgroundColor: 'white',
          }}
        >
          <CardHeader>
            <Row>
              <Col style={{ display: 'flex' }}>
                <div style={{ width: '200px', marginRight: '8px' }}>
                  <Select
                    options={employers.map((employer) => ({
                      value: employer.id,
                      label: employer.company_name,
                    }))}
                    onChange={(obj) =>
                      this.onSelectChange('employer', obj.value)
                    }
                    placeholder="Select an employer"
                  />
                </div>
                <div style={{ width: '200px', marginRight: '8px' }}>
                  <Select
                    options={[
                      { value: 'employee_no', label: 'Employee Number' },
                      { value: 'id_number', label: 'ID Number' },
                      { value: 'smartwage_id', label: 'SmartWage ID' },
                      { value: 'id', label: 'Database Number' },
                      {
                        value: 'id_number_and_employee_number',
                        label: 'ID Number/Employee No',
                      },
                    ]}
                    onChange={(obj) =>
                      this.onSelectChange('lookupHeader', obj.value)
                    }
                    placeholder="Select an option"
                  />
                </div>
                <div style={{ width: '200px' }}>
                  <Select
                    options={[
                      {
                        value: 'create_update',
                        label: 'Create/Update',
                      },
                      {
                        value: 'create_update_delete',
                        label: 'Create/Update/Delete',
                      },
                    ]}
                    onChange={(obj) =>
                      this.onSelectChange('importMode', obj.value)
                    }
                    placeholder="Select an option"
                  />
                </div>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className="btn btn-primary"
                  onClick={this.handleUpload}
                  disabled={!allowImport}
                >
                  Upload file
                </button>
                <button className="btn btn-secondary" onClick={onClose}>
                  Close
                </button>
              </Col>
            </Row>
          </CardHeader>
          {this.state.csvData ? (
            loading ? (
              <Row className="my-3">
                <Col className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Col>
              </Row>
            ) : (
              <div style={{ overflow: 'scroll' }}>
                <CsvToHtmlTable
                  data={this.state.csvData || ''}
                  csvDelimiter=","
                  tableClassName="table table"
                  responsive
                />
              </div>
            )
          ) : (
            <Row className="my-3">
              <Col className="d-flex justify-content-center">
                <h5>No file loaded</h5>
              </Col>
            </Row>
          )}
          <CardFooter
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ReactFileReader
                multipleFiles={false}
                fileTypes={['.csv']}
                handleFiles={this.handleFiles}
              >
                <button className="btn btn-primary">Choose file</button>
              </ReactFileReader>
              <button
                className="btn ml-2"
                onClick={() =>
                  window.open(`${environment.baseUrl}/import-template/`)
                }
              >
                Download template
              </button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default EmployeeImportModal;
