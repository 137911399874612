import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar.js';

import routes from '../../routes/routes.js';

class Admin extends React.Component {
  constructor() {
    super();
    this.mainContent = React.createRef();
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (this.mainContent.current) {
      this.mainContent.current.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            exact={true}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path,
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.oneOfType([
        PropTypes.shape({
          indexOf: PropTypes.func.isRequired,
        }),
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('./../../assets/img/jemlogo.png'),
            imgAlt: 'jem logo',
          }}
        />
        <div className="main-content" ref={this.mainContent}>
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/login" />
          </Switch>
        </div>
      </>
    );
  }
}

export default Admin;
