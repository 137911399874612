import React from 'react';
import './AdvanceStatusBadge.css';

type StatusBadgeMappingType = {
  paid: string;
  payment_failed: string;
  payment_paused: string;
  payment_initiated: string;
  pending: string;
  rejected: string;
};

const StatusBadgeMapping: StatusBadgeMappingType = {
  paid: 'success',
  payment_failed: 'failed',
  payment_paused: 'danger',
  payment_initiated: 'pending',
  pending: 'pending',
  rejected: 'failed',
};

function getStatusStyle(
  obj: { [key: string]: any },
  key: string,
  defaultValue: string
): string {
  return obj[key] !== undefined ? obj[key] : defaultValue;
}

interface AdvanceStatusBadgeProps {
  status: string;
}

const AdvanceStatusBadge: React.FC<AdvanceStatusBadgeProps> = (props) => {
  const statusState = getStatusStyle(
    StatusBadgeMapping,
    props.status,
    'danger'
  );

  return (
    <div className="badge-container">
      <span className={`status-badge-${statusState}`}>
        <label className={`label-${statusState}`} htmlFor='status'>
          {props.status.toUpperCase()}
        </label>
      </span>
    </div>
  );
};

export default AdvanceStatusBadge;
