import React, { useContext } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Label, Input, Row, ListGroup, ListGroupItem } from 'reactstrap';
import Select from 'react-select';
import { EmployerReconContext } from './EmployerReconsContext';

const weekDay = [
  {
    label: 'Monday',
    value: 'MO',
  },
  {
    label: 'Tuesday',
    value: 'TU',
  },
  {
    label: 'Wednesday',
    value: 'WE',
  },
  {
    label: 'Thursday',
    value: 'TH',
  },
  {
    label: 'Friday',
    value: 'FR',
  },
];

const monthDate = [...Array.from(Array(32).keys()).slice(1, 32)].map((d) => ({
  value: d,
  label: d,
}));

const nthDayInMonth = [
  {
    label: '1st',
    value: 1,
  },

  {
    label: '2nd',
    value: 2,
  },

  {
    label: '3rd',
    value: 3,
  },

  {
    label: '4th',
    value: 4,
  },
  {
    label: 'Last',
    value: -1,
  },
];

const today = new Date();

const RecurrenceRuleGenerator = () => {
  const {
    eventState,
    updateStartDate,
    updateRepeatFrequency,
    updateInterval,
    updateMonthlyType,
    updateDateInMonth,
    updateNthDayInMonth,
    updatePosDayInMonth,
    updateDayOfWeek,
  } = useContext(EmployerReconContext);

  return (
    <div className="p-1">
      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col className="col-1 mx-4">
              <strong className="float-right">Repeat</strong>
            </Col>
            <Col className="col-1 ml-4">
              <Label>
                <Input
                  checked={eventState.repeatFrequency === 'weekly'}
                  onChange={(e) => updateRepeatFrequency(e.target.value)}
                  style={{
                    width: '14x',
                    height: '14px',
                  }}
                  type="radio"
                  value="weekly"
                />
                Weekly
              </Label>
            </Col>

            <Col className="col-1 ml-4">
              <Label>
                <Input
                  checked={eventState.repeatFrequency === 'monthly'}
                  onChange={(e) => updateRepeatFrequency(e.target.value)}
                  style={{
                    width: '14x',
                    height: '14px',
                  }}
                  type="radio"
                  value="monthly"
                />
                Monthly
              </Label>
            </Col>
          </Row>

          {eventState.repeatFrequency === 'weekly' && (
            <>
              <Row className="my-4">
                <Col className="col-1 mx-4">
                  <strong className="float-right">Every</strong>
                </Col>
                <Col className="col-2">
                  <Input
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                    onChange={(e) => updateInterval(e.target.value)}
                    type="number"
                    bsSize="sm"
                    id="reconEvent"
                    required
                  />
                </Col>
                <Col className="col-4">
                  {eventState.interval > 1 ? 'weeks' : 'week'}
                </Col>
              </Row>

              {eventState.interval > 0 &&
                eventState.interval <= 2 && ( // makes sure we are creating events for weekly or bi weekly only
                  <Row className="my-4">
                    <Col className="col-1 mx-4">
                      <strong className="float-right">On</strong>
                    </Col>
                    <Col className="col-2 ml-4">
                      <Label>
                        <Input
                          checked={eventState.dayOfWeek === weekDay[0].value}
                          onChange={(e) => updateDayOfWeek(e.target.value)}
                          style={{
                            width: '14x',
                            height: '14px',
                          }}
                          type="radio"
                          value={weekDay[0].value}
                        />
                        Monday
                      </Label>
                    </Col>

                    <Col className="col-2">
                      <Label>
                        <Input
                          checked={eventState.dayOfWeek === weekDay[1].value}
                          onChange={(e) => updateDayOfWeek(e.target.value)}
                          style={{
                            width: '14x',
                            height: '14px',
                          }}
                          type="radio"
                          value={weekDay[1].value}
                        />
                        Tuesday
                      </Label>
                    </Col>

                    <Col className="col-2">
                      <Label>
                        <Input
                          checked={eventState.dayOfWeek === weekDay[2].value}
                          onChange={(e) => updateDayOfWeek(e.target.value)}
                          style={{
                            width: '14x',
                            height: '14px',
                          }}
                          type="radio"
                          value={weekDay[2].value}
                        />
                        Wednesday
                      </Label>
                    </Col>

                    <Col className="col-2">
                      <Label>
                        <Input
                          checked={eventState.dayOfWeek === weekDay[3].value}
                          onChange={(e) => updateDayOfWeek(e.target.value)}
                          style={{
                            width: '14x',
                            height: '14px',
                          }}
                          type="radio"
                          value={weekDay[3].value}
                        />
                        Thursday
                      </Label>
                    </Col>

                    <Col className="col-1 ml-4">
                      <Label>
                        <Input
                          checked={eventState.dayOfWeek === weekDay[4].value}
                          onChange={(e) => updateDayOfWeek(e.target.value)}
                          style={{
                            width: '14x',
                            height: '14px',
                          }}
                          type="radio"
                          value={weekDay[4].value}
                        />
                        Friday
                      </Label>
                    </Col>
                  </Row>
                )}
            </>
          )}

          {eventState.repeatFrequency === 'monthly' && (
            <>
              <Row className="my-4">
                <Col className="col-1 mx-4" />
                <Col className="col-1 ml-4">
                  <div>
                    <Input
                      className="pl-2"
                      checked={eventState.monthlyType === 'byDateInMonth'}
                      onChange={(e) => updateMonthlyType('byDateInMonth')}
                      style={{
                        width: '14x',
                        height: '14px',
                      }}
                      type="radio"
                      value="byDateInMonth"
                    />
                  </div>
                </Col>

                <Col className="col-2">
                  <div>On day</div>
                </Col>

                <Col className="col-3">
                  <Select
                    isDisabled={eventState.monthlyType !== 'byDateInMonth'}
                    onChange={(e) => updateDateInMonth(e.value)}
                    options={monthDate}
                  />
                </Col>
              </Row>

              <Row className="my-4">
                <Col className="col-1 mx-4" />
                <Col className="col-1 ml-4">
                  <div>
                    <Input
                      className="pl-2"
                      checked={eventState.monthlyType === 'nthDayInMonth'}
                      onChange={(e) => updateMonthlyType('nthDayInMonth')}
                      style={{
                        width: '14x',
                        height: '14px',
                      }}
                      type="radio"
                      value="nthDayInMonth"
                    />
                  </div>
                </Col>

                <Col className="col-2">
                  <div>On the</div>
                </Col>

                <Col className="col-3">
                  <Select
                    isDisabled={eventState.monthlyType !== 'nthDayInMonth'}
                    onChange={(e) => updateNthDayInMonth(e.value)}
                    options={nthDayInMonth}
                  />
                </Col>

                <Col className="col-3">
                  <Select
                    isDisabled={eventState.monthlyType !== 'nthDayInMonth'}
                    onChange={(e) => updatePosDayInMonth(e.value)}
                    options={weekDay}
                  />
                </Col>
              </Row>
            </>
          )}
        </ListGroupItem>

        <ListGroupItem>
          <Row>
            <Col className="col-1 mx-4">
              <strong className="float-right">Start</strong>
            </Col>

            <Col className="col-4">
              <DatePicker
                dateFormat="  dd  MMMM y  "
                minDate={today}
                onChange={(d) => updateStartDate(d)}
                selected={eventState.startDate}
              />
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default RecurrenceRuleGenerator;
