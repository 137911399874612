import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { eventIsAMouseClickOrKeyboardSpaceOrEnter } from '../helper/input-helper';

const Pagination = ({ page, setPage, pages }) => (
  <Row>
    <Col>
      <Card className="p-3">
        <nav
          aria-label="Page navigation example"
          style={{ display: 'flex', justifyContent: 'center' }}>
          <ul className="pagination pagination-lg">
            {page > 1 && (
              <li className="page-item">
                <button 
                  className="page-link" 
                  onClick={() => setPage(page - 1)} 
                  onKeyDown={(e) => {
                    if (eventIsAMouseClickOrKeyboardSpaceOrEnter(e)) {
                      setPage(page - 1);
                    }
                  }}>
                  <i className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </button>
              </li>
            )}
            {page > 1 && (
              <li className="page-item">
                <button 
                  className="page-link" 
                  onClick={() => setPage(page - 1)} 
                  onKeyDown={(e) => {
                    if (eventIsAMouseClickOrKeyboardSpaceOrEnter(e)) {
                      setPage(page - 1);
                    }
                  }}>
                  {page - 1}
                </button>
              </li>
            )}
            <li className="page-item active">
              <div className="page-link">{page && `${page}`}</div>
            </li>
            {page < pages && (
              <li className="page-item">
                <button 
                  className="page-link" 
                  onClick={() => setPage(page + 1)} 
                  onKeyDown={(e) => {
                    if (eventIsAMouseClickOrKeyboardSpaceOrEnter(e)) {
                      setPage(page + 1);
                    }
                  }}>
                  {page + 1}
                </button>
              </li>
            )}
            {page < pages && (
              <li className="page-item">
                <button 
                  className="page-link" 
                  onClick={() => setPage(page + 1)} 
                  onKeyDown={(e) => {
                    if (eventIsAMouseClickOrKeyboardSpaceOrEnter(e)) {
                      setPage(page + 1);
                    }
                  }} 
                  aria-label="Next">
                  <i className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </button>
              </li>
            )}
          </ul>
        </nav>
      </Card>
    </Col>
  </Row>
);

// Add prop types validation
Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
};

export default Pagination;
