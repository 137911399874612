/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  Table,
  CardHeader,
  FormGroup,
  Col,
  Label,
  Input,
  Container,
  Row,
  Alert,
} from 'reactstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import ManageMatchingCriteria from '../../../components/GenericDocuments/ManageMatchingCriteria';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';
import EditEmployerPayrollForm from './EditEmployerPayrollForm';
import AddEmployerPayrollModal from './AddEmployerPayrollModal';
import Branches from '../Branches';
import SubscriptionsTable from './SubscriptionsTable';
import TransactionFeesTable from './TransactionFeesTable';
import FlaggedButton from '../../../components/FlaggedButton';
import ManageMultipleMatchingCriterias from '../../../components/GenericDocuments/ManageMultipleMatchingCriterias';

class EmployerDetail extends React.Component {
  state = {
    loading: false,
    save_data: {},
    errors: [],
    formError: false,
    triedSubmitting: true,
    showEditModal: false,
    showAddPayrollModal: false,
    activePayroll: null,
    allowApplyAll: true,
    subscriptions: [],
    employerFeatures: [],
    _protectedPayslip: {},
  };

  featureGroups = [
    {
      name: "Documents",
      features: [
        {
          name: "Payslips",
          depends: []
        },
        {
          name: "Password Protected Payslips",
          depends: ["Payslips"]
        },
        {
          name: "IRP5s",
          depends: []
        },
        {
          name: "Self Managed Payslips Matching Criteria",
          depends: []
        }
      ]
    },
    {
      name: "Earned Wage Access",
      features: [
        {
          name: "Earned Wage Access",
          depends: []
        },
        {
          name: "Instant Payment",
          depends: ["Earned Wage Access"]
        },
        {
          name: "Automated ODP",
          depends: ["Earned Wage Access"]
        }
      ]
    },
    {
      name: "Financial Education",
      features: [
        {
          name: "Education",
          depends: []
        }
      ]
    },
    {
      name: "Leave",
      features: [
        {
          name: "Leave Management",
          depends: []
        }
      ]
    },
    {
      name: "Time & Attendance",
      features: [
        {
          name: "Timesheets",
          depends: []
        },
        {
          name: "Rosters",
          depends: []
        }
      ]
    }
  ]

  daysOfWeek = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  };

  payDayPositions = {
    0: 'First',
    1: 'Last',
  };

  componentDidMount() {
    this.getEmployerData();
    this.getEmployerFeatures();
  }

  handleinputchange = (event) => {
    event.preventDefault();
    this.setState({
      save_data: {
        ...this.state.save_data,
        [event.target.name]: event.target.value,
      },
    });
    this.setState({ allowApplyAll: false });
  };

  getEmployerData = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/employers/${this.props.match.params.empId}/`)
      .then((res) => {
        this.setState({
          ...res.data,
          save_data: res.data,
          loading: false,
        });
      });
  };

  getEmployerFeatures = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${environment.baseUrl}/employer_features/?employer_id=${this.props.match.params.empId}`,
      )
      .then((res) => {
        // NB: Check API to match the correct text
        this.setState({
          employerFeatures: res.data,
          _protectedPayslip: res.data.filter(
            (f) => f.name === 'Password Protected Payslips',
          )[0],
        });
        this.setState({ loading: false });
      }).catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        this.setState({ loading: false });
      });
  };

  updateEmployerFeature = async (feature, enable) => {
    const action = enable ? 'Enable' : 'Disable';

    const swal = await Swal.fire({
      title: `${action} feature?`,
      text: `Are you sure you want to ${action} ${feature.name} feature?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: enable ? 'green' : '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, ${action} it!`,
    });

    if (swal.isConfirmed) {
      axios
        .post(`${environment.baseUrl}/employer_features/toggle/`, {
          enabled: enable,
          employer_id: this.props.match.params.empId,
          feature_id: feature.id,
        })
        .then(() => {
          Swal.fire({
            title: `Successfully ${action}d ${feature.name}`,
            icon: 'success',
          });
          this.getEmployerFeatures();
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  /**
   * Delete an employer payroll.
   * NB: This is a hard delete !!
   *
   * @param {int} payRollId
   *
   */
  deletePayRoll = (payRollId) => {
    this.setState({ loading: true });
    axios
      .delete(`${environment.baseUrl}/pay_cycles/${payRollId}/`)
      .then(() => {
        Swal.fire({
          title: 'Successfully deleted payroll.',
          icon: 'success',
        });
        this.getEmployerData();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  handleShowEditPayrollModal = (pay_cycle) => {
    this.setState({ activePayroll: pay_cycle }, () =>
      this.setState({ showEditModal: true }),
    );
  };

  handleShowAddPayrollModal = () => {
    this.setState({ showAddPayrollModal: true });
  };

  applyAction = (action, description) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to ${description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { save_data } = this.state;

        await axios.patch(
          `${environment.baseUrl}/employers/${this.props.match.params.empId}/`,
          {
            ...save_data,
          },
        );
        axios
          .post(
            `${environment.baseUrl}/employers/${this.props.match.params.empId}/${action}/`,
          )
          .then(() => {
            Swal.fire({
              title: 'Action was successful',
              icon: 'success',
            });
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  validateInputs = (data) => {
    const errors = [];

    if (data?.company_name?.trim() === '' || data?.company_name === undefined) {
      errors.push('Company Name is a required field.');
    }

    if (!data?.default_risk_metric || data?.default_risk_metric === undefined) {
      errors.push('Default Risk Metric is a required field.');
    }

    this.setState({
      ...this.state,
      errors,
      formError: errors.length > 0,
      triedSubmitting: true,
    });

    return errors.length < 1;
  };

  saveData = () => {
    if (this.validateInputs(this.state.save_data)) {
      axios
        .patch(
          `${environment.baseUrl}/employers/${this.props.match.params.empId}/`,
          {
            ...this.state.save_data,
          },
        )
        .then(() => {
          Swal.fire({
            title: 'Employer profile saved',
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.setState({ allowApplyAll: true });
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  toggle = () => {
    this.getEmployerData();
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  toggleAdvanceApproval = (e) => {
    this.setState({
      save_data: {
        ...this.state.save_data,
        advances_require_approval: e.target.checked,
      },
    });
  };

  toggleAddPayrollModal = () =>
    this.setState({ showAddPayrollModal: !this.state.showAddPayrollModal });

  getDay = (number) => {
    switch (number) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return 'Cannot determine day';
    }
  };

  deleteEmployer = () => {
    Swal.fire({
      title: `Deleting ${this.state.company_name}?`,
      text: `All the Pending Advances will be rejected, and All ODP features for ${this.state.company_name} will be disabled
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${environment.baseUrl}/employers/${this.props.match.params.empId}/delete_employer/`,
          )
          .then(() => {
            Swal.fire({
              title: `Employer ${this.state.company_name} deleted `,
              icon: 'success',
            });

            this.props.history.push('/admin/employers');
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  render() {
    const {
      company_name,
      loading,
      save_data,
      allowApplyAll,
      employerFeatures,
      _protectedPayslip,
    } = this.state;

    return (
      <section className="dashboard">
        <div className="header bg-primary">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {company_name}
                  </h6>
                </Col>
                <Col className="d-flex justify-content-end">
                  <button
                    className="btn btn-war btn-md"
                    type="button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/admin/employers/${this.props.match.params.empId}/recons`,
                        state: {
                          company_name,
                          save_data,
                        },
                      })
                    }
                  >
                    Manage Recons
                  </button>

                  <FlaggedButton
                    flag="ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP"
                    className="btn btn-danger btn-md"
                    type="button"
                    onClick={() => this.deleteEmployer()}
                  >
                    Delete
                  </FlaggedButton>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid>
          <Row className="pt-2">
            {loading ? (
              <Row className="my-3">
                <Col className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <Col lg={8}>
                  <Row className="mb-4">
                    <Col>
                      {/* pay rolls */}
                      <Card className="shadow">
                        <CardHeader>
                          <strong>Employer Payrolls</strong>
                          <Button
                            color="primary"
                            onClick={() => this.handleShowAddPayrollModal()}
                            className="btn btn-primary btn-sm float-right"
                          >
                            New Payroll
                          </Button>
                        </CardHeader>

                        <Table responsive striped size="sm">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>ID</th>
                              <th>Description</th>
                              <th>Cut off</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {!!save_data.pay_cycles &&
                              save_data.pay_cycles.map((pay_cycle) => (
                                <tr key={`payroll_${pay_cycle.id}`}>
                                  <td>
                                    {pay_cycle.name}{' '}
                                    {pay_cycle.is_default && (
                                      <span className="badge badge-success">
                                        Default
                                      </span>
                                    )}
                                    {pay_cycle.enable_custom_cutoff_date &&
                                      Date.parse(
                                        pay_cycle.advance_cut_off_date,
                                      ) < Date.now() && (
                                        <span className="badge badge-warning">
                                          Custom cutoff
                                        </span>
                                      )}
                                  </td>
                                  <td>{pay_cycle.id}</td>
                                  <td>
                                    {pay_cycle.frequency === 'monthly' &&
                                      pay_cycle.frequency_type ===
                                      'day based' &&
                                      `Monthly, on the ${pay_cycle.pay_day_position === 0
                                        ? 'first'
                                        : 'last'
                                      } ${this.getDay(pay_cycle.pay_day)}`}
                                    {pay_cycle.frequency === 'monthly' &&
                                      pay_cycle.frequency_type ===
                                      'date based' &&
                                      `Monthly, on the ${pay_cycle.pay_date}`}
                                    {pay_cycle.frequency === 'weekly' &&
                                      pay_cycle.frequency_type ===
                                      'day based' &&
                                      `Weekly, on a ${this.getDay(
                                        pay_cycle.pay_day,
                                      )}`}
                                    {pay_cycle.frequency === 'fortnightly' &&
                                      pay_cycle.frequency_type ===
                                      'day based' &&
                                      `Fortnightly, starting on the ${moment(
                                        pay_cycle.initial_pay_date,
                                      ).format('Do MMMM YYYY')}`}
                                  </td>
                                  <td>{pay_cycle.cut_off_days}</td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        this.deletePayRoll(pay_cycle.id)
                                      }
                                      className="btn btn-danger btn-sm float-right"
                                      style={{ marginLeft: '4px' }}
                                    >
                                      Delete
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.handleShowEditPayrollModal(
                                          pay_cycle,
                                        )
                                      }
                                      className="btn btn-primary btn-sm float-right"
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Card>
                      {/* end payrolls */}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <SubscriptionsTable
                        employerId={this.props.match.params.empId}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <TransactionFeesTable
                        employerId={this.props.match.params.empId}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <Branches
                        branches={save_data.branches}
                        employerId={this.props.match.params.empId}
                        getEmployerData={this.getEmployerData}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <strong>Onboarding SMS</strong>
                          <button
                            onClick={() =>
                              this.applyAction(
                                'sendOnboardingSms',
                                `send an onboarding sms to all employees of ${company_name}`,
                              )
                            }
                            className="btn btn-primary btn-sm float-right"
                          >
                            Send SMS
                          </button>
                        </CardHeader>
                        <CardBody />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  {this.state.formError && this.state.errors && (
                    <Alert color="danger">
                      {this.state.errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </Alert>
                  )}
                  <Card className="shadow">
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <label
                              htmlFor="fee_value"
                              className="required-input"
                            >
                              Company name
                            </label>
                            <Input
                              type="text"
                              name="company_name"
                              value={save_data.company_name}
                              onChange={this.handleinputchange}
                              className="form-control"
                              placeholder="Company name"
                              invalid={
                                this.state.triedSubmitting &&
                                !this.state.save_data?.company_name?.trim()
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      {employerFeatures && this.featureGroups.map((group) => (
                        <>
                          <Row>
                            <Col lg={12} className="font-weight-bold">{group.name}</Col>
                            {group.features.map((feature) => (
                              <>
                                <Col lg={10}>{feature.name}</Col>
                                <Col lg={1}>
                                  <Input
                                    onChange={(e) =>
                                      this.updateEmployerFeature(
                                        employerFeatures.filter((f) => f.name === feature.name)[0],
                                        !employerFeatures.filter((f) => f.name === feature.name)[0]?.enabled,
                                      )
                                    }
                                    type="checkbox"
                                    disabled={
                                      feature.depends.length > 0 &&
                                      employerFeatures.filter((f) => f.name === feature.depends[0])[0]?.enabled === false
                                    }
                                    checked={employerFeatures.filter((f) => f.name === feature.name)[0]?.enabled}
                                  />
                                </Col>
                              </>
                            ))}
                          </Row>
                          <br />
                        </>
                      ))}
                      <Row>
                        <Col lg={12} className="font-weight-bold">Other Features</Col>
                        {
                          employerFeatures &&
                          employerFeatures.map((feature) => {
                            if (this.featureGroups.map((group) => group.features.map((feature) => feature.name)).flat().includes(feature.name)) {
                              return null;
                            }

                            return (
                              <>
                                <Col lg={10}>{feature.name}</Col>
                                <Col lg={1}>
                                  <Input
                                    onChange={(e) =>
                                      this.updateEmployerFeature(
                                        feature,
                                        !feature.enabled,
                                      )
                                    }
                                    type="checkbox"
                                    checked={feature.enabled}
                                  />
                                </Col>
                              </>
                            )
                          })
                        }

                      </Row>
                      <br />

                      <Row>
                        <Col lg={12} className="font-weight-bold">Approvals</Col>
                        <Col lg={10}>Advances Require Approval</Col>
                        <Col lg={1}>
                          <Input
                            onChange={this.toggleAdvanceApproval}
                            type="checkbox"
                            checked={save_data.advances_require_approval}
                          />{' '}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <label
                              htmlFor="fee_value"
                              className="required-input"
                            >
                              Default risk metric
                            </label>
                            <div className="input-group">
                              <Input
                                type="number"
                                name="default_risk_metric"
                                value={save_data.default_risk_metric}
                                onChange={this.handleinputchange}
                                className="form-control"
                                style={{ maxWidth: '10rem' }}
                                placeholder="Risk metric"
                                invalid={
                                  this.state.triedSubmitting &&
                                  !this.state.save_data?.default_risk_metric &&
                                  this.state.save_data?.default_risk_metric !==
                                  0
                                }
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">%</span>
                              </div>
                            </div>
                            <br />

                            <Button
                              className="btn btn-block"
                              color={allowApplyAll ? 'info' : 'secondary'}
                              disabled={!allowApplyAll}
                              onClick={() =>
                                this.applyAction(
                                  'applyDefaultRiskMetric',
                                  `apply default risk metric to all the employees of ${company_name}`,
                                )
                              }
                            >
                              {allowApplyAll
                                ? 'Apply default risk metric to all employees'
                                : 'Press save changes to enable this button'}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="defaultPayRoll">Default payroll</Label>

                            <Input
                              type="select"
                              name="defaultPayRoll"
                              id="defaultPayRoll"
                              onChange={(e) => this.handleinputchange(e)}
                              required
                            >
                              {!!save_data.pay_cycles &&
                                save_data.pay_cycles.map((pay_cycle) => {
                                  if (pay_cycle.is_default) {
                                    return (
                                      <option selected value={pay_cycle.id}>
                                        {pay_cycle.name}
                                      </option>
                                    );
                                  }
                                  return (
                                    <option
                                      value={pay_cycle.id}
                                      key={pay_cycle.id}
                                    >
                                      {pay_cycle.name}
                                    </option>
                                  );
                                })}
                            </Input>
                            <br />

                            <Button
                              className="btn btn-block"
                              color={allowApplyAll ? 'info' : 'secondary'}
                              disabled={!allowApplyAll}
                              onClick={() =>
                                this.applyAction(
                                  'applyDefaultPayroll',
                                  `apply the default payroll to all the employees of ${company_name}`,
                                )
                              }
                            >
                              {allowApplyAll
                                ? 'Apply default payroll to all employees'
                                : 'Press save changes to enable this button'}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col>
                          <button
                            onClick={this.saveData}
                            className="btn btn-primary btn-block"
                          >
                            Save changes
                          </button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {this.props.match.params.empId && (
                    <ManageMatchingCriteria
                      documentType="Payslips"
                      employerId={this.props.match.params.empId}
                    />
                  )}
                  {!loading && this.props.match.params.empId && employerFeatures && employerFeatures
                    .filter((f) => (f.name === 'Rosters' && f.enabled) || (f.name === 'Timesheets' && f.enabled)).length > 0 && (
                      <ManageMultipleMatchingCriterias
                        employerId={this.props.match.params.empId}
                        features={employerFeatures.filter((f) => (f.name === 'Rosters' && f.enabled) || (f.name === 'Timesheets' && f.enabled))}
                        title={employerFeatures.filter((f) => (f.name === 'Rosters' && f.enabled) || (f.name === 'Timesheets' && f.enabled)).map((f) => f.name).join(', ').replace(/, ([^,]*)$/, ' & $1')}
                      />
                    )}
                </Col>
              </>
            )}
          </Row>
        </Container>

        {!!this.state.activePayroll && this.state.showEditModal && (
          <EditEmployerPayrollForm
            key={this.state.activePayroll.id}
            toggle={this.toggle}
            showEditModal
            payrollName={this.state.activePayroll.name}
            isDefault={this.state.activePayroll.is_default}
            payrollId={this.state.activePayroll.id}
            enableCustomCutOffDate={
              this.state.activePayroll.enable_custom_cutoff_date
            }
            cutOffDays={this.state.activePayroll.cut_off_days}
            advanceReconDate={this.state.activePayroll.advance_recon_date}
            advanceCutOffDate={this.state.activePayroll.advance_cut_off_date}
          />
        )}

        {!!this.state.showAddPayrollModal && (
          <AddEmployerPayrollModal
            employerId={this.props.match.params.empId}
            toggle={this.toggleAddPayrollModal}
            showAddPayrollModal={this.state.showAddPayrollModal}
          />
        )}
      </section>
    );
  }
}

export default EmployerDetail;
