import { Document, Page, pdfjs } from 'react-pdf';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { environment } from '../../../../enviroment/enviroment';
import apiResponseHandler from '../../../../utils/apiResponseHandler';
import EmployeeDocumentUploadModal from './EmployeeDocumentUploadModal';
import EmployeeDocumentEmailModal from './EmployeeDocumentEmailModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const removeEmployeeDocument = ({
  documentType,
  documentId,
  getEmployeeDocuments,
  employeeId,
  setRemoveDocumentLoading,
}) => {
  Swal.fire({
    title: 'Remove Document?',
    text: `Are you sure you want to remove this document?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d63030',
    confirmButtonText: 'Confirm!',
  }).then((result) => {
    if (result.isConfirmed) {
      setRemoveDocumentLoading(true);
      let url = `${environment.baseUrl}/employee_payslips/${documentId}/remove_employee_payslip/`;
      if (documentType === 'irp5s') {
        url = `${environment.baseUrl}/employee_irp5s/${documentId}/remove_employee_irp5/`;
      } else if (documentType === 'timesheets') {
        url = `${environment.baseUrl}/employee_timesheet/${documentId}/remove_employee_timesheet/`;
      } else if (documentType === 'rosters') {
        url = `${environment.baseUrl}/employee_roster/${documentId}/remove_employee_roster/`;
      }
      axios
        .patch(url)
        .then((res) => {
          getEmployeeDocuments(employeeId);
          setRemoveDocumentLoading(false);
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
          setRemoveDocumentLoading(false);
        });
    }
  });
};

const EmployeeDocumentUpdate = (props) => {
  const [documentUrl, setDocumentUrl] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [removeDocumentLoading, setRemoveDocumentLoading] = useState(false);
  const [sendingDocumentLoading, setSendingDocumentLoading] = useState(false);
  const updateModalState = (isOpen, isRefresh) => {
    props.showUpdatedDocument(props.selectedDocument.id, isRefresh);
    setShowUploadModal(isOpen);
  };

  const [singlarDocumentName, setSinglarDocumentName] = useState('');

  useEffect(() => {
    if (props.documentType === 'irp5s'){
      setSinglarDocumentName('IRP5');
    } else if (props.documentType === 'payslips'){
      setSinglarDocumentName('Payslip');
    } else if (props.documentType === 'timesheets'){
      setSinglarDocumentName('Timesheet');
    } else if (props.documentType === 'rosters'){
      setSinglarDocumentName('Roster');
    }
  }, [props]);

  useEffect(() => {
    if (props.documentType === 'irp5s'){
      setDocumentUrl(props.selectedDocument.irp5_file);
    } else if (props.documentType === 'payslips'){
      setDocumentUrl(props.selectedDocument.payslip_file);
    } else if (props.documentType === 'timesheets'){
      setDocumentUrl(props.selectedDocument.timesheet_file);
    } else if (props.documentType === 'rosters'){
      setDocumentUrl(props.selectedDocument.roster_file);
    }
  }, [props]);

  return (
    <div>
      {showUploadModal && (
        <EmployeeDocumentUploadModal
          isCreateNew={false}
          documentId={props.selectedDocument.id}
          employeeData={props.employeeData}
          updateModalState={updateModalState}
          selectedDocument={props.selectedDocument}
          documentType={props.documentType}
        />
      )}
      {showShareModal && (
        <EmployeeDocumentEmailModal
          isCreateNew={false}
          showModal={showShareModal}
          documentId={props.selectedDocument.id}
          employeeData={props.employeeData}
          updateModalState={updateModalState}
          selectedDocument={props.selectedDocument}
          documentType={props.documentType}
        />
      )}
      <Row className="float-right">
        <Col lg={6} />
        <Col lg={6} className="float-right">
          <button
            type="submit"
            className="btn btn-primary float-right"
            style={{
              marginBottom: '6px',
              marginRight: '3px',
              minWidth: '200px',
            }}
            onClick={() => setShowUploadModal(true)}
          >
            Update {singlarDocumentName}
          </button>

          <button
            type="submit"
            className="btn btn-danger float-right"
            disabled={removeDocumentLoading}
            load
            style={{
              marginBottom: '6px',
              marginRight: '3px',
              minWidth: '200px',
            }}
            onClick={() =>
              removeEmployeeDocument({
                documentType: props.documentType,
                documentId: props.selectedDocument.id,
                getEmployeeDocuments: props.getEmployeeDocuments,
                employeeId: props.employeeId,
                setRemoveDocumentLoading,
              })
            }
          >
            {removeDocumentLoading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              `Remove ${singlarDocumentName}`
            )}
          </button>
          {["payslips"].includes(props.documentType) && (
            <button
              type="submit"
              className="btn btn-warning float-right"
              load
              style={{
                marginBottom: '6px',
                marginRight: '3px',
                minWidth: '200px',
              }}
              onClick={() => setShowShareModal(true)}
            >
              Share{" "}{singlarDocumentName}
            </button>
          )}
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <div style={{ overflow: 'scroll', maxHeight: '500px' }}>
          {documentUrl && (
            <Document file={documentUrl}>
              <Page pageNumber={1} />
            </Document>
          )}
        </div>
      </Row>
    </div>
  );
};

export default EmployeeDocumentUpdate;
