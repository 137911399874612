import React, { useEffect } from 'react';
import './styles.css';
import { Button, Card, CardHeader } from 'reactstrap';
import moment from 'moment';
import { ElasticMessage } from '../../../../types/MessageTypes';

interface WhatsAppMessageProps {
    message: ElasticMessage
};

export default function WhatsAppMessage({message}: WhatsAppMessageProps) {

  const [messageBody, setMessageBody] = React.useState<string>('');

  const formatWhatsappMessage = (text:string) => text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
    .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
    .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
    .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
    .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>')
    .replace(/\n/g, '<br />');

  useEffect(() => {
      let body = 'Message Empty';

      switch (message.type) {
        case 'text':
          if (typeof message.message.body === 'string') {
            body = message.message.body;
          } else {
            body = message.message.body?.text ||  message.message.text?.body || '';
          }
          break;
        case 'interactive':
          if (message.message.type === "list" && typeof message.message.body === 'object') {
            body = message.message.body?.text || '';
          }
          if (message.message.type === "list_reply") {
            body = message.message?.list_reply?.title || '';
          }
          if (message.message.type === "button_reply"){
            body = message.message?.button_reply?.title || '';
          }
          if (message.message.type === "button"  && typeof message.message.body === 'object') {
            body = message.message.body?.text || '';
          }
          break;
        case 'template':
            body = `Template: *${message.message.template?.name || ''}*\n`;
            if (message.message.template?.components?.length) {
              body += '\n*Components:*\n';
              body += message.message.template?.components?.map((component) => {
                if (component.type === 'body') {
                  return component.parameters?.map((parameter) => `${parameter.type}: ${parameter.text || ''}`).join('\n');
                }
                if (component.type === 'button') {
                  return component.parameters?.map((parameter) => `${parameter.type}: ${parameter.payload || ''}`).join('\n');
                }
                return '';
              }).join('\n') || '';
            }
          break;
        default:
          break;
      }
      setMessageBody(formatWhatsappMessage(body));
  }, [message]);

  const renderMessage = () => {
    if (message.message?.type === "list_reply" || message.message?.type === "button_reply") {
      return <Button size='sm' disabled color="primary">{messageBody}</Button>;
    }
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{__html: messageBody}} />;
  };

  return (
    <div className={`p-3 whatsapp-message ${message.sender_profile?.company_name ? 'outgoing' : 'incoming'}`}>
      <div className="whatsapp-card">
        <Card className={message.message?.type === "list_reply" || message.message?.type === "button_reply" ? "" : 'p-3'}>
              {message.message?.header?.type && (message.message?.header?.type === "text" || message.message?.header?.type === "image") && (
                <CardHeader>
                  {message.message?.header?.type === "image" && (
                    <img src={message.message.header.image?.link} alt="header" />
                  )}
                  {message.message?.header?.type === "text" && (
                    <h5 className="text-left border-bottom">
                      {message.message.header?.text}
                    </h5>
                  )}
                </CardHeader>
              )}
              <div className="d-flex justify-content-between">
                {renderMessage()}
              </div>
              {message.message?.action?.sections?.map((section) => (
                <div key={section.title} className="section">
                  <h3 className="text-center">{section.title}</h3>
                  {section.rows.map((row) => (
                    <Button size='sm' className="mt-2" disabled color="primary" key={row.id}>{row.title}</Button>
                  ))}
                </div>
              ))}
              {message.message?.action?.buttons?.map((button) => (
                <div key={button.reply.id} className="section">
                  <Button size='sm' className="mt-2" disabled color="primary">{button.reply.title}</Button>
                </div>
              ))}
        </Card>
        <div className="d-flex justify-content-between mt-2">
          <div className="text-muted text-xs">
            {message.sender_profile?.company_name || message.sender_profile?.mobile_number}
            {message.sender_profile?.first_name && <><br />{message.sender_profile?.first_name} <a href={`/admin/employees/${message.sender_profile?.id}`}>#{message.sender_profile?.id}</a></>}
          </div>
          <div className="text-muted text-xs">{moment.unix(message.timestamp).format('DD/MM/YYYY HH:mm')}</div>
        </div>
      </div>
    </div>
  );
}
