export default function removeLeadingTrailingChar(str, char) {
  // both start and end
  if (str.startsWith(char) && str.endsWith(char)) {
    return str.slice(1, -1);
  }

  // only the start
  if (str.startsWith(char)) {
    return str.slice(1);
  }

  // only the end
  if (str.endsWith(char)) {
    return str.slice(0, -1);
  }

  return str;
}
