/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Label,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';

import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import {
  formatGetLocalTime,
  formatDateForInput,
} from '../../../utils/formatters';

import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary AddNewSubscriptionBatchModal component
 *
 * @param {Props} props
 * @component
 */
const AddNewSubscriptionBatchModal = (props) => {
  const [state, setState] = useState({
    employers: [],
    subscriptionOptions: [],
    activeEmployer: null,
    newSubscriptionBatchPayload: {},
    lastSubscriptionBatch: null,
    selectedSubscriptionId: null,
  });

  const {
    toggleAddNewBatchModal,
    handleShowAddNewBatchModal,
    getSubscriptionBatches,
  } = props;

  const getEmployers = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/`, { headers }).then((res) => {
      setState({
        ...state,
        employers: res.data,
        loading: false,
      });
    });
  };

  useEffect(() => {
    getEmployers();
  }, []);

  const handleSubscriptionChange = (event) => {
    const selectedSubscriptionId = event.target.value;

    axios
      .get(
        `${environment.baseUrl}/subscription_batch/subscription/${selectedSubscriptionId}/`
      )
      .then((res) => {
        setState({
          ...state,
          lastSubscriptionBatch: res.data,
          loading: false,
          selectedSubscriptionId,
        });
      });
  };

  const handleEmployerChange = (event) => {
    event.preventDefault();

    const activeEmployerId = event.target.value;

    const matchingEmployers = state.employers.filter(
      (employer) => employer.id === parseInt(activeEmployerId, 10)
    );
    setState({
      ...state,
      activeEmployer: matchingEmployers[0],
    });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    setState({
      ...state,
      newSubscriptionBatchPayload: {
        ...state.newSubscriptionBatchPayload,
        [name]: value,
      },
    });
  };

  const handleSubmit = () => {
    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;
    const {
      lastSubscriptionBatch,
      newSubscriptionBatchPayload,
      activeEmployer,
      selectedSubscriptionId,
    } = state;

    const payload = {
      /** set start date of new batch to the end date of the last batch */
      start_date: lastSubscriptionBatch
        ? moment(`${lastSubscriptionBatch.end_datetime}`).utc().format()
        : moment(
            `${newSubscriptionBatchPayload.startDate} ${newSubscriptionBatchPayload.startDateTime}`
          ).format(),

      end_date: moment(
        `${newSubscriptionBatchPayload.endDate} ${newSubscriptionBatchPayload.endDateTime}`
      ).format(),
      employer: activeEmployer.id,
      subscription: selectedSubscriptionId,
      requested_by: currentUserId,
    };
    const url = `${environment.baseUrl}/subscription_batch_job/`;

    axios
      .post(url, payload)
      .then(() => {
        Swal.fire({
          title: 'Subscription Batch Job successfully submitted.',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        handleShowAddNewBatchModal();
        getSubscriptionBatches();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError(
          'Error submitting Subscription Batch Job',
          error
        );
        setState({ ...state, containerState: 'STATE_ERROR' });
      });
  };

  return (
    <Modal isOpen={toggleAddNewBatchModal}>
      <ModalHeader>Generate New Subscription Batch</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="exampleSelect">Employer</Label>

            <Input
              type="select"
              name="employerSelect"
              id="employerSelect"
              onChange={(e) => handleEmployerChange(e)}>
              <option />

              {!!state.employers &&
                state.employers.map((employer) => (
                  <option key={employer.id} value={employer.id}>
                    {employer.company_name}
                  </option>
                ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="exampleSelect">Subscription</Label>
            <Input
              type="select"
              name="selectedSubscription"
              id="selectedSubscription"
              onChange={(e) => handleSubscriptionChange(e)}>
              <option />

              {!!state.activeEmployer &&
                state.activeEmployer.subscriptions &&
                state.activeEmployer.subscriptions.map((subscription) => (
                  <option key={subscription.id} value={subscription.id}>
                    {subscription.name}
                  </option>
                ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              placeholder="Batch Start Date"
              onChange={(e) => handleInputChange(e)}
              required
              readOnly={
                !!state.lastSubscriptionBatch &&
                state.lastSubscriptionBatch.last_batch_id
              }
              value={
                state.lastSubscriptionBatch
                  ? formatDateForInput(state.lastSubscriptionBatch.end_datetime)
                  : null
              }
            />
          </FormGroup>

          <FormGroup>
            <Label for="startDateTime">Start Date Time</Label>
            <Input
              type="time"
              name="startDateTime"
              id="startDateTime"
              onChange={(e) => handleInputChange(e)}
              readOnly={
                !!state.lastSubscriptionBatch &&
                state.lastSubscriptionBatch.last_batch_id
              }
              value={
                state.lastSubscriptionBatch
                  ? formatGetLocalTime(state.lastSubscriptionBatch.end_datetime)
                  : null
              }
            />
          </FormGroup>

          <FormGroup>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              onChange={(e) => handleInputChange(e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="endDateTime">End Date Time</Label>
            <Input
              type="time"
              name="endDateTime"
              id="endDateTime"
              onChange={(e) => handleInputChange(e)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>{' '}
        <Button color="secondary" onClick={handleShowAddNewBatchModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddNewSubscriptionBatchModal.defaultProps = {};

AddNewSubscriptionBatchModal.propTypes = {
  toggleAddNewBatchModal: PropTypes.func.isRequired,
  handleShowAddNewBatchModal: PropTypes.func.isRequired,
  getSubscriptionBatches: PropTypes.func.isRequired,
};

export default AddNewSubscriptionBatchModal;
