import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Col, Row, ListGroup, ListGroupItem } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import { EmployerReconContext } from './EmployerReconsContext';

function CalendarDetails() {
  const { updateTitle, updateDescription, updateAttendees } =
    useContext(EmployerReconContext);

  const [users, setUsers] = useState([]);
  const location = useLocation();
  const employerId = location.pathname.split('/')[3];

  useEffect(() => {
    axios
      .get(`${environment.baseUrl}/users/`, {
        params: { employer_id: employerId },
      })
      .then((res) => {
        setUsers(res.data);
      });
  }, [employerId]);

  return (
    <div className="p-1">
      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col className="col-3">
              <strong className="float-right">Title</strong>
            </Col>
            <Col className="col-8">
              <Input
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                onChange={(e) => updateTitle(e.target.value)}
                type="text"
                bsSize="md"
                required
              />
            </Col>
          </Row>
        </ListGroupItem>

        <ListGroupItem>
          <Row>
            <Col className="col-3">
              <strong className="float-right">Description</strong>
            </Col>
            <Col className="col-8">
              <Input
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                onChange={(e) => updateDescription(e.target.value)}
                type="textarea"
                bsSize="md"
                required
              />
            </Col>
          </Row>
        </ListGroupItem>

        <ListGroupItem>
          <Row>
            <Col className="col-3">
              <strong className="float-right">Send to</strong>
            </Col>
            <Col className="col-8">
              <Select
                onChange={(atts) =>
                  updateAttendees(atts ? atts.map((a) => a.value) : [])
                }
                closeMenuOnSelect={false}
                isMulti
                options={users.map((u) => ({
                  value: u.email,
                  label: `${u.first_name} ${u.last_name} | ${u.email}`,
                }))}
              />
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
}

export default CalendarDetails;
