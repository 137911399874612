import React, { useState, useContext } from 'react';
import { Button, ModalFooter, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { EmployerReconContext } from './EmployerReconsContext.js';

const CalendarEventTypes = {
  AUTOMATED_ODP_RECON: 'AUTOMATED_ODP_RECON',
};

const CreateEventActions = (props) => {
  const location = useLocation();

  const {
    eventState,
    getRecurrenceRule,
    isCalendarDetailValid,
    isRecurrenceDetailValid,
  } = useContext(EmployerReconContext);

  const employerId = location.pathname.split('/')[3];

  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.setIsCreateEvent(false);
    props.onClose(false);
  };

  const isEventValid = () => {
    if (!isCalendarDetailValid()) {
      return false;
    }

    if (!isRecurrenceDetailValid()) {
      return false;
    }

    return true;
  };

  const getReconConfig = (eventState) => {
    if (
      eventState.reconConfigType === 'default' ||
      eventState.reconConfigType === 'lineItemsPerBranch'
    ) {
      return {
        reconConfigType: eventState.reconConfigType,
        xeroContactId: eventState.xeroContactId,
        xeroContactName: eventState.xeroContactName,
        addXeroInvoiceReference: eventState.addXeroInvoiceReference,
        xeroInvoiceReference: eventState.xeroInvoiceReference,
        isTaxInclusive: eventState.isTaxInclusive,
      };
    }

    if (eventState.reconConfigType === 'invoicesPerBranch') {
      return {
        reconConfigType: eventState.reconConfigType,
        employerBranchXeroContactRelation:
          eventState.employerBranchXeroContactRelation,
        isTaxInclusive: eventState.isTaxInclusive,
      };
    }
  };

  const createEvent = async () => {
    const recurrenceRule = getRecurrenceRule();

    const time = eventState.startDate.setHours(
      eventState.startDate.getHours() + 2,
    );
    const startDate = new Date(time).toISOString().split('T')[0];

    const reconConfig = getReconConfig(eventState);

    const requestBody = {
      summary: eventState.title,
      startDate,
      description: eventState.description,
      recurrence: [`RRULE:${recurrenceRule}`],
      employerId,
      employerPayCycleId: eventState.employerPayCycleId,
      attendees: eventState.attendees,
      calendarEventType: CalendarEventTypes.AUTOMATED_ODP_RECON,
      reconConfig,
    };

    setLoading(true);

    const response = await axios.post(
      `/api/calendar_events/create`,
      requestBody,
    );

    if (response.status === 201) {
      setLoading(false);
      props.setIsCreateEvent(false);
      props.onClose(true);
      await Swal.fire({
        title: 'Recon Event created successfully',
        icon: 'success',
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      setLoading(false);
      apiResponseHandler.handleApiError('Error');
    }
  };

  if (loading) {
    return (
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <ModalFooter>
      <Button
        disabled={!isEventValid()}
        color="primary"
        onClick={() => createEvent()}
      >
        Create Event
      </Button>{' '}
      <Button color="secondary" onClick={() => closeModal(false)}>
        Cancel
      </Button>
    </ModalFooter>
  );
};

export default CreateEventActions;
