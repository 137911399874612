import React, { createContext, useReducer } from 'react';

const EventFrequencies = {
  Monthly: 'monthly',
  Weekly: 'weekly',
  Daily: 'daily',
};

const monthlyEventType = {
  ByNthDayInMonth: 'byNthDayInMonth',
  ByDateInMonth: 'byDateInMonth',
};

const initialEventState = {
  startDate: null,
  repeatFrequency: null,
  interval: null,
  monthlyType: 'byDateInMonth',
  dateInMonth: null,
  nthDayInMonth: null,
  posDayInMonth: null,
  dayOfWeek: null,

  title: '',
  description: '',
  attendees: [],

  employerPayCycleId: '',

  reconConfigType: null,
  xeroContactId: null,

  isTaxInclusive: true,
  addXeroInvoiceReference: false,
  xeroInvoiceReference: null,
  employerBranchXeroContactRelation: [],
};

// const today =  new Date();

export const reconConfigurationReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_RECON_CONFIG_TYPE':
      return {
        ...state,
        reconConfigType: action.reconConfigType,
        xeroContactId: null,
        xeroContactName: null,
        isTaxInclusive: true,
        addXeroInvoiceReference: false,
        employerBranchXeroContactRelation:
          action.reconConfigType === 'invoicesPerBranch'
            ? action.branches.map((b) => ({
                branchId: b.id,
                name: b.name,
                code: b.code,
                xeroContactId: null,
                addXeroInvoiceReference: false,
                xeroInvoiceReference: null,
              }))
            : [],
      };
    case 'UPDATE_BRANCH_XERO_CONTACT_RELATION':
      const updatedXeroContact = state.employerBranchXeroContactRelation.map(
        (bXC) => {
          if (bXC.branchId === action.branchId) {
            return {
              ...bXC,
              xeroContactId: action.xeroContactId,
            };
          }
          return bXC;
        },
      );

      return {
        ...state,
        employerBranchXeroContactRelation: updatedXeroContact,
      };

    case 'UPDATE_XERO_CONTACT_ID':
      return {
        ...state,
        xeroContactId: action.xeroContactId,
        xeroContactName: action.xeroContactName,
      };

    case 'TOGGLE_ADD_BRANCH_XERO_REFERENCE':
      const updatedToggleXeroReference =
        state.employerBranchXeroContactRelation.map((bXC) => {
          if (bXC.branchId === action.branchId) {
            return {
              ...bXC,
              addXeroInvoiceReference: action.addXeroInvoiceReference,
            };
          }
          return bXC;
        });

      return {
        ...state,
        employerBranchXeroContactRelation: updatedToggleXeroReference,
      };

    case 'UPDATE_BRANCH_XERO_REFERENCE':
      const updatedXeroReference = state.employerBranchXeroContactRelation.map(
        (bXC) => {
          if (bXC.branchId === action.branchId) {
            return {
              ...bXC,
              xeroInvoiceReference: action.xeroInvoiceReference,
            };
          }
          return bXC;
        },
      );

      return {
        ...state,
        employerBranchXeroContactRelation: updatedXeroReference,
      };

    case 'TOGGLE_ADD_XERO_INVOICE_REFERENCE':
      return {
        ...state,
        addXeroInvoiceReference: !state.addXeroInvoiceReference,
      };

    case 'UPDATE_XERO_REFERENCE':
      return {
        ...state,
        xeroInvoiceReference: action.xeroInvoiceReference,
      };

    case 'UPDATE_START_DATE':
      return {
        ...state,
        startDate: action.startDate,
      };
    case 'UPDATE_REPEAT_FREQUENCY':
      return {
        ...state,
        repeatFrequency: action.repeatFrequency,
        interval: action.repeatFrequency === 'monthly' ? null : state.interval,
        dayOfWeek:
          action.repeatFrequency === 'monthly' ? null : state.dayOfWeek,
      };
    case 'UPDATE_INTERVAL':
      return {
        ...state,
        interval: action.interval,
      };
    case 'UPDATE_MONTHLY_TYPE':
      return {
        ...state,
        monthlyType: action.monthlyType,
      };
    case 'UPDATE_MONTH_DATE':
      return {
        ...state,
        dateInMonth: action.dateInMonth,
      };
    case 'UPDATE_NTH_DAY':
      return {
        ...state,
        nthDayInMonth: action.nthDayInMonth,
      };
    case 'UPDATE_POS_DAY_IN_MONTH':
      return {
        ...state,
        posDayInMonth: action.posDayInMonth,
      };
    case 'UPDATE_DAY_OF_WEEK':
      return {
        ...state,
        dayOfWeek: action.dayOfWeek,
      };
    case 'UPDATE_TITLE':
      return {
        ...state,
        title: action.title,
      };

    case 'UPDATE_DESCRIPTION':
      return {
        ...state,
        description: action.description,
      };

    case 'UPDATE_ATTENDEES':
      return {
        ...state,
        attendees: action.attendees,
      };

    case 'UPDATE_EMPLOYER_PAYCYCLE':
      return {
        ...state,
        employerPayCycleId: action.employerPayCycleId,
      };

    case 'UPDATE_TAX_INCLUSIVE':
      return {
        ...state,
        isTaxInclusive: !state.isTaxInclusive,
      };

    default:
      break;
  }
};

const ReconEventStore = ({ children }) => {
  const [eventState, dispatch] = useReducer(
    reconConfigurationReducer,
    initialEventState,
  );

  const updateXeroContact = (xeroContactId, xeroContactName) => {
    dispatch({
      type: 'UPDATE_XERO_CONTACT_ID',
      xeroContactId,
      xeroContactName,
    });
  };

  const updateEmployerBranchXeroContactRelation = (branchId, xeroContactId) => {
    dispatch({
      type: 'UPDATE_BRANCH_XERO_CONTACT_RELATION',
      branchId,
      xeroContactId,
    });
  };

  const toggleAddBranchXeroReference = (branchId, addXeroInvoiceReference) => {
    dispatch({
      type: 'TOGGLE_ADD_BRANCH_XERO_REFERENCE',
      branchId,
      addXeroInvoiceReference,
    });
  };

  const toggleIsTaxInclusive = () => {
    dispatch({
      type: 'UPDATE_TAX_INCLUSIVE',
    });
  };

  const toggleAddXeroInvoiceReference = () => {
    dispatch({
      type: 'TOGGLE_ADD_XERO_INVOICE_REFERENCE',
    });
  };

  const updateXeroInvoiceReference = (xeroInvoiceReference) => {
    dispatch({
      type: 'UPDATE_XERO_REFERENCE',
      xeroInvoiceReference,
    });
  };

  const updateBranchXeroReference = (branchId, xeroInvoiceReference) => {
    dispatch({
      type: 'UPDATE_BRANCH_XERO_REFERENCE',
      branchId,
      xeroInvoiceReference,
    });
  };

  const updateReconConfigType = (reconConfigType, branches) => {
    dispatch({
      type: 'UPDATE_RECON_CONFIG_TYPE',
      reconConfigType,
      branches,
    });
  };

  const updateStartDate = (startDate) => {
    dispatch({
      type: 'UPDATE_START_DATE',
      startDate,
    });
  };

  const updateRepeatFrequency = (repeatFrequency) => {
    dispatch({
      type: 'UPDATE_REPEAT_FREQUENCY',
      repeatFrequency,
    });
  };

  const updateInterval = (interval) => {
    dispatch({
      type: 'UPDATE_INTERVAL',
      interval,
    });
  };

  const updateMonthlyType = (monthlyType) => {
    dispatch({
      type: 'UPDATE_MONTHLY_TYPE',
      monthlyType,
    });
  };

  const updateDateInMonth = (dateInMonth) => {
    dispatch({
      type: 'UPDATE_MONTH_DATE',
      dateInMonth,
    });
  };

  const updateNthDayInMonth = (nthDayInMonth) => {
    dispatch({
      type: 'UPDATE_NTH_DAY',
      nthDayInMonth,
    });
  };

  const updatePosDayInMonth = (posDayInMonth) => {
    dispatch({
      type: 'UPDATE_POS_DAY_IN_MONTH',
      posDayInMonth,
    });
  };

  const updateDayOfWeek = (dayOfWeek) => {
    dispatch({
      type: 'UPDATE_DAY_OF_WEEK',
      dayOfWeek,
    });
  };

  const updateTitle = (title) => {
    dispatch({
      type: 'UPDATE_TITLE',
      title,
    });
  };

  const updateDescription = (description) => {
    dispatch({
      type: 'UPDATE_DESCRIPTION',
      description,
    });
  };

  const updateAttendees = (attendees) => {
    dispatch({
      type: 'UPDATE_ATTENDEES',
      attendees,
    });
  };

  const updateEmployerPayCycle = (employerPayCycleId) => {
    dispatch({
      type: 'UPDATE_EMPLOYER_PAYCYCLE',
      employerPayCycleId,
    });
  };

  const getRecurrenceRule = () => {
    let freq = 'FREQ=';

    let interval = 'INTERVAL=';

    let byIdentifier = '';

    let bySetPos = '';

    if (eventState.repeatFrequency === EventFrequencies.Monthly) {
      freq += 'MONTHLY;';
      interval += '1;';

      if (eventState.monthlyType === monthlyEventType.ByDateInMonth) {
        if (eventState.dateInMonth > 28) {
          byIdentifier += 'BYMONTHDAY=28,29,30,31;';
          bySetPos += 'BYSETPOS=-1;';
        } else {
          byIdentifier += `BYMONTHDAY=${eventState.dateInMonth};`;
        }
      } else {
        byIdentifier += `BYDAY=${eventState.posDayInMonth};`;
        bySetPos += `BYSETPOS=${eventState.nthDayInMonth};`;
      }
    } else {
      freq += 'WEEKLY;';
      interval += `${eventState.interval};`;
      byIdentifier += `BYDAY=${eventState.dayOfWeek};`;
    }
    return freq + byIdentifier + interval + bySetPos;
  };

  const isCalendarDetailValid = () => {
    if (eventState.title.trim().length === 0) return false;
    if (eventState.description.trim().length === 0) return false;

    if (eventState.attendees.length === 0) {
      return false;
    }

    return true;
  };

  const isRecurrenceDetailValid = () => {
    if (!['weekly', 'monthly'].includes(eventState.repeatFrequency)) {
      return false;
    }

    // if (eventState.startDate < today) {
    //   return false;
    // }
    if (eventState.startDate === null) {
      return false;
    }

    if (
      eventState.repeatFrequency === 'weekly' &&
      (eventState.interval <= 0 || eventState.interval > 4)
    ) {
      return false;
    }

    return true;
  };

  return (
    <EmployerReconContext.Provider
      value={{
        eventState,
        updateStartDate,
        updateRepeatFrequency,
        updateInterval,
        updateMonthlyType,
        updateDateInMonth,
        updateNthDayInMonth,
        updatePosDayInMonth,
        updateDayOfWeek,
        updateTitle,
        updateDescription,
        updateAttendees,
        updateEmployerPayCycle,
        getRecurrenceRule,
        isCalendarDetailValid,
        isRecurrenceDetailValid,
        updateReconConfigType,
        updateXeroContact,
        toggleAddXeroInvoiceReference,
        updateXeroInvoiceReference,
        updateEmployerBranchXeroContactRelation,
        toggleIsTaxInclusive,
        toggleAddBranchXeroReference,
        updateBranchXeroReference,
      }}
    >
      {children}
    </EmployerReconContext.Provider>
  );
};

export const EmployerReconContext = createContext(initialEventState);
export default ReconEventStore;
