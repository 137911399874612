import React from 'react';
import { useEnabledFeatureFlag } from '../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../utils/feature-flagging/FeatureFlags';
import { FeatureFlagsType } from '../utils/feature-flagging/types';

interface Props {
  flag: FeatureFlagsType;
  [otherProps: string]: any;
}

const FlaggedButton: React.FC<Props> = (props) => {
  const flagStatus = useEnabledFeatureFlag(FeatureFlags[props.flag]);

  return flagStatus ? <button {...props}>{props.children}</button> : <></>;
};

export default FlaggedButton;
