import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Table,
  Row,
} from 'reactstrap';
import InvoiceConfig from '../Employer/InvoiceConfigWidget';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { CreateBillableUnit } from './CreateBillableUnit';
import { environment } from '../../../enviroment/enviroment';


const EmployerHRCRecons = (props) => {

  const [isCreateNewBillabeUnit, setIsCreateNewBillabeUnit] = useState(false);

  const [employerBillableUnits, setEmployerBillableUnits] = useState([]);

  const getEmployerBillableUnits = () => {
    const url = `${environment.baseUrl}/employer_billable_units/?employer_id=${props.employerId}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          setEmployerBillableUnits(res.data);
        }
      })
      .catch((error) => {
        apiResponseHandler.handleApiError(
          'Error retrieving Billable Units',
          error
        );
      });
  };

  const toggleEmployerBillableUnit = async (empUnit) => {
    const desiredAction = !empUnit.enabled ? 'Enable' : 'Disable';
    const { isConfirmed } = await Swal.fire({
      title: `${desiredAction} Billable Unit.`,
      text: `Are you sure you want to ${desiredAction} this billable unit?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, ${desiredAction} it!`,
    });

    if (isConfirmed) {
      const url = `${environment.baseUrl}/employer_billable_units/${empUnit.id}/`;
      axios
        .put(url, {
          ...empUnit,
          id: empUnit.id,
          features: empUnit.features.map((f) => f.id),
          updated_by: empUnit.updated_by.id,
          enabled: !empUnit.enabled,
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: 'Updated successfully',
              text: `Billable Unit ${desiredAction}d successfully`,
              icon: 'success',
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            getEmployerBillableUnits();
          }
        })
        .catch((error) => {
          apiResponseHandler.handleApiError(
            'Billable Unit was not updated',
            error
          );
        });
    }
  };

  const formatUnitTypeDisplayName = (unitType) => {
    if (unitType) {
      return unitType
        .split('_')
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(' ');
    }
    return '';
  };

  useEffect(() => {
    getEmployerBillableUnits();
  }, []);

  return (
    <div>
      <CreateBillableUnit
        employerId={props.employerId}
        isOpen={isCreateNewBillabeUnit}
        setIsCreateNewBillabeUnit={setIsCreateNewBillabeUnit}
      />

      <InvoiceConfig employerId={props.employerId} />

      <Row>
        <Col className="col-12">
          <Card className="shadow">
            <CardHeader>
              <strong>Employer Billable Units</strong>
              <Button
                color="primary"
                onClick={() => setIsCreateNewBillabeUnit(true)}
                className="btn btn-primary btn-sm float-right">
                Create New Billable Unit
              </Button>
            </CardHeader>

            <Table responsive striped size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit Price</th>
                  <th>Unit Type</th>
                  <th>Updated By</th>
                  <th>Account</th>
                  <th>Enabled</th>
                  <th>Features</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {employerBillableUnits.length > 0 &&
                  employerBillableUnits.map((empUnit) => (
                    <tr key={empUnit.id}>
                      <td>{empUnit.name}</td>
                      <td>{empUnit.price_per_unit}</td>
                      <td>{formatUnitTypeDisplayName(empUnit.unit_type)}</td>
                      <td>{`${empUnit.updated_by.first_name}  ${empUnit.updated_by.last_name}`}</td>
                      <td>{empUnit.account_code}</td>
                      <td>
                        {empUnit.enabled ? (
                          <i className="fas fa-check-circle text-lg text-success" />
                        ) : (
                          <i className="fas fa-times-circle text-lg text-warning" />
                        )}
                      </td>
                      <td>{empUnit.features.map((f) => f.name).join(', ')}</td>
                      <td>
                        <Button
                          onClick={() => toggleEmployerBillableUnit(empUnit)}
                          color="primary"
                          size="sm">
                          {empUnit.enabled ? 'Disable' : 'Enable'}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmployerHRCRecons;
