import React, { useState } from 'react';

import {
  Spinner,
  Row,
  Col,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

const EditEducationQuestion = (props) => {
  const [state, setState] = useState({
    containerState: 'STATE_NEW',
    questionId: '',
    template: props.location.state ? props.location.state.template : '',
    questionType: props.location.state ? props.location.state.questionType : '',
    category: props.location.state ? props.location.state.category : '',
    platform: props.location.state ? props.location.state.platform : '',
    answers: props.location.state ? props.location.state.answers : '',
    isActive: props.location.state ? props.location.state.isActive : '',
    newAnswers: [],
    removeAnswerIds: [],
  });

  const handleSubmit = () => {
    setState({ ...state, containerState: 'STATE_LOADING' });

    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;
    const answersList = [];
    const { questionId } = props.match.params;
    const { answers, platform, questionType, isActive, category, template } =
      state;

    const newAnswersList = answers.filter((answer) => !!answer.newAnswer);

    const a = [];

    // eslint-disable-next-line no-unused-expressions
    newAnswersList &&
      newAnswersList.map((newAnswer) =>
        a.push({
          template: newAnswer.template,
          order_weight: '1',
          added_by: currentUserId,
          platform,
        })
      );

    // eslint-disable-next-line no-unused-expressions
    answers &&
      answers
        .filter((answer) => !answer.newAnswer)
        .map((answer) =>
          answersList.push({
            id: answer.id,
            template: answer.template,
            order_weight: answer.order_weight,
            modified_by: currentUserId,
            platform,
          })
        );

    const payload = {
      update_details: {
        question_type: questionType,
        template,
        platform,
        is_active: isActive,
        category,
        added_by: currentUserId,
        answers: answersList,
        remove_answers: state.removeAnswerIds,
        new_answers: a,
      },
    };

    axios
      .put(`${environment.baseUrl}/questions/${questionId}`, payload)
      .then(() => {
        Swal.fire({
          title: 'Question successfully updated',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        setState({ ...state, containerState: 'STATE_OK' });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setState({ ...state, containerState: 'STATE_ERROR' });
      });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    setState({ ...state, [name]: value });
  };

  const handleAnswerInputChange = (i, event) => {
    const { target } = event;

    if (target.name === 'answer') {
      const answers = [...state.answers];
      answers[i].template = event.target.value;
      setState({ ...state, answers });
    }
  };

  const addClick = () => {
    // createUI();
    setState({
      ...state,
      answers: state.answers.concat([{ newAnswer: true }]),
    });
  };

  const removeClick = (i, answerId) => {
    const answers = [...state.answers];
    answers.splice(i, 1);
    const removeAnswerIds = [...state.removeAnswerIds];

    setState({
      ...state,
      answers,
      removeAnswerIds: removeAnswerIds.concat([answerId]),
    });
  };

  const createUI = () =>
    state.answers.map((answer, index) => (
      <FormGroup inline key={answer.id}>
        <Label for="exampleText">
          <Button
            color="danger"
            size="sm"
            value="remove"
            onClick={removeClick.bind(this, index, answer.id)}>
            {' '}
            Remove
          </Button>
        </Label>
        <Input
          type="textarea"
          name="answer"
          id="answer"
          defaultValue={answer.template}
          onChange={handleAnswerInputChange.bind(this, index)}
        />
      </FormGroup>
    ));

  if (state.containerState === 'STATE_LOADING') {
    return <Spinner size="sm" color="secondary" />;
  }

  if (state.containerState === 'STATE_ERROR') {
    return <h4>An error occurred</h4>;
  }

  if (state.containerState === 'STATE_OK') {
    props.history.push('/admin/questions/education');
    return null;
  }

  if (state.containerState === 'STATE_NEW') {
    if (!props.location.state) {
      props.history.push('/admin/employers');
      return null;
    }

    const { template, questionType, category, platform, isActive } = state;

    const { questionId } = props.match.params;

    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Edit Question - {questionId}
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <br />

        <Form onSubmit={handleSubmit}>
          {' '}
          <Container>
            <h4>Edit Question</h4>

            <FormGroup>
              <Label for="questionTemplate">Template</Label>
              <Input
                type="textarea"
                name="template"
                id="questionTemplate"
                defaultValue={template}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="questionTypeSelect">Type</Label>
              <Input
                type="select"
                name="questionType"
                defaultValue={questionType}
                id="questionTypeSelect"
                onChange={handleInputChange}>
                <option value="multiple choice - single selection">
                  multiple choice - single selection
                </option>
                <option value="multiple choice - multiple selection">
                  multiple choice - multiple selection
                </option>
                <option value="open ended">open ended</option>
                <option value="rating question">rating question</option>
                <option value="lickert scale">lickert scale</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="platformSelect">Platform</Label>
              <Input
                type="select"
                name="platform"
                defaultValue={platform}
                id="platformSelect"
                onChange={handleInputChange}>
                <option value="whatsapp">Whatsapp</option>
                <option value="ussd">USSD</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="categorySelect">Category</Label>
              <Input
                type="select"
                name="category"
                id="categorySelect"
                defaultValue={category}
                onChange={handleInputChange}>
                <option value="Organisation for Economic Co-operation and Development">
                  Organisation for Economic Co-operation and Development
                </option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="isActive"
                  id="isActiveCheckbox"
                  checked={isActive}
                  onChange={handleInputChange}
                />{' '}
                Active
              </Label>
            </FormGroup>
          </Container>
          <Container>
            <h4>Answers</h4>

            {createUI()}

            <FormGroup>
              <Label for="actionButtons" />
              <Button color="primary" size="sm" onClick={addClick}>
                Add Answer
              </Button>{' '}
              <Button color="info" size="sm">
                Save
              </Button>
            </FormGroup>
          </Container>
        </Form>
      </>
    );
  }
};

export default EditEducationQuestion;
