import React, { useCallback, useEffect, useState } from 'react';

import axios from 'axios';
import { Row, Col, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { environment } from '../../../../enviroment/enviroment';
import apiResponseHandler from '../../../../utils/apiResponseHandler';
import { useEnabledFeatureFlag } from '../../../../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../../../../utils/feature-flagging/FeatureFlags';
import EmployeeDocumentUpdate from './EmployeeDocumentUpdate';
import EmployeeDocumentUploadModal from './EmployeeDocumentUploadModal';

const ManageEmployeeDocuments = (props) => {
  const flagReuploadDocumentsTemp = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP,
  );

  const [employeeDocuments, setEmployeeDocuments] = useState([]);

  const [displayDocuments, setDisplayDocuments] = useState([]);

  const [displaySelectOption, setDisplaySelectOption] = useState();

  const [selectedDocument, setSelectedDocument] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const [singlarDocumentName, setSinglarDocumentName] = useState('');
  const [pluralDocumentName, setPluralDocumentName] = useState('');

  useEffect(() => {
    if (props.documentType === 'irp5s'){
      setSinglarDocumentName('IRP5');
      setPluralDocumentName('IRP5s');
    } else if (props.documentType === 'payslips'){
      setSinglarDocumentName('Payslip');
      setPluralDocumentName('Payslips');
    } else if (props.documentType === 'timesheets'){
      setSinglarDocumentName('Timesheet');
      setPluralDocumentName('Timesheets');
    } else if (props.documentType === 'rosters') {
      setSinglarDocumentName('Roster');
      setPluralDocumentName('Rosters');
    }
  }, [props]);

  const updateModalState = (isOpen, isRefresh = false) => {
    showUpdatedDocument(isRefresh);
    setShowUploadModal(isOpen);
  };

  const getEmployeeDocuments = useCallback((employeeId, isRefresh = false) => {
    setIsLoading(true);
    setSelectedDocument(null);
    setDisplaySelectOption('hidden');
    setEmployeeDocuments([]);
    setDisplayDocuments([]);

    const dateTimeOptions = {
      day: '2-digit',
      year: 'numeric',
      month: '2-digit',
      hour: '2-digit',
      hour12: true,
      minute: '2-digit',
      second: '2-digit',
    };

    let url = `${environment.baseUrl}/employee_payslips/?employee_id=${employeeId}&verification_status=success`;
    if (props.documentType === 'irp5s') {
      url = `${environment.baseUrl}/employee_irp5s/?employee_id=${employeeId}&status=imported`;
    } else if (props.documentType === 'timesheets') {
      url = `${environment.baseUrl}/employee_timesheet/?employee_id=${employeeId}&status=imported`;
    } else if (props.documentType === 'rosters') {
      url = `${environment.baseUrl}/employee_roster/?employee_id=${employeeId}&status=imported`;
    }

    axios
      .get(url)
      .then((res) => {
        setEmployeeDocuments(res.data.results);

        let documents = [];
        if (["rosters", "timesheets"].includes(props.documentType)) {
          documents = res.data.results.map((document) => ({
            label: `\u00A0 📅 \u00A0 ${ document.period_start_date} \u00A0 \u00A0 \u00A0 📅 \u00A0 ${ document.period_end_date}`,
            value: document.id,
          }));

          console.log(documents);
        } else {
          documents = res.data.results.map((document) => ({
            label: `\u00A0 📅 \u00A0 ${
              document.date_issued || new Date(
                document.date_of_distribution,
              )
                .toLocaleDateString('en-ZA', dateTimeOptions)
                .replace(/\//g, '-')
            }  \u00A0  \u00A0 \u00A0  \u00A0 ⏰ \u00A0 \u00A0${new Date(
              document.date_of_distribution,
            )
              .toLocaleDateString('en-ZA', dateTimeOptions)
              .replace(/\//g, '-')}`,
            value: document.id,
          }));
        }
        
        setDisplayDocuments(documents);

        if (isRefresh && res.data.results.length > 0) {
          const _displayDocuments = res.data.results.map((document) => ({
            value: document.id,
            label: document.date_issued,
          }));

          const _document = res.data.results[0];

          setDisplaySelectOption(_displayDocuments[0]);

          setSelectedDocument(_document);
        }

        setIsLoading(false);
      });
  }, [props.documentType]);

  const viewDocument = (documentId) => {
    setSelectedDocument(null);
    setIsLoading(true);
    const _document = employeeDocuments.find(
      (eP) => eP.id === parseInt(documentId),
    );
    setSelectedDocument(_document);
    setIsLoading(false);
  };

  const showUpdatedDocument = async (isRefresh) => {
    if (isRefresh) {
      getEmployeeDocuments(props.employeeData.id, isRefresh);
    }
  };

  const refreshDocumentPasswords = () => {
    setIsLoading(true);
    let url = `${environment.baseUrl}/employees/${props.employeeData.id}/refresh_payslip_passwords/`
    if (props.documentType === 'irp5s') {
      url = `${environment.baseUrl}/employees/${props.employeeData.id}/refresh_irp5_passwords/`
    } else if (props.documentType === 'timesheets') {
      url = `${environment.baseUrl}/employees/${props.employeeData.id}/refresh_timesheet_passwords/`
    } else if (props.documentType === 'rosters') {
      url = `${environment.baseUrl}/employees/${props.employeeData.id}/refresh_roster_passwords/`
    }
    axios
      .post(url)
      .then(() => {
        Swal.fire({
          title: `${pluralDocumentName} Passwords Refreshed`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getEmployeeDocuments(props.employeeData.id);
  }, [props, getEmployeeDocuments]);

  return (
    <div style={{ padding: '16px' }}>
      {showUploadModal && (
        <EmployeeDocumentUploadModal
          isCreateNew
          payslipId={null}
          employeeData={props.employeeData}
          updateModalState={updateModalState}
          documentType={props.documentType}
        />
      )}

      <Row>
        <Col lg="6">
          <Input
            placeholder={`Select ${singlarDocumentName}`}
            value={displaySelectOption}
            onChange={(obj) => {
              viewDocument(obj.target.value);
              setDisplaySelectOption(obj.target.value);
            }}
            type="select"
            disabled={isLoading}
          >
            <option value="" hidden>
              {displayDocuments.length === 0
                ? 'No Document Available'
                : `Select ${singlarDocumentName}`}
            </option>
            {displayDocuments.map((paydisplay) => (
              <option value={paydisplay.value}> {paydisplay.label}</option>
            ))}
          </Input>
        </Col>

        <Col lg="6">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-success float-right"
            style={{
              minWidth: '200px',
              marginBottom: '3px',
              marginRight: '8px',
            }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload New {singlarDocumentName}
          </button>
        </Col>
      </Row>

      {flagReuploadDocumentsTemp && !selectedDocument && (
        <Row className="float-right">
          <Col lg={6}>
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-primary"
              style={{
                minWidth: '200px',
                marginTop: '8px',
                marginBottom: '3px',
                marginRight: '8px',
              }}
              onClick={refreshDocumentPasswords}
            >
              Refresh Passwords
            </button>
          </Col>
        </Row>
      )}
      {isLoading && (
        <Row className="my-3">
          <Col className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Col>
        </Row>
      )}

      <div style={{ padding: '4px' }}>
        {!isLoading && selectedDocument && (
          <EmployeeDocumentUpdate
            showUpdatedDocument={showUpdatedDocument}
            employeeData={props.employeeData}
            selectedDocument={selectedDocument}
            getEmployeeDocuments={getEmployeeDocuments}
            employeeId={props.employeeData.id}
            documentType={props.documentType}
          />
        )}
      </div>
    </div>
  );
};

export default ManageEmployeeDocuments;
