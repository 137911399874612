const ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP =
  'adminportal.automatedinvoicing.temp';
const ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP =
  'adminportal.reuploadpayslips.temp';

const ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP =
  'adminportal.delete_employer.temp';

const ADMIN_PORTAL_DELETE_EMPLOYEES_FEATURE_FLAG_TEMP =
  'adminportal.delete_employees.temp';

const ADMIN_PORTAL_TOOLS_FEATURE_FLAG_TEMP = 'adminportal.tools.temp';
const ADMIN_PORTAL_TOOLS_MERGE_PROFILES_FEATURE_FLAG_TEMP =
  'adminportal.tools_merge_profiles.temp';

const ADMIN_PORTAL_AUTOMATED_ODP_INVOICING =
  'adminportal.automated_odp_invoicing.manage';

const ADMIN_PORTAL_AUTOMATED_ODP_INVOICING_USE_ALL_CONFIGS =
  'adminportal.automated_odp_invoicing.use_all_recon_configs';

const PROCESS_UNFLAGGED_RISKY_ADVANCE_PAYMENTS_AUTOMATICALLY =
  'adminportal.risky_advances.process_unflagged_risky_advance_payments_automatically.temp';

const DISPLAY_NEW_EWA_LIMITS =
  'adminportal.ewa_limits.display_new_ewa_limits.temp';

export default {
  ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_TOOLS_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_TOOLS_MERGE_PROFILES_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_DELETE_EMPLOYEES_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_AUTOMATED_ODP_INVOICING,
  ADMIN_PORTAL_AUTOMATED_ODP_INVOICING_USE_ALL_CONFIGS,
  PROCESS_UNFLAGGED_RISKY_ADVANCE_PAYMENTS_AUTOMATICALLY,
  DISPLAY_NEW_EWA_LIMITS
};
