import moment from 'moment-timezone';
/**
 * Format currency value to 2 dp
 *
 * @param {string} value currency amount
 * @returns {sring} currency amount string formatted to 2 dp
 */
export function formatCurrency(value) {
  if (!value) return value;

  // handle cents
  if (value % 1 !== 0) {
    return parseFloat(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  return value.toLocaleString(navigator.language);
}

/**
 * Format Unix date to D MMM YYYY format
 *
 * @param {string} date date string
 * @returns {string} formattedDate Date in D MMM YYYY format
 */
export function formatDate(date) {
  if (!date) {
    return date;
  }

  const formattedDate = moment(date).format('D MMM YYYY');
  return formattedDate;
}

/**
 * Format Unix date to D MMM YYYY format
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in D MMM YYYY format
 */
export function formatUnixDate(date) {
  if (!date) {
    return date;
  }

  const formattedDate = moment.unix(date).format('D MMM YYYY');
  return formattedDate;
}

/**
 * Format Unix date to D MMM YYYY @ H:mm format
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in D MMM YYYY format
 */
export function formatDateTime(date) {
  if (!date) {
    return date;
  }

  const formattedDate = moment(date).format('D MMM YYYY @ H:mm');
  return formattedDate;
}

/**
 * Format Unix date to D MMM YYYY @ H:mm format
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in D MMM YYYY @ H:mm format
 */
export function formatUnixDateTime(date) {
  if (!date) {
    return date;
  }

  return moment.unix(date).format('D MMM YYYY @ H:mm');
}

/**
 * // "2014-09-08T08:02:17-05:00" (ISO 8601, no fractional seconds)
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in D MMM YYYY @ H:mm format
 */
export function formatISODateTime(date) {
  if (!date) {
    return date;
  }

  return moment(date).format();
}

/**
 * // "2014-09-08T08:02:17-05:00" (ISO 8601, no fractional seconds)
 *
 * @param {string} date utc date string e.g. "2021-03-01T12:00:00"
 * @returns {string} localDate Date in local time
 */
export function formatUTCToLocalDateTime(date) {
  const zone = 'Africa/Johannesburg';
  const localDate = moment.tz(date, zone).utc().format('D MMM YYYY @ H:mm');
  return localDate;
}

/**
 * get time from datetime string
 *
 * @param {string} date utc date string e.g. "2021-03-01T12:00:00"
 * @returns {string} localTime Date in local time e.g. 12:00
 */
export function formatGetLocalTime(date) {
  const zone = 'Africa/Johannesburg';
  const localTime = moment.tz(date, zone).utc().format('H:mm');
  return localTime;
}

export function formatGetLocalDate(date) {
  const zone = 'Africa/Johannesburg';
  const localTime = moment.tz(date, zone).utc().format('DD/MM/YYYY');
  return localTime;
}

export function formatDateMonthTime(date) {
  if (!date) {
    return date;
  }

  return {
    date: moment(date).format('D MMM'),
    time: moment(date).format('H:mm'),
  };
}

/**
 * Format Unix date to YYYY-MM-DD format
 * Useful for getting data into Inputs
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in YYYY-MM-DD
 */
export function formatDateForInput(date) {
  const format = 'YYYY-MM-DD';
  return moment(date).format(format);
}

/**
 * Format Unix date to YYYY-MM-DD format
 * Useful for getting data into Inputs
 *
 * @param {number} date date string
 * @returns {string} formattedDate Date in YYYY-MM-DD
 */
export function formatDateTimeForInput(date) {
  const format = 'YYYY-MM-DDTHH:mm';
  return moment(date).format(format);
}

/**
 * Tests whether an email is in the format x@y.z
 *
 * @param {email} email email string
 * @returns {boolean} valid true when valid, false otherwise
 */

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  const valid = re.test(email);
  return valid;
}
