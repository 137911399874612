/* eslint-disable */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import EmployerNavbar from '../components/Navbars/EmployerNavbar';
import EmployerSidebar from '../components/Sidebar/EmployerSidebar.js';

import routes from '../../routes/routes.js';

class EmployerLayout extends React.Component {
  constructor() {
    super();
    this.mainContent = React.createRef();
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (this.mainContent.current) {
      this.mainContent.current.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/employeradmin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            exact={true}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path,
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return;
  };
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.oneOfType([
        PropTypes.shape({
          indexOf: PropTypes.func.isRequired,
        }),
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
  };
  render() {
    return (
      <>
        <EmployerSidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/employeradmin/index',
            imgSrc: require('./../../assets/img/logo.png'),
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref={this.mainContent}>
          <EmployerNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/employeradmin/login" />
          </Switch>
        </div>
      </>
    );
  }
}

export default EmployerLayout;
