import React from 'react';
import { Row, Table, Col, Input, Container } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { Context } from '../../../state/GlobalStore';
import { environment } from '../../../enviroment/enviroment';
import Pagination from '../../../components/Pagination';

class ColdRegistrationAttempts extends React.Component {
  static contextType = Context;

  constructor() {
    super();
    this.state = {
      coldRegistrationAttempts: null,
      loading: true,
      queryParam: 'mobileNumber',
      number: null,
      searchQuery: null,
      page: 1,
    };
  }

  componentDidMount() {
    this.getColdRegistrationAttempts();
  }

  /**
   * Get a list of all cold registration attempts
   */
  getColdRegistrationAttempts = () => {
    const { page } = this.state;

    axios.get(`${environment.baseUrl}/coldregistrationattempt/`, {
      params: {page}
    }).then((res) => {
      this.setState({
        loading: false,
        coldRegistrationAttempts: res.data.results,
        pages: res.data.pages
      });
    });
  };

  /**
   * handle the change in input and update the state
   * @param {*} event
   */
  inputChangeHandler = (event) => {
    const { value } = event.target;
    this.setState({ [event.target.name]: value });
  };

  /**
   * select which field to mark a cold registartion as safe with
   * @param {*} event
   */
  markSafeBy = (event) => {
    const { value } = event.target;
    this.setState({ queryParam: value });
  };

  onSearchQuery = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/coldregistrationattempt/`, {
        params: { q: this.state.searchQuery },
      })
      .then((res) => {
        this.setState({
          loading: false,
          coldRegistrationAttempts: res.data.results,
          pages: res.data.pages,
          page: 1
          });
      });
  };

  /**
   * Update cold registration attempts by mobile_number or id_number as safe
   * @param {*} number
   * @param {*} mobileNumber
   */
  handleMarkAsSafeSubmit = (number, mobileNumber = false) => {
    const query = mobileNumber
      ? `mobile_number=${number}`
      : `id_number=${number}`;
    this.setState({ loading: true });

    axios
      .patch(
        `${environment.baseUrl}/coldregistrationattempt/markColdRegistrationAttemptsAsSafe/?${query}`,
        {
          safe: true,
        }
      )
      .then((res) => {
        this.getColdRegistrationAttempts();
      });
  };

  setPage = (page) => {
    this.setState({ page }, () => this.getColdRegistrationAttempts());
  };

  /**
   * Display a loader while fetching cold registration attempts
   * @returns {Component}
   */
  loadingComponent = () => (
    <Row className="my-3">
      <Col className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Col>
    </Row>
  );

  render() {
    const { coldRegistrationAttempts, loading, page, pages } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Cold Registration Attempts
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className="mt--5">
          <div className="card">
            <div className="card-header">
              <div className="m-2">
                <Row>
                  <Col size={4}>
                    <Input
                      name="searchQuery"
                      type="text"
                      onChange={this.inputChangeHandler}
                    />
                  </Col>
                  <Col>
                    <button
                      type="button"
                      className="btn btn-primary ml-2"
                      onClick={this.onSearchQuery}>
                      Search
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                this.loadingComponent()
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Mobile Number</th>
                      <th>Id Number</th>
                      <th>Safe</th>
                      <th>Date created</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {coldRegistrationAttempts.map((coldRegistrationAttempt) => (
                      <tr key={coldRegistrationAttempt.id}>
                        <td>{coldRegistrationAttempt.id}</td>

                        <td>{coldRegistrationAttempt.mobile_number}</td>

                        <td>{coldRegistrationAttempt.id_number}</td>

                        <td>
                          {coldRegistrationAttempt.safe ? 'True' : 'False'}
                        </td>
                        <td>
                          {moment(coldRegistrationAttempt.date_created).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </td>
                        <td align="right">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() =>
                              this.handleMarkAsSafeSubmit(
                                coldRegistrationAttempt.mobile_number,
                                true
                              )
                            }>
                            Mark mobile number as safe
                          </button>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() =>
                              this.handleMarkAsSafeSubmit(
                                coldRegistrationAttempt.id_number,
                                false
                              )
                            }>
                            Mark ID number as safe
                          </button>
                        </td>
                      </tr>
                    ))}{' '}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
          <Pagination page={page} pages={pages} setPage={this.setPage} />
        </Container>
      </>
    );
  }
}

export default ColdRegistrationAttempts;
