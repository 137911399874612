import React from 'react';
import { Redirect } from 'react-router';
import { useEnabledFeatureFlag } from '../../../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags';
import MaintenancePage from '../System/MaintenancePage';

export default () => {
  const flagToolsTemp = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_TOOLS_FEATURE_FLAG_TEMP
  );

  return flagToolsTemp ? (
    <Redirect to="/admin/tools/merge-profiles" />
  ) : (
    <MaintenancePage />
  );
};
