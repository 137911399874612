/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
  FormGroup,
  Alert,
} from 'reactstrap';
import debounce from 'lodash.debounce';
import UserFeatureSelected from '../../../components/Users/UserFeatureSelected';
import UserEmployerAndRoleSelect from '../../../components/Users/UserEmployerAndRoleSelect';
import UserTagsSelect from '../../../components/Users/UserTagsSelect';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';
import { sanitizeMobileNumber } from './UserDetail';
import CustomIntlTelInput, { isMobileNumberValid } from '../../../components/Users/CustomIntlTelInput';

let cancelToken;

class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      availableRoles: [],
      employers: [],
      employer_features: [],
      user_features: [],
      user_tags: [],
      groups: [],
      categories: [],
      formError: false,
      errors: {
        mobileNumberErrors: [],
      },
      mobileNumberExists: 1,
      mobile_number: {
        value: '',
        countryCode: '27',
        valid: false,
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getEmployers();
    this.getAvailableRoles();
  }

  getUsers = debounce((params) => {
    this.setState({
      isLoading: true,
    });

    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    axios
      .get(`${environment.baseUrl}/users/`, {
        params: { ...params },
        cancelToken: cancelToken.token,
      })

      .then((res) => {
        this.setState({
          mobileNumberExists: res.data.filter(
            (user) => user.mobile_number === params.search
          ).length,

          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }, 1000);

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  onEmployerChanged = (event) => {
    this.handleinputchange(event);
  };

  onRolesChanged = (roleIds) => {
    this.setState({
      groups: roleIds,
    });
  };

  getEmployerTagsByCategory = () => {
    if (this.state.employer) {
      const url = `${environment.baseUrl}/employertag/categories/`;
      axios
        .get(url, {
          params: {
            employer_id: this.state.employer,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({ employer_tags: res.data });
          }
        });
    }
  };

  getEmployerFeatures = () => {
    if (this.state.employer) {
      const url = `${environment.baseUrl}/employer_features?enabled=true`;
      axios
        .get(url, {
          params: {
            employer_id: this.state.employer,
          },
        })
        .then((res) => {
          if (res.data) {
            const employer_features = res.data.map((employerFeature) => ({
              feature: employerFeature,
            }));
            this.setState({ employer_features });
          }
        });
    }
  };

  getAvailableRoles = () => {
    let rolesFor = 'admin';
    if (this.state.employer) {
      rolesFor = 'all';
    }
    axios
      .get(`${environment.baseUrl}/user_roles?roles_for=${rolesFor}`)
      .then((res) => {
        this.setState({ availableRoles: res.data });
      });
  };

  afterSetStateFinished(eventName) {
    if (eventName === 'employer') {
      this.getAvailableRoles();
      this.getEmployerTagsByCategory();
      this.getEmployerFeatures();
    }
  }

  onTagsChanged = (tags) => {
    this.setState({
      user_tags: tags,
    });
  };

  updatedUserFeatures = (features) => {
    this.setState({
      user_features: features,
    });
  };

  handleinputchange = (event) => {
    event.preventDefault();
    const eventName = event.target.name;
    let eventValue = event.target.value;
    // this.form.validateFields(event.target);
    if (eventName === 'employer' && eventValue === 'NO_EMPLOYER') {
      eventValue = undefined;
    }
    if (
      event.target.name === 'bank_account_number' &&
      !/^\d+$/.test(event.target.value)
    ) {
      return null;
    }
    this.setState(
      {
        [eventName]: eventValue,
      },
      () => {
        this.afterSetStateFinished(eventName);
      }
    );
  };

  validateInputs = () => {
    const errors = {
      mobileNumberErrors: [],
      username: [],
    };

    if (!('username' in this.state) || !this.state.username) {
      errors.username.push('Username is required.');
    }

    if (!this.state.mobile_number.valid) {
      errors.mobileNumberErrors.push('User mobile number is incorrect.');
    } else if (
      this.state.mobile_number.valid &&
      this.state.mobileNumberExists !== 0
    ) {
      errors.mobileNumberErrors.push(
        'User already exists for the provided mobile number.'
      );
    }
    this.setState({
      errors,
      formError: Object.values(errors).flat().length > 0,
    });
    return Object.values(errors).flat().length === 0;
  };

  saveData = () => {
    if (this.validateInputs()) {
      const {
        username,
        first_name,
        last_name,
        email,
        mobile_number,
        groups,
        employer,
      } = this.state;

      const payload = {
        username,
        first_name,
        last_name,
        email,
        mobile_number: sanitizeMobileNumber(mobile_number.value),
        employer,
        groups,
        features: this.state.user_features.map((uF) => uF.feature.id),
        tags: this.state.user_tags.map((ut) => ut.tag.id),
      };

      const addUserUrl = `${environment.baseUrl}/users/`;
      axios
        .post(addUserUrl, payload)
        .then((res) => {
          apiResponseHandler.handleApiSuccess('User Created');
          this.props.history.push('/admin/users');
        })
        .catch((error) =>
          apiResponseHandler.handleApiError('Error while creating user', error)
        );
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      loading,
      first_name,
      last_name,
      username,
      email,
      employers,
      errors,
      formError,
    } = this.state;
    return (
      <section className="dashboard">
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Create new user
                  </h6>
                  <br />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className="mt--7">
          <Row className="pt-5 emp-edit-data">
            <Col>
              {loading ? (
                <Row className="my-3">
                  <Col className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={{ size: '6', offset: '3' }}>
                    <Row>
                      <Col>
                        <Card className="shadow">
                          <CardHeader>
                            <Row className="align-items-center">
                              <Col lg="8">
                                <h3 className="mb-0">Edit user</h3>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {formError && (
                              <Alert color="danger">
                                {Object.values(errors)
                                  .flat()
                                  .map((error) => (
                                    <li key={error}>{error}</li>
                                  ))}
                              </Alert>
                            )}
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="first_name">First name</Label>
                                  <Input
                                    type="text"
                                    name="first_name"
                                    value={first_name}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="last_name">Last name</Label>
                                  <Input
                                    type="text"
                                    name="last_name"
                                    value={last_name}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="username">Username</Label>
                                  <Input
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="email">Email</Label>
                                  <Input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleinputchange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="mobile_number">
                                    Mobile number
                                  </Label>
                                  <CustomIntlTelInput
                                    customValidation={isMobileNumberValid}
                                    containerClassName="intl-tel-input"
                                    style={{ width: '100%' }}
                                    inputClassName="form-control"
                                    onPhoneNumberChange={(
                                      valid,
                                      phone,
                                      selectedCountryData,
                                      fullNumber
                                    ) => {
                                      // check if user exists
                                      this.getUsers({
                                        search:
                                          sanitizeMobileNumber(fullNumber),
                                      });

                                      this.setState({
                                        mobile_number: {
                                          country_code:
                                            selectedCountryData.dialCode ||
                                            '27',
                                          value: fullNumber,
                                          valid,
                                        },
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <UserEmployerAndRoleSelect
                              onEmployerChanged={this.onEmployerChanged}
                              onRolesChanged={this.onRolesChanged}
                              employers={employers}
                              availableRoles={this.state.availableRoles}
                              employerSelectedId={this.state.employer?.id}
                              selectedRoleIds={this.state.groups}
                            />
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="features">Features</Label>
                                  <UserFeatureSelected
                                    userFeatures={this.state.user_features}
                                    employerFeatures={
                                      this.state.employer_features
                                    }
                                    updatedUserFeatures={
                                      this.updatedUserFeatures
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="employerTag">Tags</Label>
                                  <UserTagsSelect
                                    employerTagsByCategory={
                                      this.state.employer_tags
                                    }
                                    userTags={this.state.user_tags}
                                    onTagsChanged={this.onTagsChanged}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <button
                                  type="button"
                                  className="btn btn-primary float-right"
                                  onClick={this.saveData}
                                  disabled={this.state.isLoading}>
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UserAdd;
