import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import PropTypes from "prop-types";

import { TableRowHoverHighlight } from '../../../components/styled';
import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from '../../../utils/apiResponseHandler';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = async (params) => {
    // Show loading
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/users/`, {
        params: { ...params },
      })
      .then((res) => {
        this.setState({
          users: res.data,
          loading: false,
        });
      });
  };

  changeActive = (id, state) => {
    axios
      .patch(`${environment.baseUrl}/users/${id}/`, { is_active: state })
      .then((res) => {
        Swal.fire({
          title: `User made ${state ? 'active' : 'inactive'}`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getUsers();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  render() {
    const { users, loading } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">Users</h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row>
                    <Col style={{ display: 'flex' }}>
                      <input
                        style={{ flex: 1 }}
                        type="text"
                        className="form-control mr-2"
                        placeholder="Search Users"
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="/admin/users/add"
                        className="btn btn-primary float-right">
                        Add User
                      </Link>
                      <Link
                        to="/admin/users/permissions"
                        className="btn btn-primary float-right mr-2">
                        Permissions
                      </Link>
                      <Link
                        to="/admin/users/logs"
                        className="btn btn-primary float-right mr-2">
                        Logs
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                {loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush "
                    responsive
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '0.5pt solid #f7fafc',
                    }}>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Username</th>
                        <th scope="col">Mobile number</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date Added</th>
                        <th scope="col">Last login</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map((user, index) => (
                          <TableRowHoverHighlight
                            key={index}
                            onClick={() =>
                              this.props.history.push(`/admin/users/${user.id}`)
                            }>
                            <td>{`${user.first_name} ${user.last_name}`}</td>
                            <td>{user.employer ? user.employer.company_name : ''}</td>
                            <td>{user.username}</td>
                            <td>{user.mobile_number}</td>
                            <td>
                              {user.is_active ? (
                                <span className="badge badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-danger">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              {moment(user.date_joined).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )}
                            </td>
                            <td>
                              {user.last_login &&
                                moment(user.last_login).format(
                                  'MMMM Do YYYY, h:mm:ss a'
                                )}
                            </td>
                            <td className="d-flex justify-content-end">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.changeActive(user.id, !user.is_active);
                                }}>
                                Make {user.is_active ? 'Inactive' : 'Active'}
                              </button>
                            </td>
                          </TableRowHoverHighlight>
                        ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

UserList.propTypes = {
  history: PropTypes.shape({
    pathname: PropTypes.func.isRequired,
  }).isRequired,
};

export default UserList;
