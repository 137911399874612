import styled from 'styled-components';

export const TableRowHoverHighlight = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const TableCellHighlight = styled.td`
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #e5e5e5;
  }
`;
